import React, { Component } from 'react'

export default class TermsModal extends Component {
    render() {
        return (
            <div className="modal tpsl-modal">
                <div className="container">
                    <div className="header">
                        <p className="text">قوانین و شرایط</p>
                    </div>

                    <div className="content">
                        <div className="text-container">
                            <p>{this.props.room.rules}</p>
                        </div>

                        <p className="submit-btn" onClick={async () => { await this.props.changeState({ isFirst: false }); localStorage.setItem('first', false) }}>قبول</p>
                    </div>
                </div>
            </div >
        )
    }
}

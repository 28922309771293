import React, { Component } from 'react'
import axios from 'axios'
import Loader from './loader'
import More from '../../images/more.png'
import CoinTrade from '../../images/CoinTrade.jpeg'

import _1Mage from '../../images/1AdvancedPlatform.png'
import _2Mage from '../../images/2Wallet.png'
import _3Mage from '../../images/3Chart.png'
import _4Mage from '../../images/4SafeBuySell.png'
import _5Mage from '../../images/5Support.png'
import _6Mage from '../../images/6OnlinePay.png'

let priceInterval = null
let newsInterval = null

export default class Home extends Component {
    constructor() {
        super()
        this.state = {
            isLoading: true,
            isFailed: false,
            news: [],
            newsBody: '',
            site_description: '',
            goldPrice: {
                "Geram18": "-",
                "NewCoin": "-",
                "OldCoin": "-",
                "HalfCoin": "-",
                "QuarterCoin": "-",
                "GeramCoin": "-"
            }
        }
    }
    async syncGoldPrice() {
        await axios.get('/api/price')
            .then(async r => {
                const data = r.data

                await Promise.all(Object.keys(data).map(key => {
                    data[key] = parseInt(data[key]).toLocaleString('fa-IR') + ' تومان'
                    return key
                }))

                this.setState({ goldPrice: { ...this.state.goldPrice, ...data } })
            })
            .catch(() => this.setState({ isFailed: true }))
    }
    async getNews() {
        await axios.get('/api/notification?type=news')
            .then(async r => {
                const data = Object.values(r.data)

                this.setState({ news: data })
            })
            .catch(() => this.setState({ isFailed: true }))
    }
    async getSiteData() {
        await axios.get('/api/general-settings?key=site_description')
            .then(async r => {
                this.setState({ site_description: r.data.site_description, isLoading: false })
            })
            .catch(() => this.setState({ isFailed: true }))
    }
    async componentDidMount() {
        await this.syncGoldPrice()
        await this.getNews()
        await this.getSiteData()

        priceInterval && clearInterval(priceInterval)
        priceInterval = setInterval(() => { this.syncGoldPrice() }, 60000)

        newsInterval && clearInterval(newsInterval)
        newsInterval = setInterval(() => {
            const newsContainer = document.querySelector('.news-container .data')
            newsContainer.scrollBy(0, 70)
        }, 3000)

        const root = document.documentElement
        root.style.setProperty('--bg-color', 'white')
        root.style.setProperty('--fg-color', 'black')
    }
    componentWillUnmount() {
        priceInterval && clearInterval(priceInterval)
        newsInterval && clearInterval(newsInterval)
    }
    render() {
        if (this.state.isLoading)
            return <Loader isFailed={this.state.isFailed} failedCallback={() => window.location.reload()} />
        else
            return (
                <div className="general-container landing-container">
                    {this.state.newsBody &&
                        <div className="modal">
                            <div className="container">
                                <p className="close-btn" onClick={() => this.setState({ newsBody: '' })}>X</p>

                                <p className="body">{this.state.newsBody}</p>
                            </div>
                        </div>
                    }

                    {/* Intro */}
                    <div className="intro-container">
                        <img src={CoinTrade} alt="header" />

                        <p>{this.state.site_description}</p>
                    </div>

                    {/* Price */}
                    <div className="price-container">
                        <p className="title">مظنه ها1</p>

                        <div className="data">
                            <div>
                                <p className="name">1مظنه گرم ۱۸ عیار</p>
                                <p className="price">{this.state.goldPrice['Geram18']}</p>
                            </div>

                            <div>
                                <p className="name">سکه طرح جدید</p>
                                <p className="price">{this.state.goldPrice['NewCoin']}</p>
                            </div>

                            <div>
                                <p className="name">سکه طرح قدیم</p>
                                <p className="price">{this.state.goldPrice['OldCoin']}</p>
                            </div>

                            <div>
                                <p className="name">نیم سکه</p>
                                <p className="price">{this.state.goldPrice['HalfCoin']}</p>
                            </div>

                            <div>
                                <p className="name">ربع سکه</p>
                                <p className="price">{this.state.goldPrice['QuarterCoin']}</p>
                            </div>

                            <div>
                                <p className="name">سکه گرمی</p>
                                <p className="price">{this.state.goldPrice['GeramCoin']}</p>
                            </div>
                        </div>
                    </div>

                    {/* First Features */}
                    <div className="first-features">
                        <div>
                            <img src={_1Mage} alt="feature" />
                            <p>پیشرفته ترین پلتفرم</p>
                        </div>

                        <div>
                            <img src={_2Mage} alt="feature" />
                            <p>دارای کیف طلا</p>
                        </div>

                        <div>
                            <img src={_3Mage} alt="feature" />
                            <p>نمودار پیشرفته تریدینگ ویو</p>
                        </div>
                    </div>

                    {/* News */}
                    <div className="news-container">
                        <p className="title">تازه ترین اخبار</p>

                        <div className="data">
                            {this.state.news.map((n, i) =>
                                <div key={i} onClick={() => this.setState({ newsBody: n.body })}>
                                    <p>{n.subject}</p>
                                    <div className="img-container">
                                        <img src={More} alt="more" />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Second Features */}
                    <div className="second-features">
                        <div>
                            <img src={_4Mage} alt="feature" />
                            <p>خرید و فروش امن و سریع</p>
                        </div>

                        <div>
                            <img src={_5Mage} alt="feature" />
                            <p>پشتیبانی سریع</p>
                        </div>

                        <div>
                            <img src={_6Mage} alt="feature" />
                            <p>پرداخت با کارت های شتاب</p>
                        </div>
                    </div>
                </div>
            )
    }
}

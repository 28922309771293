import React, { Component } from 'react'
import {useLocation, useNavigate, useParams} from "react-router-dom";

class PaymentForm extends Component {

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.submitForm();
    }

    submitForm = () => {
        if (this.formRef.current) {
            this.formRef.current.submit();
        }
    }
    render() {
        return (
            <div style={{ height: '100vh', display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center' }}>
                اگر بعد از چند ثانیه به صفحه پرداخت هدایت نشدید دکمه زیر را بزنید
                <form ref={this.formRef} action="https://core.paystar.ir/api/pardakht/payment" method="POST" style={{marginTop: "10px"}}>
                    <input name="token" type="hidden" value={this.props.token}/>
                    <input type="submit" value="پرداخت" style={{padding: "8px"}}/>
                </form>
            </div>
        )
    }
}

export default function HOC() {
    let { token } = useParams()
    return <PaymentForm token={token} location={useLocation()} navigate={useNavigate()} />
}

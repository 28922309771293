import React, { Component } from 'react'
import axios from 'axios'
import AuthContext from '../../../AuthContext'

let cacheBankAccountsState = {}

class BankAccounts extends Component {
    constructor(props, context) {
        super()
        this.state = {
            bankName: '',
            bankAccounts: context.me.UserBankAccounts,
            cryptoWallets: context.me.UserCryptoWallets,
            error: '',
            ...cacheBankAccountsState
        }

        this.bank_names = [
            'بانک ملی ایران',
            'بانک اقتصاد نوین',
            'بانک قرض‌ الحسنه مهر ایران',
            'بانک سپه',
            'بانک پارسیان',
            'بانک قرض‌ الحسنه رسالت',
            'بانک صنعت و معدن',
            'بانک کار آفرین',
            'بانک کشاورزی',
            'بانک سامان',
            'بانک مسکن',
            'بانک سینا',
            'بانک توسعه صادرات ایران',
            'بانک خاور میانه',
            'بانک توسعه تعاون',
            'بانک شهر',
            'پست بانک ایران',
            'بانک دی',
            'بانک صادرات',
            'بانک ملت',
            'بانک تجارت',
            'بانک رفاه',
            'بانک آینده',
            'بانک گردشگری',
            'بانک ایران زمین',
            'بانک قوامین',
            'بانک انصار',
            'بانک سرمایه',
            'بانک پاسارگاد'
        ]

        this.onSubmit = this.onSubmit.bind(this)

        props.setLoading({ isLoading: false, isFailed: false, errorFunc: null })
    }
    async removeBankAccount(b, i) {
        await this.props.setLoading({ isLoading: true })

        await axios.delete('/api/user/bankAccount/' + b.id)
            .then(_ => {
                const accounts = this.state.bankAccounts
                delete accounts[i]
                this.setState({ bankAccounts: accounts })
            })
            .catch(_ => {
                const accounts = this.state.bankAccounts
                accounts[i] = { ...b, confirm_message: 'خطا در حذف حساب بانکی' }
                this.setState({ bankAccounts: accounts })
            })

        await this.props.setLoading({ isLoading: false })
    }
    async checkInputData(e, data) {
        let error = ""
        const inputs = Array.from(e.target.querySelectorAll('input'))

        await Promise.all(inputs.map(input => {
            switch (input.name) {
                case 'card_number':
                    if (input.value.length !== 16)
                        error = 'شماره کارت نامعتبر می باشد'
                    data.append('card_number', input.value)
                    break

                case 'account':
                    if (input.value.length === 0)
                        error = 'شماره حساب نامعتبر می باشد'
                    data.append('account_number', input.value)
                    break

                case 'shaba':
                    if (input.value.length !== 27)
                        error = 'شماره شبا نامعتبر می باشد'
                    data.append('shaba_number', input.value.replace(/IR /, ''))
                    break

                case 'image':
                    const image = input.files[0]
                    if (!image)
                        error = 'تصویر بانکی نامعتبر می باشد'
                    else {
                        if (image.type !== 'image/png' && image.type !== 'image/jpg' && image.type !== 'image/jpeg')
                            error = 'تنها فرمت png و jpg برای تصویر مجاز می باشد'

                        if (image.size > 10000000)
                            error = 'حداکثر حجم مجاز برای تصویر 10MB می باشد'
                    }
                    data.append('image', image)
                    break

                default:
                    break
            }

            return input
        }))

        this.setState({ error })

        if (error)
            return true
    }
    async onSubmit(e) {
        e.preventDefault()
        const data = new FormData()
        if (await this.checkInputData(e, data))
            return
        if (!this.state.bankName) {
            this.setState({ error: 'نام بانک خود را انتخاب نمایید' })
            return
        }
        data.append('bank_name', this.state.bankName)

        await this.props.setLoading({ isLoading: true, error: '' })

        await axios.post('/api/user/bankAccount', data, { headers: { "Content-Type": "multipart/form-data" } })
            .then(res => {
                const data = res.data
                const accounts = this.state.bankAccounts
                accounts.push(data)

                cacheBankAccountsState = {
                    ...this.state,
                    error: '',
                    bankAccounts: accounts
                }
            })
            .catch(e => {
                const data = e.response.data
                const message = !data.message ? '' : Array.isArray(data.message) ? data.message[0] : data.message

                cacheBankAccountsState = {
                    ...this.state,
                    error: !message ? 'خطا در ارسال درخواست' : message,
                }
            })

        await this.props.setLoading({ isLoading: false })

        this.setState(cacheBankAccountsState)
    }


    async onSubmitForCrypto(e) {
        console.log("here")
        e.preventDefault()
        const input = e.target.querySelector("input[name=usdt_trc20_wallet]");
        console.log(input)
        console.log(input.value.trim())

        const data = {usdt_trc20_wallet: input.value.trim()}


        // await this.props.setLoading({ isLoading: true, error: '' })

        await axios.put('/api/user/cryptoWallets', {...data})
            .then(res => {
                alert("ثبت شد")
                const data = res.data
                const accounts = this.state.cryptoWallets
                accounts.push(data)

                cacheBankAccountsState = {
                    ...this.state,
                    error: '',
                    cryptoWallets: accounts
                }
            })
            .catch(e => {
                const data = e.response.data
                const message = !data.message ? '' : Array.isArray(data.message) ? data.message[0] : data.message

                cacheBankAccountsState = {
                    ...this.state,
                    error: !message ? 'خطا در ارسال درخواست' : message,
                }
            })

        await this.props.setLoading({ isLoading: false })

        this.setState(cacheBankAccountsState)
    }
    render() {
        return (
            <div className="bank_accounts_container">
                <div className="form-container">
                    <form className="form" onSubmit={this.onSubmit}>
                        {this.state.error && <p className="error">{this.state.error}</p>}

                        <select name="bank_name" onChange={e => this.setState({ bankName: e.target.value })} defaultValue="">
                            <option value="" disabled hidden>نام بانک</option>
                            {this.bank_names.map((n, i) =>
                                <option key={i} value={n}>{n}</option>
                            )}
                        </select>

                        <input type="text" name="card_number" maxLength={16} onInput={e => e.target.value = e.target.value.replace(/[^0-9.]/g, '')} placeholder="شماره کارت" />

                        <input type="text" name="account" maxLength={30} onInput={e => e.target.value = e.target.value.replace(/[^0-9.]/g, '')} placeholder="شماره حساب" />

                        <input type="text" name="shaba" dir='ltr' maxLength={27} onInput={e => e.target.value = ('IR ' + e.target.value.replace('IR ', '').replace(/[^0-9.]/g, ''))} defaultValue="IR " />

                        <label htmlFor="image">:تصویر کارت بانکی</label>
                        <input type="file" id="image" name="image" />

                        <button className="submit">بررسی و افزودن حساب</button>
                    </form>


                    <form className="form" onSubmit={this.onSubmitForCrypto} style={{marginTop: "30px"}}>
                        {this.state.error && <p className="error">{this.state.error}</p>}

                        <input type="text" name="usdt_trc20_wallet"  placeholder="( USDT - TRC20 ) آدرس کیف پول تتر "
                        onInput={e => e.target.value = e.target.value.trim()}
                        defaultValue={this.state.cryptoWallets?.find(item => item?.network === "TRC20" && item?.token === "USDT")?.address || ''}
                        />
                        <strong style={{paddingTop: "10px"}}>از  صحت آدرس کیف پول و نوع شبکه (TRC20) مطمئن شوید، مسئولیت آدرس نادرست بعهده کاربر میباشد </strong>

                        <button className="submit">ثبت آدرس</button>
                    </form>

                </div>

                <div className="bank_accounts">
                    {this.state.bankAccounts.map((b, i) =>
                        <div key={i} className="bank_account" style={b.confirm_wait ? { backgroundColor: '#e3e9908a' } : b.confirm_failed ? { backgroundColor: '#db76764D' } : {}}>
                            <p className="name">{b.bank_name}</p>
                            <p className="card_number">کارت: {b.card_number.replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d])}</p>
                            <p className="account">حساب: {b.account_number.replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d])}</p>
                            <p className="shaba">شبا: {b.shaba_number.replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d])}</p>
                            <p>{b.confirm_wait ? 'در انتظار تایید' : b.confirm_failed ? 'رد شده' : 'تایید شده'}</p>

                            {b.confirm_failed && <>
                                <p className="fail_reason">{b.confirm_message}</p>
                                <p className="remove-btn" onClick={() => this.removeBankAccount(b, i)}>حذف</p>
                            </>}
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

BankAccounts.contextType = AuthContext

export default BankAccounts
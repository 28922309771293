import React, { Component } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import Logo from './images/logo2.png'

class Footer extends Component {
    constructor() {
        super()
        this.state = {
            isLoading: true,
            email: '-',
            instagram: '-',
            whatsapp: '-',
            telephone: '-',
            mobile: '-',
            address: '-'
        }
    }
    componentDidMount() {
        axios.get('/api/general-settings?key=footer')
            .then(r => this.setState({ isLoading: false, ...r.data.footer }))
            .catch(_ => this.setState({ isLoading: false }))
    }
    render() {
        if (this.props.location.pathname.startsWith('/dashboard') || this.props.location.pathname.startsWith('/room'))
            return
        else
            return (
                <div className="footer-container">
                    {this.state.isLoading ?
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                        :
                        <div className="footer">
                            <div className="certs">
                                {/* <div className="dummy-cert"> */}
                                <img src={Logo} className="dummy-cert" alt="logo" />
                                {/* </div> */}
                            </div>

                            <div className="contact_us">
                                <div className="address">
                                    <p>
                                        آدرس: {this.state.address}
                                    </p>
                                </div>

                                <div className="phone">
                                    <p>
                                        ما اینستاگرام: <span className="number" onClick={() => window.location = this.state.instagram}>{this.state.instagram.split('/').pop()}@</span>
                                    </p>
                                </div>

                                <div className="phone">
                                    <p>
                                        شماره واتساپ: <span className="number" onClick={() => window.location = 'https://api.whatsapp.com/send?phone=' + this.state.whatsapp}>{this.state.whatsapp}</span>
                                    </p>
                                </div>

                                <div className="phone">
                                    <p>
                                        شماره تماس: <span className="number" onClick={() => window.location = 'tel:' + this.state.telephone}>{this.state.telephone}</span>
                                    </p>
                                </div>

                                <div className="phone">
                                    <p>
                                        شماره موبایل: <span className="number" onClick={() => window.location = 'tel:' + this.state.mobile}>{this.state.mobile}</span>
                                    </p>
                                </div>

                                <div className="email">
                                    <p>
                                        ایمیل: <span onClick={() => window.location = 'mailto:' + this.state.email}>{this.state.email}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            )
    }
}

export default function HOC() {
    return <Footer location={useLocation()} />
}
import React, { Component } from 'react'
import Loader from './loader'
import axios from 'axios'
import Mage from '../../images/FAQ.png'

export default class FAQ extends Component {
    constructor() {
        super()
        this.state = {
            isLoading: true,
            isFailed: false,
            faqs: []
        }
    }
    loadText() {
        this.setState({ isLoading: true, isFailed: false }, () => {
            axios.get('/api/faqs')
                .then(res => {
                    this._isMounted && this.setState({ isLoading: false, faqs: res.data })
                })
                .catch(() => this._isMounted && this.setState({ isFailed: true }))
        })
    }
    componentDidMount() {
        this.loadText()
        this._isMounted = true

        const root = document.documentElement
        root.style.setProperty('--bg-color', 'white')
        root.style.setProperty('--fg-color', 'black')
    }
    componentWillUnmount() {
        this._isMounted = false
    }
    render() {
        if (this.state.isLoading)
            return <Loader isFailed={this.state.isFailed} failedCallback={() => this.loadText()} />
        else
            return (
                <div className="general-container general-data">
                    <div className="data">
                        <p className="title">تسوالات  متداول111</p>

                        {Object.values(this.state.faqs).map((faq, i) =>
                            <div key={i} className="faq-container">
                                <div
                                    className="header"
                                    onClick={e => document.querySelectorAll('.answer')[i].style.display !== 'block' ? document.querySelectorAll('.answer')[i].style.display = 'block' : document.querySelectorAll('.answer')[i].style.display = 'none'}
                                >
                                    <p>{faq.question}</p>

                                    <p>{faq.category_name}</p>
                                </div>

                                <div className="answer">
                                    <p>{faq.answer}</p>
                                </div>
                            </div>
                        )}
                    </div>

                    <img className="mage" src={Mage} alt="mage" />
                </div>
            )
    }
}

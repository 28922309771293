import React, { Component } from 'react'
import axios from 'axios'
import AuthContext from '../../../AuthContext'

let cacheWalletState = null

class Wallet extends Component {
    constructor(props) {
        super()
        this.state = {
            settleError: 'حداکثر مبلغ قابل برداشت روزانه به تومان: 200,000,000 تومان',
            depositError: 'مبلغ واریز مجاز بین 10,000 تومان و 50,000,000 تومان می باشد',
            page: 0,
            next: false,
            last: false,
            mode: '',
            card_deposit: false,
            crypto_deposit: false,
            crypto_gateway_deposit: false,
            settings: '',
            card_settle: true,
            crypto_settle: false,
            crypto_deposit_address: null,
            crypto_deposit_payAmount: null,
            crypto_end_pay_date: null,
            BankAccounts: {},
            settleRequests: [],
            ...cacheWalletState
        }

        this.onSubmit = this.onSubmit.bind(this)

        props.setLoading({ isLoading: !cacheWalletState, isFailed: false, errorFunc: null })
    }
    async checkInputData(e, data, type) {
        let error = ""
        const inputs = Array.from(e.target.querySelectorAll('input'))

        await Promise.all(inputs.map(input => {
            switch (input.name) {
                case 'amount':
                    const amount = input.value.replace(/,/g, '')
                    if (e.target.id === 'settle-form') {
                        if (type !== "Crypto" && amount < 100000 || amount > 200000000)
                            error = 'مبلغ تسویه مجاز بین 100,000 تومان و 200,000،000 تومان می باشد'
                        else if(type === "Crypto" && (amount < 500000 || amount > 200000000))
                            error = `مبلغ تسویه مجاز بین ${(this.state.settings.sell_usdt_price * 10).toLocaleString()} تومان و 200,000,000 تومان می باشد`
                    }
                    else {
                        if (type !== "Crypto" && (amount < 10000 || amount > 50000000))
                            error = 'مبلغ واریز مجاز بین 10,000 تومان و 50,000,000 تومان می باشد'
                        else if (type === "Crypto" && (amount < 500000 || amount > 200000000))
                            error = `مبلغ واریز مجاز بین ${(this.state.settings.sell_usdt_price * 10).toLocaleString()} تومان و 200,000,000 تومان می باشد`
                    }
                    data.append('amount', amount)
                    break

                case 'image':
                    const image = input.files[0]
                    if (e.target.querySelector('input[name=type]:checked').value === 'CardToCard')
                        if (!image)
                            error = 'تصویر بانکی نامعتبر می باشد'
                        else {
                            if (image.type !== 'image/png' && image.type !== 'image/jpg' && image.type !== 'image/jpeg')
                                error = 'تنها فرمت png و jpg برای تصویر مجاز می باشد'

                            if (image.size > 5000000)
                                error = 'حداکثر حجم مجاز برای تصویر 5MB می باشد'
                        }
                    data.append('image', image)
                    break

                default:
                    break
            }

            return input
        }))

        if (e.target.id === 'settle-form')
            this.setState({ settleError: error })
        else
            this.setState({ depositError: error })

        if (error)
            return true
    }
    async onSubmit(e) {
        e.preventDefault()
        const type = e.target.querySelector('input[name=type]:checked').value
        let url = ''
        const data = new FormData()
        if (await this.checkInputData(e, data, type))
            return
        if (e.target.id === 'deposit-form') {
            data.append('type', type)

            if (type === 'CardToCard') {
                const user_bank_id = e.target.querySelector('select[name=user_bank_id]').value
                if (!user_bank_id) {
                    this.setState({ depositError: 'حساب بانک مبدا را انتخاب نمایید' })
                    return
                }
                data.append('user_bank_id', user_bank_id)

                const bank_id = e.target.querySelector('select[name=bank_id]').value
                if (!bank_id) {
                    this.setState({ depositError: 'حساب بانک مقصد را انتخاب نمایید' })
                    return
                }
                data.append('bank_id', bank_id)
            }else if(type === 'Crypto'){
                const txHash = e.target.querySelector('input[name=txHash]').value
                if(!txHash || txHash.trim().length === 0){
                    this.setState({ depositError: 'شناسه تراکنش رمز ارز را وارد کنید' })
                    return
                }
                data.append('txHash', txHash)
            }
        }
        else {
            const user_bank_id = e?.target?.querySelector('select[name=user_bank_id]')?.value
            const user_crypto_wallet_id = e?.target?.querySelector('select[name=user_crypto_wallet_id]')?.value
            if(this.state.card_settle && !this.state.crypto_settle) {
                if (!user_bank_id) {
                    this.setState({settleError: 'حساب بانک مقصد را انتخاب نمایید'})
                    return
                }
                data.append('user_bank_id', user_bank_id)
            } else if(this.state.crypto_settle) {
                if (!user_crypto_wallet_id) {
                    this.setState({settleError: 'کیف پول مقصد را انتخاب نمایید'})
                    return
                }
                data.append('user_crypto_wallet_id', user_crypto_wallet_id)
                data.append('type', type)
            }

        }

        await this.props.setLoading({ isLoading: true, error: '' })

        if (e.target.id === 'settle-form')
            url = '/api/settle'
        else
            url = '/api/transaction/deposit'

        await axios.post(url, e.target.id === 'settle-form' ? { amount: parseInt(data.get('amount')), type, user_bank_id: parseInt(data.get('user_bank_id')), user_crypto_wallet_id: parseInt(data.get('user_crypto_wallet_id')) } : data, {
            headers: {
                'Content-Type': e.target.id === 'settle-form' ? 'application/json' : "multipart/form-data"
            }
        })
            .then(res => {
                if (type === 'Gateway') {
                    // window.location = res.data.url
                    window.location = `/payment/${res?.data?.token}`
                }
                else if (type === 'CardToCard')
                    window.location = '/dashboard/transactions'
                else if (type === 'Crypto_Gateway') {
                    this.setState({
                        crypto_deposit_address: res.data?.crypto_pay_address,
                        crypto_deposit_payAmount: res.data?.crypto_pay_amount,
                        crypto_end_pay_date: res.data?.crypto_expiration_estimate_date,
                        crypto_deposit: true,
                        card_deposit: false,
                    })
                    cacheWalletState = {
                        ...this.state,
                        crypto_deposit_address: res.data?.crypto_pay_address,
                        crypto_deposit_payAmount: res.data?.crypto_pay_amount,
                        crypto_end_pay_date: res.data?.crypto_expiration_estimate_date,
                        crypto_deposit: true,
                        card_deposit: false,
                    }
                }
                else
                    window.location.reload()
            })
            .catch(err => {
                const data = err.response.data
                const code = err.response.status
                const message = !data.message ? '' : Array.isArray(data.message) ? data.message[0] : data.message

                if (e.target.id === 'settle-form')
                    cacheWalletState = {
                        ...this.state,
                        settleError: code !== 503 ? (!message ? 'خطا در ارسال درخواست' : message) : 'درخواست تسویه خارج از زمان مجاز می باشد',
                    }
                else
                    cacheWalletState = {
                        ...this.state,
                        depositError: !message ? 'خطا در ارسال درخواست' : message,
                    }
            })

        await this.props.setLoading({ isLoading: false })

        this.setState(cacheWalletState)
    }
    getBankAccountText(bank_id) {
        const bank_accounts = this.context.me.UserBankAccounts

        for (const a of bank_accounts) {
            if (a.id === bank_id)
                return `${a.bank_name} ${a.account_number.replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d])}`
        }
    }
    getStatusText(s) {
        switch (s) {
            case 'FAILED':
                return 'رد شده'

            case 'WAIT':
                return 'در انتظار تایید'

            case 'VERIFIED':
                return 'تایید شده'

            case 'SYSTEM_PAY':
                return 'درحال پرداخت'

            case 'SUCCESS':
                return 'موفق'

            default:
                break
        }
    }
    async getSettle() {
        await this.props.setLoading({ isLoading: true, isFailed: false, errorFunc: null })

        axios.get(`/api/settle?page=${this.state.page}`)
            .then(r => {
                const data = r.data

                cacheWalletState = {
                    ...cacheWalletState,
                    ...this.state,
                    next: data.next,
                    last: data.last,
                    settleRequests: data.data
                }

                this.getBankAccounts()
            })
            .catch(e => {
                this.props.setLoading({ isLoading: true, isFailed: true, errorFunc: () => this.getSettle() })
            })
    }
    getBankAccounts() {
        axios.get(`/api/bank_accounts`)
            .then(r => {
                const data = r.data

                cacheWalletState = {
                    ...cacheWalletState,
                    BankAccounts: data
                }

                this.props.setLoading({ isLoading: false, isFailed: false, errorFunc: null })
            })
            .catch(e => {
                this.props.setLoading({ isLoading: true, isFailed: true, errorFunc: () => this.getSettle() })
            })
    }
    async cancel(id) {
        await this.props.setLoading({ isLoading: true, isFailed: false, errorFunc: null })

        axios.delete(`/api/settle/${id}`)
            .then(r => {
                window.location.reload()
            })
            .catch(e => {
                this.props.setLoading({ isLoading: true, isFailed: true, errorFunc: () => this.getSettle() })
            })
    }

    // async getUsdPrice(){
    //     this.setState({
    //         usdPrice: (await axios.get('/api/price/usd')).data
    //     })
    // }

    async getGeneralSettings(){
        this.setState({
            settings: (await axios.get('/api/general-settings'))?.data
        })
    }

    async copyWalletAddress(value) {
        try {
            await navigator.clipboard.writeText(value);
            alert("در کلیپ‌بورد کپی شد")
        } catch (error) {
            alert("خطا در کپی")
        }
    }

    componentDidMount() {
        this.getGeneralSettings()
        !cacheWalletState && this.getSettle()
    }
    render() {
        return (
            <div className="wallet-container">
                <div className="overview">
                    <p className="header">
                        موجودی حساب من:
                    </p>

                    <p className="credit">
                        {this.context.me.credit.replace(/\B(?=(\d{3})+(?!\d))/g, '،').replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d])} تومان
                    </p>

                    <div>
                        <button style={{ backgroundColor: '#e60000' }} onClick={() => this.setState({ mode: 'settle' })}>برداشت</button>
                        <button style={{ backgroundColor: '#4baf01' }} onClick={() => this.setState({ mode: 'deposit' })}>واریز وجه</button>
                    </div>
                </div>

                <div className="form-container">
                    {/* Settle */}
                    {this.state.mode === 'settle' && <form className="form" id="settle-form" onSubmit={this.onSubmit}>
                        {this.state.settleError && <p className="error">{this.state.settleError}</p>}

                        <input type="text" name="amount" placeholder="مبلغ (تومان)" onInput={e => e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />

                        <div className="deposit-types">
                            <div className="deposit-type">
                                <label htmlFor="CardToCard">کارت بانکی</label>
                                <input id="CardToCard" name="type" value="CardToCard" type="radio" checked={this.state.card_settle} onChange={e => this.setState({ card_settle: true, crypto_settle: false })} />
                            </div>

                            {this.state.settings.allow_crypto_settle &&
                            <div className="deposit-type">
                                <label htmlFor="card">رمزارز</label>
                                <input id="crypto" name="type" value="Crypto" type="radio" checked={this.state.crypto_settle} onChange={e => this.setState({ crypto_settle: true, card_settle: false })} />
                            </div>
                            }
                        </div>


                        {this.state.card_settle &&
                        <select name="user_bank_id" defaultValue="">
                            <option value="" disabled hidden>حساب بانکی</option>
                            {this.context.me.UserBankAccounts.map((b, i) =>
                                !b.confirm_failed && !b.confirm_wait && <option key={i} value={b.id}>{`${b.bank_name} ${b.account_number.replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d])}`}</option>
                            )}
                        </select>
                        }

                        {this.state.crypto_settle &&
                        <select name="user_crypto_wallet_id" defaultValue="">
                            <option value="" disabled hidden>کیف پول</option>
                            {this.context.me.UserCryptoWallets.map((b, i) =>
                                <option key={i} value={b.id}>{`${b.token} - ${b.network} - ${b.address} `}</option>
                            )}
                        </select>
                        }

                        <p className="error" style={{ paddingTop: '30px' }}>
                            <span>حداکثر مبلغ قابل برداشت شما به تومان: </span>
                            <span>{this.context.me.credit.replace(/\B(?=(\d{3})+(?!\d))/g, '،').replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d])} </span>
                            <span
                                style={{ color: '#374ca3', textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => document.querySelector('#settle-form input[name=amount').value = this.context.me.credit.replace(/[^0-9.]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            >
                                انتخاب
                            </span>
                        </p>

                        <button className="submit" style={{ backgroundColor: '#e60000' }}>برداشت</button>
                    </form>}

                    {/* Deposit */}
                    {this.state.mode === 'deposit' && <form className="form" id="deposit-form" onSubmit={this.onSubmit}>
                        {this.state.depositError && <p className="error">{this.state.depositError}</p>}

                        <input
                            type="text"
                            name="amount"
                            placeholder= {`مبلغ (${this.state.crypto_deposit || this.state.crypto_gateway_deposit ? 'تتر': 'تومان'})`}
                            onInput={e => e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        />

                        {(this.state.crypto_deposit || this.state.crypto_gateway_deposit) && <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            width: '100%'
                        }}>
                            <p>قیمت تتر:</p>
                            <strong>{this.state.settings.sell_usdt_price?.toLocaleString()}</strong>
                            <button type="button" onClick={this.getGeneralSettings} className="submit" style={{width: 'max-content', margin: 0, marginTop: '8px', fontSize: '12px', padding: '0 10px'}}>
                                تازه سازی
                            </button>
                        </div>
                        }

                        <div className="deposit-types">

                            {this.state.settings.allow_gateway_deposit &&
                            <div className="deposit-type">
                                <label htmlFor="gateway">با درگاه</label>
                                <input id="gateway" name="type" value="Gateway" type="radio" onChange={e => this.setState({ card_deposit: false, crypto_deposit: false })} defaultChecked />
                            </div>
                            }

                            {this.state.settings.allow_cardToCard_deposit &&
                            <div className="deposit-type">
                                <label htmlFor="card">کارت به کارت</label>
                                <input id="card" name="type" value="CardToCard" type="radio" checked={this.state.card_deposit} onChange={e => this.setState({crypto_deposit: false, card_deposit: true, depositError: 'مبلغ واریز مجاز بین ۱۰،۰۰۰ تومان و ۲۰۰،۰۰۰،۰۰۰ تومان می باشد'})} />
                            </div>
                            }

                            {this.state.settings.allow_crypto_deposit && <>
                            {/*<div className="deposit-type">*/}
                            {/*    <label htmlFor="crypto_gateway">رمزارز - واریز سریع با کارمزد</label>*/}
                            {/*    <input id="crypto_gateway" name="type" value="Crypto_Gateway" type="radio" checked={this.state.crypto_gateway_deposit} onChange={async e => {*/}
                            {/*        this.setState({crypto_gateway_deposit: true, crypto_deposit: false, card_deposit: false, depositError: `مبلغ واریز مجاز بین ${(this.state.settings.sell_usdt_price * 10).toLocaleString()} تومان و 200,000,000 تومان می باشد`})*/}
                            {/*    }} />*/}
                            {/*</div>*/}

                            <div className="deposit-type">
                                <label htmlFor="crypto">رمزارز</label>
                                <input id="crypto" name="type" value="Crypto" type="radio" checked={this.state.crypto_deposit} onChange={async e => {
                                    this.setState({crypto_deposit: true, card_deposit: false, crypto_gateway_deposit: false, depositError: `مبلغ واریز مجاز بین ${(this.state.settings.sell_usdt_price * 10).toLocaleString()} تومان و 200,000,000 تومان می باشد`})
                                }} />
                            </div>
                            </>
                            }
                        </div>

                        {this.state.crypto_deposit && <>
                            <div style={{lineHeight: 2, paddingTop: "15px"}}>
                                * مبلغ
                                تتر(USDT) مورد نظر را در شبکه
                                <strong style={{fontWeight: 'bold', textDecoration: 'underline'}}> TRC20 </strong>
                                به آدرس کیف پول پایین واریز کنید سپس شناسه تراکنش (tx-hash) را در کادر پایین وارد کنید و
                                منتظر تایید و شارژ کیف پول بمانید.

                                <div style={{marginTop: "20px"}}>
                                    <label htmlFor="walletAddress" style={{fontWeight: 'bold'}}>آدرس کیف پول مقصد</label>
                                    <input id="walletAddress" type="text" value={this.state.settings.admin_usdt_trc20_wallet}
                                           readOnly/>
                                </div>

                                <div style={{marginTop: "30px"}}>
                                    <label htmlFor="txHash" style={{fontWeight: 'bold'}}>شناسه تراکنش</label>
                                    <input type="text" id="txHash" name="txHash"/>
                                </div>


                            </div>
                        </>}

                        {this.state.card_deposit && <>
                            <select name="user_bank_id" defaultValue="">
                                <option value="" disabled hidden>حساب بانکی مبدا</option>
                                {this.context.me.UserBankAccounts.map((b, i) =>
                                    !b.confirm_failed && !b.confirm_wait && <option key={i} value={b.id}>{`${b.bank_name} ${b.account_number.replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d])}`}</option>
                                )}
                            </select>

                            <select name="bank_id" defaultValue="">
                                <option value="" disabled hidden>حساب بانکی مقصد</option>
                                {Object.values(this.state.BankAccounts).map((b, i) =>
                                    <option key={i} value={b.id}>{`${b.bank_name} ${b.card_number.replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d])}`}</option>
                                )}
                            </select>

                            <label htmlFor="image">:تصویر کارت به کارت</label>
                            <input type="file" id="image" name="image" />
                        </>}

                        {this.state.crypto_gateway_deposit && <>

                            <br/>
                            {this.state.crypto_deposit_address &&
                            <div style={{lineHeight: 2, paddingTop: "15px"}}>
                                * مبلغ
                                <strong style={{fontWeight: 'bold', textDecoration: 'underline'}}> {this.state.crypto_deposit_payAmount} </strong>
                                تتر(USDT) را در شبکه
                                <strong style={{fontWeight: 'bold', textDecoration: 'underline'}}> TRC20 </strong>
                                 به آدرس کیف پول پایین واریز کنید و منتظر تایید و شارژ کیف پول بمانید.
                                <div>
                                    <span>:مهلت پرداخت تا تاریخ</span>
                                    <strong style={{fontWeight: 'bold', textDecoration: 'underline'}}>
                                        {new Intl.DateTimeFormat("fa-IR", {
                                            year: "numeric",
                                            month: "long",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                            timeZone: "Asia/Tehran"
                                        }).format(new Date(this.state.crypto_end_pay_date))}
                                    </strong>
                                </div>
                                <p>مبلغ اضافی برای پوشش کارمزد تراکنش‌ها می‌باشد</p>
                                <p>همچنین بعد از واریز مبلغ لزومی برای ماندن در این صفحه وجود ندارد</p>
                            </div>
                            }

                            {this.state.crypto_deposit_address &&
                                <>
                                    <label htmlFor="walletAddress">:آدرس کیف پول مقصد</label>
                                    <input id="walletAddress" type="text" value={this.state.crypto_deposit_address} readOnly/>
                                    {/*<button className="submit" style={{width: 'max-content', margin: 0, marginTop: '8px', fontSize: '12px', padding: '0 10px'}} onClick={(e) => this.copyWalletAddress(this.state.crypto_deposit_address)} >کپی آدرس</button>*/}
                                </>
                        }
                        </>}

                        <button className="submit">واریز وجه</button>
                    </form>}
                </div>

                <div className="table-container">
                    <p className="title">تاریخچه برداشت</p>

                    <table>
                        <thead>
                            <tr>
                                <th>مبلغ</th>
                                <th>بانک</th>
                                <th>وضعیت</th>
                                <th>تاریخ برداشت</th>
                                <th>توضیحات</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.state.settleRequests.map((r, i) =>
                                <tr key={i}>
                                    <td>{r.amount.toLocaleString('fa-IR')}</td>
                                    <td>{r.user_crypto_wallet_id ? 'رمز ارز' : this.getBankAccountText(r.user_bank_id)}</td>
                                    <td>{this.getStatusText(r.status)}</td>
                                    <td>{new Date(r.create_date).toLocaleString('fa-IR', { year: "numeric", month: "numeric", day: 'numeric' })}</td>
                                    <td>{r.status !== 'FAILED' ? r?.note : r.fail_reason}</td>
                                    {r.status === 'WAIT' && <td onClick={() => this.cancel(r.id)} style={{ textDecoration: 'underline', cursor: 'pointer' }}>حذف</td>}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className="pagination">
                    {this.state.next && <p onClick={() => this.setState({ page: this.state.page + 1 }, () => this.getSettle())}>&laquo;</p>}
                    {this.state.last && <p onClick={() => this.setState({ page: this.state.page - 1 }, () => this.getSettle())}>&raquo;</p>}
                </div>
            </div>
        )
    }
}

Wallet.contextType = AuthContext

export default Wallet
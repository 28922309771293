import React, { Component } from 'react'
import Loader from './loader'
import axios from 'axios'
import Mage from '../../images/Help.png'

export default class Guide extends Component {
    constructor() {
        super()
        this.state = {
            isLoading: true,
            isFailed: false,
            text: ''
        }
    }
    loadText() {
        this.setState({ isLoading: true, isFailed: false }, () => {
            axios.get('/api/general-settings?key=guide')
                .then(res => {
                    this._isMounted && this.setState({ isLoading: false, text: res.data.guide })
                })
                .catch(() => this._isMounted && this.setState({ isFailed: true }))
        })
    }
    componentDidMount() {
        this.loadText()
        this._isMounted = true

        const root = document.documentElement
        root.style.setProperty('--bg-color', 'white')
        root.style.setProperty('--fg-color', 'black')
    }
    componentWillUnmount() {
        this._isMounted = false
    }
    render() {
        if (this.state.isLoading)
            return <Loader isFailed={this.state.isFailed} failedCallback={() => this.loadText()} />
        else
            return (
                <div className="general-container general-data">
                    <div className="data">
                        <p className="title">راهنمای وب سایت</p>

                        <p className="mage-title">{this.state.text}</p>
                    </div>

                    <img className="mage" src={Mage} alt="mage" />
                </div>
            )
    }
}

import React, { Component } from 'react'
import { io } from 'socket.io-client'
import axios from 'axios'
import AuthContext from '../AuthContext'
import Chart from './room/chart'
import ChartHeader from './room/chart-header'
import ConnectionModal from './room/modals/connection-modal'
import History from './room/history'
import OrderForm from './room/order-form'
import OrderList from './room/order-list'
import Portfo from './room/portfo'
import TpSlModal from './room/modals/tpsl-modal'
import PortfoModal from './room/modals/portfo-modal'
import TermsModal from './room/modals/terms-modal'
import Navbar from './room/navbar'
import Average from './room/average'
import NotiClose from '../images/noti_cancel.png'

class Room extends Component {
    socket = null

    constructor() {
        super()
        if(!localStorage.getItem('uid')) window.location.href = '/dashboard'
        axios.defaults.headers.common['uid'] = localStorage.getItem('uid')

        this.state = {
            openNotificationModal: true,
            isFirst: false,
            isLoading: true,
            isConnected: false,
            ConnectionError: '',
            isFailed: false,
            showNotification: true,
            isMaxConnections: false,
            isDisabled: false,
            notification: '',
            error: '',
            modal: '',
            nav: 'trade',
            portfo: false,
            portfoCount: 0,
            myOrders: [],
            deals: [],
            tpsl_deal: {},
            user: {},
            room: {},
            price: {
                value: 0,
                lastDealValue: 0
            },
            metaPrice: {
                GlobalOunce: 0,
                HaratDollar: 0
            },
            orders: {
                BUY: [],
                SELL: []
            },
            barHandler: {
                resolution: '',
                callback: bar => { }
            },
            isMobile: document.documentElement.clientWidth <= 1000
        }

        this.changeState = this.changeState.bind(this)
        this.calcUnitAmount = this.calcUnitAmount.bind(this)
    }
    resetData() {
        this.setState({
            myOrders: [],
            deals: [],
            tpsl_deal: {},
            orders: {
                BUY: [],
                SELL: []
            }
        })
    }
    formatPrice(p) {
        p = p.toString()

        if (p.length > 5) {
            p = p.slice(0, -3)
        }

        p = parseInt(p).toLocaleString('fa-IR')

        return p
    }
    changeState(state) {
        return new Promise((resolve, reject) => {
            try {
                this.setState({ ...state }, () => resolve())
            }
            catch (e) {
                reject(e)
            }
        })
    }
    unitCountNumber(unit_count) {
        switch (unit_count) {
            case 'Fifty':
                unit_count = 100
                break
            case 'OneHundred':
                unit_count = 200
                break
            case 'TwoHundred':
                unit_count = 400
                break
            default:
                break
        }

        return unit_count
    }
    async calcUnitAmount(_amount, _unit_count) {
        const amount = parseInt(_amount.toString().replace(/,/g, ''))
        const unit_price = this.state.room.unit_min_price
        let unit_count = this.unitCountNumber(_unit_count)

        return Math.floor(amount / (unit_count * unit_price))
    }
    scrollNotification() {
        setInterval(() => {
            try {
                const elem = document.querySelector('.system_notification .text')
                elem.scrollBy(elem.scrollLeft - 100, 0)
            }
            catch (e) { }
        }, 50)
    }
    componentDidMount() {
        // if(Object.keys(this.context.me).length > 0 && (!this.context.me?.email || (this.context.me?.email && !this.context.me?.emailConfirmed)) && !window.location.href.includes('dashboard/personal_info')){
        //     window.location = "/dashboard/personal_info";
        // }

        window.onresize = e => {
            const w = e.target.innerWidth
            this.setState({ isMobile: w <= 1000 })
        }

        // Check for first time
        const isFirst = localStorage.getItem('first')
        if (!isFirst)
            this.setState({ isFirst: true })

        // Init socket
        this.socket = io({
            auth: {
                token: this.context.token
            },
            transports: ["websocket"]
        })

        this.socket.on('connect', () => {
            axios.defaults.headers.common['Authorization'] = this.context.token
            this.setState({ ConnectionError: '', isLoading: false, isConnected: true, isFailed: false, isMaxConnections: false, isDisabled: false })
        })

        this.socket.on("connect_error", e => {
            const message = e.message

            if (isNaN(parseInt(message))) {
                this.setState({ ConnectionError: '0', isLoading: true, isConnected: false, isFailed: false, isMaxConnections: false, isDisabled: false })
                return
            }

            if (message === '-2')
                window.location = '/dashboard'
            else
                this.setState({ ConnectionError: message, isLoading: true, isConnected: false, isFailed: false, isMaxConnections: false, isDisabled: false })
        })

        this.socket.on('disconnect', () => {
            this.resetData()
            this.setState({ ConnectionError: '0', isLoading: true, isConnected: false, isFailed: true, isMaxConnections: false, isDisabled: false })
        })

        this.socket.on('message.error', data => {
            this.setState({ error: data })
        })

        this.socket.on('user.update', data => {
            this.setState({ user: data })
        })

        this.socket.on('notification', data => {
            this.setState({ notification: data }, () => this.scrollNotification())
        })

        this.socket.on('room.update', room => {
            this.setState({ room })
        })

        this.socket.on('room.meta.update', metaPrice => {
            this.setState({ metaPrice })
        })

        this.socket.on('portfo.update', data => {
            if (!data)
                this.setState({ portfo: null })
            else
                this.setState({ portfo: data })
        })

        this.socket.on('portfo.count', data => {
            this.setState({ portfoCount: data })
        })

        this.socket.on('price.update', data => {
            this.setState({ price: data })
        })

        this.socket.on('order.my', orders => {
            this.setState({ myOrders: orders.sort((a, b) => b.timestamp - a.timestamp) })
        })

        this.socket.on('order.my.update', order => {
            this.setState({ myOrders: [order, ...this.state.myOrders.filter(o => o.id !== order.id)] })
        })

        this.socket.on('order.my.remove', id => {
            this.setState({ myOrders: this.state.myOrders.filter(o => o.id !== id) })
        })

        this.socket.on('order', order => {
            // eslint-disable-next-line
            const data = this.state.orders[order.type].filter(o => o.fee != order.fee)

            this.setState({
                orders: {
                    ...this.state.orders,
                    [order.type]: [...data, { fee: order.fee, count: order.count }]
                }
            })
        })

        this.socket.on('orders', orders => {
            this.setState({ orders })
        })

        this.socket.on('deals', deals => {
            this.setState({ deals: deals.sort((a, b) => b.id - a.id) })
        })

        this.socket.on('deal.new', deal => {
            this.setState({ deals: [deal, ...this.state.deals.filter(d => d.id !== deal.id)] })
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (localStorage.getItem('theme') === 'DARK') {
            const room = document.documentElement
            room.style.setProperty('--bg-color', '#2b2a34')
            room.style.setProperty('--fg-color', 'white')
            room.style.setProperty('--chart-header-color', '#2b2a34')
        }

        if (prevState.error)
            setTimeout(() => {
                try {
                    document.querySelector('.alert').style.opacity = 0
                    setTimeout(() => this.setState({ error: '' }), 400)
                }
                catch (err) { }
            }, 4000)

        if (this.state.barHandler !== prevState.barHandler) {
            this.socket.off(`chart/${prevState.barHandler.resolution}/new`)

            this.socket.on(`chart/${this.state.barHandler.resolution}/new`, log => {
                this.state.barHandler.callback({
                    time: log.timestamp * 1000,
                    open: log.entry_price,
                    high: log.max_price,
                    low: log.min_price,
                    close: log.close_price
                })
            })
        }
    }
    componentWillUnmount() {
        if (this.socket !== null) {
            this.socket.disconnect()
            this.socket = null
        }
    }
    render() {
        return (
            <div className="room-container" style={(this.state.isMobile && this.state.nav === 'trade') ? { height: '850px' } : {}}>
                {this.state.error && <div className="alert">
                    <span className="closebtn" onClick={e => this.setState({ error: '' })}>&times;</span>
                    {this.state.error}
                </div>}
                {(this.state.notification && this.state.showNotification) &&
                    <div className="system_notification">
                        <div className="close" onClick={() => this.setState({ showNotification: false })}>
                            <img src={NotiClose} alt="close" />
                        </div>

                        <div className="text">
                            <p>{this.state.notification}</p>
                        </div>
                    </div>
                }

                {/* Modals */}
                {/*{this.state.openNotificationModal &&*/}
                {/*    <NotificationModal onClose={()=> this.setState({openNotificationModal: false})} />*/}
                {/*}*/}

                <ConnectionModal
                    isLoading={this.state.isLoading}
                    ConnectionError={this.state.ConnectionError}
                    reconnect={() => this.socket && this.setState({ isLoading: true, ConnectionError: '' }, () => this.socket.connect())}
                />
                {this.state.modal.startsWith('tpsl') &&
                    <TpSlModal
                        room={this.state.room}
                        deal={this.state.tpsl_deal}
                        deals={this.state.deals}
                        changeState={this.changeState}
                    />
                }
                {this.state.modal.startsWith('portfo') &&
                    <PortfoModal
                        socket={this.socket}
                        portfo={this.state.portfo}
                        user={this.state.user}
                        changeState={this.changeState}
                        calcUnitAmount={this.calcUnitAmount}
                    />
                }
                {this.state.isFirst &&
                    <TermsModal changeState={this.changeState} room={this.state.room} />
                }

                {(!this.state.isMobile || (this.state.isMobile && this.state.nav === 'chart')) && <div className="chart-main-container">
                    <ChartHeader
                        isConnected={this.state.isConnected}
                        price={this.state.price}
                        metaPrice={this.state.metaPrice}
                        portfoCount={this.state.portfoCount}
                        isMobile={this.state.isMobile}
                    />

                    {(!this.state.isLoading && !this.state.isFailed) ?
                        <Chart socket={this.socket} changeState={this.changeState} /> :
                        <div className="chart-container"></div>
                    }
                </div>}

                {(!this.state.isMobile || (this.state.isMobile && this.state.nav === 'trade')) && <div className="trade-container">
                    <Portfo
                        socket={this.socket}
                        portfo={this.state.portfo}
                        changeState={this.changeState}
                    />

                    <Average deals={this.state.deals} formatPrice={this.formatPrice} />

                    <div className="order-container">
                        <OrderForm
                            socket={this.socket}
                            portfo={this.state.portfo}
                            myOrders={this.state.myOrders}
                            changeState={this.changeState}
                            user={this.state.user}
                        />

                        <OrderList
                            formatPrice={this.formatPrice}
                            price={this.state.price}
                            orders={this.state.orders}
                            portfo={this.state.portfo}
                            user={this.state.user}
                        />
                    </div>

                    <History
                        socket={this.socket}
                        room={this.state.room}
                        price={this.state.price}
                        portfo={this.state.portfo}
                        formatPrice={this.formatPrice}
                        deals={this.state.deals}
                        myOrders={this.state.myOrders}
                        changeState={this.changeState}
                        unitCountNumber={this.unitCountNumber}
                        calcUnitAmount={this.calcUnitAmount}
                    />
                </div>}

                <Navbar current={this.state.nav} changeState={this.changeState} />
            </div>
        )
    }
}

Room.contextType = AuthContext

export default Room
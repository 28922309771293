import React, { Component } from 'react'
import axios from 'axios'

export default class PortfoModal extends Component {
    constructor(props) {
        super()
        this.state = {
            isLoading: false,
            amount: '',
            type: props.portfo ? 'settings' : 'open',
            unit_count: 'Fifty',
            portfo_type: 'CROSS',
            unit_amount: 0
        }
        this.onChange = this.onChange.bind(this)
    }
    create() {
        const amount = parseInt(this.state.amount.replace(/,/g, ''))

        if (!this.state.unit_amount)
            this.props.changeState({ error: 'حداقل یک واحد برای ساخت پرتفو نیاز می باشد' })
        else
            this.setState({ isLoading: true }, () => {
                axios.post(`/api/trade/room/1/portfo`, {
                    amount,
                    unit_count: this.state.unit_count,
                    type: this.state.portfo_type
                })
                    .then(res => {
                        this.props.changeState({ isLoading: false, portfo: res.data, modal: '' })
                        this.props.socket.emit('user.update')
                    })
                    .catch(e => {
                        const data = e.response.data
                        const message = !data.message ? '' : Array.isArray(data.message) ? data.message[0] : data.message

                        this.setState({
                            isLoading: false
                        })

                        this.props.changeState({
                            error: !message ? 'خطا در ارسال درخواست' : message
                        })
                    })
            })
    }
    deposit() {
        const amount = parseInt(this.state.amount.replace(/,/g, ''))

        if (!amount || amount < 1000)
            this.props.changeState({ error: 'حداقل مبلغ ۱،۰۰۰ تومان می باشد' })
        else if (amount > this.props.user.credit)
            this.props.changeState({ error: 'موجودی ناکافی می باشد' })
        else
            this.setState({ isLoading: true }, () => {
                axios.put(`/api/trade/room/1/portfo/${this.props.portfo.id}/deposit`, { amount })
                    .then(res => {
                        this.props.changeState({ isLoading: false, portfo: res.data, modal: '' })
                        this.props.socket.emit('user.update')
                    })
                    .catch(e => {
                        const data = e.response.data
                        const message = !data.message ? '' : Array.isArray(data.message) ? data.message[0] : data.message

                        this.setState({
                            isLoading: false
                        })

                        this.props.changeState({
                            error: !message ? 'خطا در ارسال درخواست' : message
                        })
                    })
            })
    }
    close() {
        this.setState({ isLoading: true }, () => {
            axios.put(`/api/trade/room/1/portfo/${this.props.portfo.id}/close`)
                .then(() => {
                    this.props.changeState({
                        isLoading: false,
                        portfo: null,
                        modal: '',
                        myOrders: [],
                        deals: []
                    })
                    this.props.socket.emit('user.update')
                    localStorage.setItem("system_u", "1")
                })
                .catch(e => {
                    const data = e.response.data
                    const message = !data.message ? '' : Array.isArray(data.message) ? data.message[0] : data.message

                    this.setState({
                        isLoading: false
                    })

                    this.props.changeState({
                        error: !message ? 'خطا در ارسال درخواست' : message
                    })
                })
        })
    }
    async onChange(e) {
        const name = e.target.name
        const value = e.target.value
        const unit_amount = await this.props.calcUnitAmount(name === 'amount' ? value : this.state.amount, name === 'unit_count' ? value : this.state.unit_count)

        if (name === 'portfo_type' && value === 'CROSS')
            this.setState({
                unit_count: 'Fifty'
            })

        this.setState({
            [name]: value,
            unit_amount: isNaN(unit_amount) ? 0 : unit_amount
        })
    }
    render() {
        return (
            <div className="modal portfo-modal">
                <div className="container" style={this.state.type === 'settings' ? { height: '350px' } : { height: '430px' }}>
                    {this.state.isLoading ? <div className="loader"></div> :
                        <>
                            <div className="header">
                                <p className="close-btn" onClick={() => this.props.changeState({ modal: '' })}>X</p>
                                {this.state.type === 'settings' && <p className="text">مدیریت پرتفو</p>}
                                {this.state.type === 'open' && <p className="text">باز کردن پرتفو</p>}
                            </div>

                            <div className="content" style={this.state.type === 'open' ? { justifyContent: 'space-between', alignItems: 'flex-end' } : {}}>
                                {this.state.type === 'settings' && <>
                                    <div className="input-container">
                                        <label>مبلغ: <input type="text" name="amount" value={this.state.amount} onChange={e => this.setState({ amount: e.target.value })} onInput={e => e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')} /></label>
                                        <p style={{ color: 'grey', lineHeight: '1.8em' }}>کل مبلغ کیف پول اصلی: {parseInt(this.props.user.credit).toLocaleString('fa-IR')} تومان</p>
                                    </div>

                                    <p className="btn submit-btn" onClick={() => this.deposit()}>اضافه کردن مبلغ به پرتفو</p>
                                    <p className="btn close-portfo-btn" onClick={() => this.close()}>بستن پرتفو</p>
                                </>}

                                {this.state.type === 'open' && <>
                                    <div className="open-select">
                                        <p>نوع پرتفو: </p>

                                        <select name="portfo_type" value={this.state.portfo_type} onChange={this.onChange}>
                                            <option value="CROSS">Cross</option>
                                            <option value="ISOLATED">Isolated</option>
                                        </select>
                                    </div>

                                    <div className="open-select">
                                        <p>اندازه واحد: </p>

                                        <select name="unit_count" value={this.state.unit_count} onChange={this.onChange} disabled={this.state.portfo_type === 'CROSS'}>
                                            <option value="Fifty">100</option>
                                            <option value="OneHundred">200</option>
                                            <option value="TwoHundred">400</option>
                                        </select>
                                    </div>

                                    <div className="open-input-container">
                                        <label>وجه تضمین: <input type="text" name="amount" value={this.state.amount} onChange={this.onChange} onInput={e => e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')} /></label>
                                    </div>

                                    <p className="amount-count">تعداد واحد: <span>{this.state.unit_amount.toLocaleString('fa-IR', { useGrouping: false })} واحد</span></p>

                                    <p style={{ color: 'grey', margin: '10px 0', lineHeight: '1.8em', direction: 'rtl' }}>کل مبلغ کیف پول اصلی: {parseInt(this.props.user.credit).toLocaleString('fa-IR')} تومان</p>

                                    <p className="btn submit-btn" style={{ marginBottom: 0 }} onClick={() => this.create()}>باز کردن پرتفو</p>
                                </>}
                            </div>
                        </>
                    }
                </div>
            </div>
        )
    }
}

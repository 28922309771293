import React, { Component } from 'react'
import Menu from '../dashboard/menu'
import Loader from './loader'
import axios from 'axios'
import { useLocation, useParams, useNavigate } from 'react-router-dom'

class Blog extends Component {
    constructor() {
        super()
        this.state = {
            isLoading: true,
            isFailed: false,
            next: false,
            last: false,
            page: 0,
            menuItems: [],
            posts: [],
            post: null
        }
    }
    getCategories() {
        return new Promise((resolve, reject) => {
            this.setState({ isLoading: true, isFailed: false }, async () => {
                await axios.get('/api/blog/categories')
                    .then(res => {
                        this._isMounted && this.setState({
                            isLoading: Object.keys(res.data).length,
                            menuItems: Object.values(res.data).map(c => ({ name: c.name, path: '/blog/category/' + c.name }))
                        }, () => {
                            this.state.menuItems[0] && this.props.navigate(this.state.menuItems[0].path)
                            resolve()
                        })
                    })
                    .catch(() => this._isMounted && this.setState({ isFailed: true }, () => resolve()))
            })
        })
    }
    getPosts() {
        this.setState({ isLoading: true, isFailed: false, post: null }, async () => {
            await axios.get(`/api/blog?category_name=${this.props.slug}&page=${this.state.page}`)
                .then(res => {
                    const data = res.data

                    this._isMounted && this.setState({
                        isLoading: false,
                        posts: Object.values(data.data),
                        next: data.next,
                        last: data.last
                    })
                })
                .catch(() => this._isMounted && this.setState({ isFailed: true }))
        })
    }
    getPost(id) {
        this.setState({ isLoading: true, isFailed: false, posts: [] }, async () => {
            await axios.get('/api/blog/' + id)
                .then(res => {
                    this._isMounted && this.setState({
                        isLoading: false,
                        post: res.data
                    })
                })
                .catch(() => this._isMounted && this.setState({ isFailed: true }))
        })
    }
    async componentDidMount() {
        this._isMounted = true
        await this.getCategories()
        this.getPosts()

        const root = document.documentElement
        root.style.setProperty('--bg-color', 'white')
        root.style.setProperty('--fg-color', 'black')
    }
    componentDidUpdate(prevProps) {
        if (this.props.slug !== prevProps.slug)
            !this.props.slug ? this.getCategories() : this.getPosts()
    }
    componentWillUnmount() {
        this._isMounted = false
    }
    render() {
        if (this.state.isLoading)
            return <Loader isFailed={this.state.isFailed} failedCallback={() => this.getCategories()} />
        else
            return (
                <div className="general-container blog-container">
                    <Menu items={this.state.menuItems} isCategory={true} />

                    {this.state.post ?
                        <div className="post-container">
                            <div className="header">
                                <img src={`/assets/images/blog/${this.state.post.id}.png`} alt="thumbnail13" />

                                <div className="data">
                                    <p>{this.state.post.subject}</p>
                                    <p>تاریخ ایجاد : {new Date(this.state.post.create_date).toLocaleDateString('fa-IR')} - بازدید : {this.state.post.view_count.toLocaleString('fa-IR')}</p>
                                </div>

                                <div className="tags">
                                    <p>تگ ها</p>
                                    <p>{this.state.post.tags}</p>
                                </div>
                            </div>

                            <p className="description">{this.state.post.description}</p>

                            {this.state.post.contain_video &&
                                <video controls>
                                    <source src={`/assets/videos/blog/${this.state.post.id}.mp4`} type="video/mp4" />
                                </video>
                            }

                            <p className="body">{this.state.post.body}</p>
                        </div>
                        :
                        <div className="posts-container">
                            {!this.state.posts.length ? <p>پستی برای نمایش وجود ندارد</p> : this.state.posts.map((p, i) =>
                                <div key={i} className="post" onClick={() => this.getPost(p.id)}>
                                    <img src={`/assets/images/blog/${p.id}.png`} alt="thumbnail" />

                                    <div className="data">
                                        <div className="subject-container">
                                            <p className="subject">{p.subject}</p>
                                            <p className="create_date">تاریخ ایجاد : {new Date(p.create_date).toLocaleDateString('fa-IR')}</p>
                                        </div>

                                        <p className="description">{p.description}</p>
                                    </div>

                                    <div className="more-image">
                                        <svg viewBox="0 0 460.5 531.74" overflow="visible" enableBackground="new 0 0 460.5 531.74">
                                            <polygon points="460,530.874 1,265.87 460,0.866 " />
                                        </svg>
                                    </div>
                                </div>
                            )}

                            <div className="pagination">
                                {this.state.last && <p onClick={() => this.setState({ page: this.state.page - 1 }, () => this.getPosts())}>&raquo;</p>}
                                {this.state.next && <p onClick={() => this.setState({ page: this.state.page + 1 }, () => this.getPosts())}>&laquo;</p>}
                            </div>
                        </div>
                    }
                </div>
            )
    }
}

export default function HOC() {
    let { slug } = useParams()
    return <Blog slug={slug} location={useLocation()} navigate={useNavigate()} />
}
import React, { Component } from 'react'
import axios from 'axios'
import Cancel from "../../images/cancel.png"

export default class History extends Component {
    constructor() {
        super()
        this.state = {
            nav: 'orders',
            is_top: false,
            deal_avg: 0,
            deal_amount: 0,
            open_deals: [],
            close_deals: []
        }
    }
    calcProfitLoss(deal, value) {
        const unit_price = Math.floor(this.props.room.unit_min_price / 1000)
        let profit_loss = (deal.entry_fee - value) * deal.amount * unit_price
        profit_loss = (deal.type !== 'SELL') ? (-1 * profit_loss) : profit_loss

        if (profit_loss === 0)
            profit_loss = 0

        return profit_loss
    }
    calcCallMargin(deal, portfo) {
        const call_margin = !portfo.call_margin ? this.props.room.default_call_margin : portfo.call_margin

        if (portfo.type === 'CROSS') {
            const amount = portfo.entry_amount + portfo.added_amount + portfo.profit_loss - portfo.commission
            const amount_percentage = (amount * call_margin) / 100
            const C = Math.ceil(amount_percentage / (this.props.room.unit_min_price * this.state.deal_amount))
            const E = Math.ceil(this.state.deal_avg / this.state.deal_amount)

            if (deal.type !== 'SELL')
                return E - (C * 1000)
            else
                return E + (C * 1000)
        }

        if (portfo.type === 'ISOLATED') {
            let unit = (this.props.unitCountNumber(portfo.unit_count) * call_margin) / 100
            unit *= 1000

            if (deal.type !== 'SELL')
                return deal.entry_fee - unit
            else
                return deal.entry_fee + unit
        }
    }
    async lockDeal(deal, index) {
        if (deal.load)
            return

        let deals = this.props.deals
        deal.load = true
        deals[index] = deal

        await this.props.changeState({ deals })

        axios.put(`/api/trade/room/1/deal/${deal.order_id}/lock`)
            .catch(e => {
                const data = e.response.data
                const message = !data.message ? '' : Array.isArray(data.message) ? data.message[0] : data.message

                deals = this.props.deals
                delete deal.load
                deals[index] = deal

                this.props.changeState({
                    deals,
                    error: !message ? 'خطا در ارسال درخواست' : message
                })
            })
    }
    async closeDeal(deal, index) {
        if (deal.load)
            return

        let deals = this.props.deals
        deal.load = true
        deals[index] = deal

        await this.props.changeState({ deals })

        axios.put(`/api/trade/room/1/deal/${deal.order_id}/close`)
            .then(res => {
                // deals = this.props.deals
                // deals[index] = res.data

                // this.props.changeState({ deals })
            })
            .catch(e => {
                const data = e.response.data
                const message = !data.message ? '' : Array.isArray(data.message) ? data.message[0] : data.message

                deals = this.props.deals
                delete deal.load
                deals[index] = deal

                this.props.changeState({
                    deals,
                    error: !message ? 'خطا در ارسال درخواست' : message
                })
            })
    }
    async deleteOrder(order, index) {
        let orders = this.props.myOrders
        order.delete = true
        orders[index] = order

        await this.props.changeState({ myOrders: orders })

        axios.delete('/api/trade/room/1/portfo/order/' + order.id)
            .then(res => {
                orders = this.props.myOrders.filter(item => item.id !== order.id)

                this.props.changeState({
                    myOrders: orders
                })

                this.props.socket.emit('portfo')
            })
            .catch(e => {
                const data = e.response.data
                const message = !data.message ? '' : Array.isArray(data.message) ? data.message[0] : data.message

                orders = this.props.myOrders
                delete order.delete
                orders[index] = order

                this.props.changeState({
                    myOrders: orders,
                    error: !message ? 'خطا در ارسال درخواست' : message
                })
            })
    }
    async combineDeals() {
        let is_top = false
        let deal_avg = 0
        let deal_amount = 0
        const open_deals = {}
        const close_deals = {}

        await Promise.all(this.props.deals.map(deal => {
            // Sum Open and Closing Deals
            if (deal.status === 'OPEN' || deal.status === 'CLOSING') {
                is_top = true

                if (!open_deals[deal.order_id])
                    open_deals[deal.order_id] = deal
                else {
                    const _deal = open_deals[deal.order_id]
                    _deal.init_amount += deal.init_amount
                    _deal.amount += deal.amount
                    _deal.remain += deal.remain
                    _deal.profit_loss += deal.profit_loss
                }

                deal_amount += deal.remain
                deal_avg += deal.remain * deal.entry_fee
            }

            // Sum Close Deals
            if (deal.status === 'CLOSE' && deal.close_fee) {
                if (!close_deals[`${deal.order_id}-${deal.close_fee}`])
                    close_deals[`${deal.order_id}-${deal.close_fee}`] = deal
                else {
                    const _deal = close_deals[`${deal.order_id}-${deal.close_fee}`]
                    _deal.init_amount += deal.init_amount
                    _deal.amount += deal.amount
                    _deal.profit_loss += deal.profit_loss
                }
            }

            return deal
        }))

        this.setState({
            deal_avg,
            deal_amount,
            open_deals,
            close_deals,
            is_top
        })
    }
    componentDidMount() {
        this.combineDeals()
    }
    componentDidUpdate(prevProps) {
        if (prevProps.deals !== this.props.deals)
            this.combineDeals()
    }
    render() {
        return (
            <div className="history-container" style={this.state.is_top ? { marginTop: '10px' } : {}}>
                <div className="nav">
                    <div className="wrapper">
                        <p className={this.state.nav === 'orders' ? 'active' : ''} onClick={() => this.setState({ nav: 'orders' })}>لفظ های من</p>

                        <div className="line"></div>

                        <p className={this.state.nav === 'open-deals' ? 'active' : ''} onClick={() => this.setState({ nav: 'open-deals' })}>معاملات باز</p>

                        <div className="line"></div>

                        <p className={this.state.nav === 'close-deals' ? 'active' : ''} onClick={() => this.setState({ nav: 'close-deals' })}>معاملات بسته شده</p>
                    </div>
                </div>

                <div className="data">
                    <table className="wrapper">
                        <thead>
                            <tr>
                                <th>ورود</th>
                                <th>نوع</th>
                                <th>حجم</th>
                                {this.state.nav === 'close-deals' && <th>خروج</th>}
                                {this.state.nav === 'orders' && <th>تلورانس</th>}
                                {this.state.nav === 'open-deals' && <th>وضعیت</th>}
                                {this.state.nav === 'open-deals' && <th>حراج</th>}
                                {this.state.nav === 'close-deals' && <th>سود/ضرر</th>}
                                {(this.state.nav === 'open-deals' || this.state.nav === 'orders') && <th>حد سود/ضرر</th>}
                                {this.state.nav === 'open-deals' && <th>بستن</th>}
                                {this.state.nav === 'orders' && <th onClick={() => this.props.myOrders.map((o, i) => this.deleteOrder(o, i))} style={{ cursor: 'pointer' }}>لغو همه</th>}
                                {this.state.nav === 'open-deals' && <th>قفل</th>}
                            </tr>
                        </thead>

                        <tbody>
                            {this.state.nav === 'orders' &&
                                //this.props.myOrders.map((o, i) => o.remain !== 0 &&
                                this.props.myOrders.map((o, i) => o && o.remain !== 0 &&
                                    <tr key={i} className={(!o.delete ? '' : "opacity-animation ") + "order"}>
                                        <td>{this.props.formatPrice(o.fee)}</td>
                                        <td style={o.type === 'BUY' ? { color: '#4aaf23' } : { color: '#ff0050' }}>{o.type === 'BUY' ? 'خرید' : 'فروش'}</td>
                                        <td>{o.remain.toLocaleString('fa-IR', { useGrouping: false })}</td>
                                        <td style={!o.tolerance ? { color: '#bfbfbf' } : {}}>{(!o.tolerance ? 0 : o.tolerance).toLocaleString('fa-IR', { useGrouping: false })} خط</td>
                                        <td>{o.tp ? this.props.formatPrice(o.tp) : '-'} / {o.sl ? this.props.formatPrice(o.sl) : '-'}</td>
                                        <td onClick={() => this.deleteOrder(o, i)} style={{ cursor: 'pointer' }}><img src={Cancel} style={{ width: '15px' }} alt="لغو" /></td>
                                    </tr>
                                )}

                            {this.state.nav === 'open-deals' &&
                                Object.values(this.state.open_deals).reverse().map((d, i) =>
                                    <tr key={i} className={(!d.load ? '' : "opacity-animation ") + "open-deal"}>
                                        <td>{this.props.formatPrice(d.entry_fee)}</td>
                                        <td style={d.type === 'BUY' ? { color: '#4aaf23' } : { color: '#ff0050' }}>{d.type === 'BUY' ? 'خرید' : 'فروش'}</td>

                                        <td>{d.amount.toLocaleString('fa-IR', { useGrouping: false })}</td>
                                        <td style={this.calcProfitLoss(d, this.props.price.value) > 0 ? { color: '#4aaf23' } : this.calcProfitLoss(d, this.props.price.value) < 0 ? { color: '#ff0050' } : {}}>{this.calcProfitLoss(d, this.props.price.value).toLocaleString('fa-IR')}</td>

                                        <td>{this.props.formatPrice(this.calcCallMargin(d, this.props.portfo))}</td>
                                        <td className="tpsl-btn" style={d.status !== 'OPEN' ? { cursor: 'default', color: 'black' } : { color: '#374793', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => d.status === 'OPEN' && this.props.changeState({ modal: 'tpsl', tpsl_deal: d })}>{d.tp ? this.props.formatPrice(d.tp) : '-'} / {d.sl ? this.props.formatPrice(d.sl) : '-'}</td>
                                        {d.status === 'OPEN' ?
                                            <td style={{ alignSelf: 'flex-end', cursor: 'pointer' }} className="close-btn" onClick={() => this.closeDeal(d, i)} ><img src={Cancel} style={{ width: '15px' }} alt="بستن" /></td> :
                                            <td style={{ alignSelf: 'flex-end' }}>{d.amount.toLocaleString('fa-IR', { useGrouping: false })}/{d.remain.toLocaleString('fa-IR', { useGrouping: false })}</td>
                                        }

                                        {d.status === 'OPEN' ?
                                            <td style={{ alignSelf: 'flex-end', cursor: 'pointer' }} className="close-btn" onClick={() => this.lockDeal(d, i)} >
                                                <svg viewBox="0 0 448 512" width="15" fill={d.lock ? '#ff0050' : '#bfbfbf'}>
                                                    <g><path d="M80 192v-48C80 64.47 144.5 0 224 0s144 64.47 144 144v48h16c35.3 0 64 28.7 64 64v192c0 35.3-28.7 64-64 64H64c-35.35 0-64-28.7-64-64V256c0-35.3 28.65-64 64-64h16zm64 0h160v-48c0-44.18-35.8-80-80-80s-80 35.82-80 80v48z"></path></g>
                                                </svg>
                                            </td> :
                                            <td></td>
                                        }
                                    </tr>
                                )}

                            {this.state.nav === 'close-deals' &&
                                Object.values(this.state.close_deals).map((d, i) =>
                                    <tr key={i} className="close-deal">
                                        <td>{this.props.formatPrice(d.entry_fee)}</td>
                                        <td style={d.type === 'BUY' ? { color: '#4aaf23' } : { color: '#ff0050' }}>{d.type === 'BUY' ? 'خرید' : 'فروش'}</td>
                                        <td>{d.amount.toLocaleString('fa-IR', { useGrouping: false })}</td>
                                        <td>{this.props.formatPrice(d.close_fee)}</td>
                                        <td style={d.profit_loss > 0 ? { color: '#4aaf23' } : d.profit_loss < 0 ? { color: '#ff0050' } : {}}>{d.profit_loss.toLocaleString('fa-IR')}</td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                </div>
            </div >
        )
    }
}

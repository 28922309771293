import React, { Component } from 'react'
import axios from 'axios'

export default class TpSlModal extends Component {
    constructor() {
        super()
        this.state = {
            tp: '',
            sl: '',
            isLoading: false
        }

        this.onChange = this.onChange.bind(this)
    }
    onChange(e) {
        const name = e.target.name
        const value = e.target.value

        this.setState({ [name]: value })
    }
    calcLP(value) {
        if (!value) return '-'

        value = parseInt(value.replace(/,/g, ''))
        const entry = Math.floor(this.props.deal.entry_fee / 1000)
        let diff = (this.props.deal.type === 'BUY' ? value - entry : entry - value) * this.props.room.unit_min_price

        return diff.toLocaleString('fa-IR')
    }
    onSubmit() {
        this.setState({ isLoading: true }, () => {
            const tp = parseInt(this.state.tp.replace(/,/g, ''))
            const sl = parseInt(this.state.sl.replace(/,/g, ''))

            axios.put(`/api/trade/room/1/deal/${this.props.deal.order_id}/sltp`, { tp: !tp ? null : tp * 1000, sl: !sl ? null : sl * 1000 })
                .then(res => {
                    // const deal = res.data

                    this.props.changeState({
                        // deals: [deal, ...this.props.deals.filter(d => d.id !== deal.id)],
                        isLoading: false,
                        tpsl_deal: {},
                        modal: ''
                    })
                })
                .catch(e => {
                    const data = e.response.data
                    const message = !data.message ? '' : Array.isArray(data.message) ? data.message[0] : data.message

                    this.setState({
                        isLoading: false
                    })

                    this.props.changeState({
                        error: !message ? 'خطا در ارسال درخواست' : message
                    })
                })
        })
    }
    componentDidMount() {
        this.setState({
            tp: this.props.deal.tp ? this.props.deal.tp.toString().slice(0, -3).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '',
            sl: this.props.deal.sl ? this.props.deal.sl.toString().slice(0, -3).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
        })
    }
    render() {
        return (
            <div className="modal tpsl-modal">
                <div className="container">
                    {this.state.isLoading ? <div className="loader"></div> :
                        <>
                            <div className="header">
                                <p className="close-btn" onClick={() => this.props.changeState({ tpsl_deal: {}, modal: '' })}>X</p>
                                <p className="text">تعیین حد سود و ضرر</p>
                            </div>

                            <div className="content">
                                <div className="input-container">
                                    <div className="tp-container">
                                        <label>حد سود: <input type="text" name="tp" value={this.state.tp} onChange={this.onChange} onInput={e => e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')} /></label>
                                        <p>سود شما در این مظنه: {this.calcLP(this.state.tp)} تومان</p>
                                    </div>

                                    <div className="sl-container">
                                        <label>حد ضرر: <input type="text" name="sl" value={this.state.sl} onChange={this.onChange} onInput={e => e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')} /></label>
                                        <p>ضرر شما در این مظنه: {this.calcLP(this.state.sl)} تومان</p>
                                    </div>
                                </div>

                                <p className="submit-btn" onClick={() => this.onSubmit()}>ثبت حد سود و ضرر</p>
                            </div>
                        </>}
                </div>
            </div>
        )
    }
}

import React, { Component } from 'react'

export default class Average extends Component {
    constructor() {
        super()
        this.state = {
            count: 0,
            entry: 0,
            type: ''
        }
    }
    async syncDeal() {
        let count = 0
        let entry = 0
        let type = ''

        await Promise.all(this.props.deals.map(d => {
            if (d.status === 'OPEN' && !d.lock) {
                type = d.type
                count += d.remain
                entry += (d.entry_fee * d.remain)
            }

            return d
        }))

        entry = Math.floor(entry / count)

        this.setState({ count, entry, type })
    }
    componentDidMount() {
        this.syncDeal()
    }
    componentDidUpdate(prevProps) {
        if (prevProps.deals !== this.props.deals)
            this.syncDeal()
    }
    render() {
        if (!this.state.count)
            return <></>
        else
            return (
                <div className="average-container" style={{ backgroundColor: this.state.type === 'SELL' ? '#ff004e' : '#4aaf23' }}>
                    <p>
                        <span>{this.state.count.toLocaleString('fa-IR', { useGrouping: false })}</span>
                        <span> واحد معامله {this.state.type === 'SELL' ? 'فروش' : 'خرید'}</span>
                    </p>

                    <div className="line"></div>

                    <p>
                        <span>میانگین قیمت ورود: </span>
                        <span>{this.props.formatPrice(this.state.entry)}</span>
                    </p>
                </div>
            )
    }
}

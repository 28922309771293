import React, { Component } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

let cacheTicketState = null

class Ticket extends Component {
    constructor(props) {
        super()
        this.state = {
            ticketError: '',
            page: 0,
            next: false,
            last: false,
            tickets: [],
            ...cacheTicketState
        }

        this.onSubmit = this.onSubmit.bind(this)

        props.setLoading({ isLoading: !cacheTicketState, isFailed: false, errorFunc: null })
    }
    async checkInputData(e, data) {
        let error = ""
        const inputs = [
            ...Array.from(e.target.querySelectorAll('input')),
            ...Array.from(e.target.querySelectorAll('select'))
        ]

        await Promise.all(inputs.map(input => {
            switch (input.name) {
                case 'subject':
                    if (!input.value)
                        error = 'موضوع نامعتبر می باشد'
                    data['subject'] = input.value
                    break

                case 'type':
                    if (!input.value)
                        error = 'نوع پیام نامعتبر می باشد'
                    data['type'] = input.value
                    break

                case 'priority':
                    if (!input.value)
                        error = 'اولویت نامعتبر می باشد'
                    data['priority'] = input.value
                    break

                default:
                    break
            }

            return input
        }))


        this.setState({ ticketError: error })

        if (error)
            return true
    }
    async onSubmit(e) {
        e.preventDefault()

        let url = '/api/ticket'
        const data = {}
        if (await this.checkInputData(e, data))
            return

        await this.props.setLoading({ isLoading: true, error: '' })

        await axios.post(url, data)
            .then(res => {
                this.props.history('/dashboard/ticket/' + res.data.id)
            })
            .catch(err => {
                const data = err.response.data
                const message = !data.message ? '' : Array.isArray(data.message) ? data.message[0] : data.message

                cacheTicketState = {
                    ...this.state,
                    ticketError: !message ? 'خطا در ارسال درخواست' : message,
                }
            })

        await this.props.setLoading({ isLoading: false })

        this.setState(cacheTicketState)
    }
    getStatusText(status, type) {
        if (type === 's')
            switch (status) {
                case 'ACTIVE':
                    return 'باز'
                case 'DEACTIVE':
                    return 'بسته'
                default:
                    break
            }

        if (type === 'p')
            switch (status) {
                case 'LOW':
                    return 'پایین'
                case 'MEDIUM':
                    return 'متوسط'
                case 'HIGH':
                    return 'بالا'
                default:
                    break
            }

        if (type === 't')
            switch (status) {
                case 'TRADE':
                    return 'معاملات'
                case 'SUBSCRIPTION':
                    return 'اشتراک'
                case 'SETTLE':
                    return 'تسویه'
                case 'SHOP':
                    return 'فروشگاه'
                case 'GOLD_WALLET':
                    return 'کیف طلایی'
                case 'TECHNICAL':
                    return 'فنی'
                case 'GENERAL':
                    return 'عمومی'
                default:
                    break
            }
    }
    async getTickets() {
        await this.props.setLoading({ isLoading: true, isFailed: false, errorFunc: null })

        axios.get(`/api/ticket?page=${this.state.page}`)
            .then(r => {
                const data = r.data

                cacheTicketState = {
                    ...cacheTicketState,
                    ...this.state,
                    next: data.next,
                    last: data.last,
                    tickets: data.data
                }

                this.props.setLoading({ isLoading: false, isFailed: false, errorFunc: null })
            })
            .catch(e => {
                this.props.setLoading({ isLoading: true, isFailed: true, errorFunc: () => this.getTickets() })
            })
    }
    componentDidMount() {
        !cacheTicketState && this.getTickets()
    }
    render() {
        return (
            <div className="wallet-container ticket-container">
                <div className="form-container">
                    <form className="form" id="new-form" onSubmit={this.onSubmit}>
                        {this.state.ticketError && <p className="error">{this.state.ticketError}</p>}

                        <input type="text" name="subject" placeholder="موضوع" maxLength="40" />

                        <select name="type" defaultValue="">
                            <option value="" disabled hidden>نوع پیام</option>
                            <option value="TRADE">معاملات</option>
                            <option value="SUBSCRIPTION">اشتراک</option>
                            <option value="SETTLE">تسویه</option>
                            <option value="SHOP">فروشگاه</option>
                            <option value="GOLD_WALLET">کیف طلایی</option>
                            <option value="TECHNICAL">فنی</option>
                            <option value="GENERAL">عمومی</option>
                        </select>

                        <select name="priority" defaultValue="">
                            <option value="" disabled hidden>اولویت</option>
                            <option value="LOW">پایین</option>
                            <option value="MEDIUM">متوسط</option>
                            <option value="HIGH">بالا</option>
                        </select>

                        <button className="submit">ارسال پیام به پشتیبانی</button>
                    </form>
                </div>

                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>موضوع</th>
                                <th>وضعیت</th>
                                <th>تاریخ</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.state.tickets.map((r, i) =>
                                <tr key={i}>
                                    <td>{r.subject}</td>
                                    <td style={r.status === 'ACTIVE' ? { color: '#4aaf23' } : { color: '#ff0050' }}>{this.getStatusText(r.status, 's')}</td>
                                    <td>{new Date(r.create_date).toLocaleString('fa-IR', { year: "numeric", month: "numeric", day: 'numeric' })}</td>
                                    <td>{!r.wait ? 'منتظر پاسخ کاربر' : 'منتظر پاسخ کارشناس'}</td>
                                    <td onClick={() => this.props.history('/dashboard/ticket/' + r.id)} className="deals-btn">مشاهده</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className="pagination">
                    {this.state.next && <p onClick={() => this.setState({ page: this.state.page + 1 }, () => this.getTickets())}>&laquo;</p>}
                    {this.state.last && <p onClick={() => this.setState({ page: this.state.page - 1 }, () => this.getTickets())}>&raquo;</p>}
                </div>
            </div>
        )
    }
}

export default function HOC(props) {
    const history = useNavigate()
    return <Ticket {...props} history={history} />
}
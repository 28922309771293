import React, { Component } from 'react'
import axios from "axios"
import {
    Navigate,
    Route,
    Routes
} from "react-router-dom"
import AuthContext from '../AuthContext'
import Header from './dashboard/header'
import Menu from './dashboard/menu'
import Home from './dashboard/pages/home'
import PersonalInfo from './dashboard/pages/personal_info'
import Wallet from './dashboard/pages/wallet'
import Portfos from './dashboard/pages/portfos'
import Transactions from './dashboard/pages/transactions'
import BankAccounts from './dashboard/pages/bank_accounts'
import Notification from './dashboard/pages/notification'
import Settings from './dashboard/pages/settings'
import GoldWallet from './dashboard/pages/gold_wallet'
import Ticket from './dashboard/pages/ticket'
import TicketMessage from './dashboard/pages/ticket-message'

class Dashboard extends Component {
    constructor() {
        super()
        this.state = {
            isLoading: true,
            isFailed: false,
            isLogout: false,
            errorFunc: null,
            profileImage: null,
            notification: null,
            ticket_data: null,
            menuItems: [
                { name: 'داشبورد', path: '/dashboard' },
                { name: 'خرید و فروش آبشده', path: '/room' },
                { name: 'اطلاعات شخصی', path: '/dashboard/personal_info' },
                { name: 'تنظیمات سیستم', path: '/dashboard/settings' },
                { name: 'کیف پول', path: '/dashboard/wallet' },
                { name: 'پیام به پشتیبانی', path: '/dashboard/ticket' },
                { name: 'پرتفو ها', path: '/dashboard/portfos' },
                { name: 'تراکنش ها', path: '/dashboard/transactions' },
                { name: 'کیف طلایی', path: '/dashboard/gold_wallet' },
                { name: 'اطلاعات حساب', path: '/dashboard/bank_accounts' },
                { name: 'اطلاعیه ها', path: '/dashboard/notification' },
                { name: 'خروج', path: '' }
            ]
        }
    }
    checkToken() {
        !this.context.me.username && this.setState({ isFailed: false }, async () => {
            const token = this.context.token
            if (!token) {
                window.location = '/auth/login'
                return
            }

            const req = await fetch('/api/user/me', {
                method: 'GET',
                headers: { 'Authorization': token }
            })

            if (req.status === 401 || req.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('uid')
                this.context.changeToken(undefined)
                window.location = '/auth/login'
            }
            else if (req.status === 200) {

                const chat_script = document.createElement('script');
                chat_script.setAttribute('src','//code.tidio.co/enve8cze19s4ryrai40mamd1cauwmjss.js');
                document.body.appendChild(chat_script)

                axios.defaults.headers.common['Authorization'] = token

                const me = await req.json()
                
                if(me.username){
                    localStorage.setItem('tv_username', me.username)
                }else{
                    localStorage.setItem('tv_username', undefined)
                }
                this.context.changeMe(me)

                localStorage.setItem('uid', me.id)
                axios.defaults.headers.common['uid'] = me.id

                // if((!me?.email || (me?.email && !me?.emailConfirmed)) && !window.location.href.includes('dashboard/personal_info')){
                //     window.location = "/dashboard/personal_info";
                // }

                this.setState({ isLoading: false })
                this.getProfileImg()

                localStorage.setItem('theme', me.UserMeta.theme)
                localStorage.setItem('direct_order', me.UserMeta.direct_order)
                localStorage.setItem('show_order_dialog', me.UserMeta.show_order_dialog)

                const room = document.documentElement
                room.style.setProperty(
                    '--bg-color',
                    localStorage.getItem('theme') === 'LIGHT' ? 'white' : '#2b2a34'
                )
                room.style.setProperty(
                    '--fg-color',
                    localStorage.getItem('theme') !== 'LIGHT' ? 'white' : 'black'
                )
            }
            else {
                this.setState({ isFailed: true })
            }
        })

        this.setLoading = this.setLoading.bind(this)
    }
    setLoading(data) {
        return new Promise((resolve, reject) => {
            this.setState(data, () => resolve())
        })
    }
    logout() {
        localStorage.removeItem('token')
        localStorage.removeItem('uid')
        window.location = '/auth/login'
    }
    async getProfileImg() {
        await axios.get('/api/user/resource?type=profile', { responseType: "blob" })
            .then(response => {
                const reader = new window.FileReader()
                reader.readAsDataURL(response.data)
                reader.onload = () => this.setState({ profileImage: reader.result })
            })
            .catch(e => e)
    }
    componentDidMount() {
        const room = document.documentElement
        room.style.setProperty(
            '--bg-color',
            localStorage.getItem('theme') === 'LIGHT' ? 'white' : '#2b2a34'
        )
        room.style.setProperty(
            '--fg-color',
            localStorage.getItem('theme') !== 'LIGHT' ? 'white' : 'black'
        )

        this.checkToken()
    }
    componentWillUnmount() {
        this.context.changeMe({})
    }
    render() {
        if (this.state.isLoading)
            return <div className="dashboard-loading-container">
                {!this.state.isFailed ? <div className="loader"></div> :
                    <div className="error-container">
                        <p>خطا در دریافت اطلاعات</p>
                        <p className="retry-btn" onClick={() => !this.state.errorFunc ? this.checkToken() : this.state.errorFunc()}>تلاش مجدد</p>
                    </div>
                }
            </div>
        else
            return (
                <div className="dashboard-container">
                    {this.state.ticket_data && <div className="modal">
                        <div className="container" style={{height: '80%'}}>
                            <div className="text-container" style={{overflow: 'hidden'}}>
                                {this.state.ticket_data.t === 'images' ? <img src={this.state.ticket_data.url} alt="tic"
                                                                              style={{
                                                                                  width: '100%',
                                                                                  height: '100%'
                                                                              }}/> :
                                    <video controls={true} style={{width: '100%', height: '100%'}}
                                           src={this.state.ticket_data.url}></video>}
                            </div>

                            <p className="close-btn" onClick={() => this.setState({ticket_data: null})}>بستن</p>
                        </div>
                    </div>}

                    {this.state.notification && <div className="modal">
                        <div className="container">
                            <div className="text-container">
                                <p>{this.state.notification.body}</p>
                            </div>

                            <p className="close-btn" onClick={() => this.setState({notification: null})}>بستن</p>
                        </div>
                    </div>}

                    {this.state.isLogout && <div className="modal">
                        <div className="container logout">
                            <div className="text-container">
                                <p>آیا از خروج اطمینان دارید؟</p>
                            </div>

                            <div>
                                <p className="close-btn" onClick={() => this.logout()}>خروج</p>
                                <p className="close-btn" style={{backgroundColor: 'rgba(0,0,0,0.4)'}}
                                   onClick={() => this.setState({isLogout: false})}>لغو</p>
                            </div>
                        </div>
                    </div>}

                    <Header ProfileImage={this.state.profileImage}/>

                    <div className="content-container">
                        <Menu items={this.state.menuItems} setLogout={() => this.setState({isLogout: true})}/>

                        <Routes>
                            <Route path={'/'} element={<Home setLoading={this.setLoading}/>} exact/>
                            <Route path={'/personal_info'} element={<PersonalInfo setLoading={this.setLoading}/>}/>
                            <Route path={'/wallet'} element={<Wallet setLoading={this.setLoading}/>}/>
                            <Route path={'/portfos'} element={<Portfos setLoading={this.setLoading}/>}/>
                            <Route path={'/transactions'} element={<Transactions setLoading={this.setLoading}/>}/>
                            <Route path={'/bank_accounts'} element={<BankAccounts setLoading={this.setLoading}/>}/>
                            <Route path={'/notification'} element={<Notification setLoading={this.setLoading}/>}/>
                            <Route path={'/settings'} element={<Settings setLoading={this.setLoading}/>}/>
                            <Route path={'/gold_wallet'} element={<GoldWallet setLoading={this.setLoading}/>}/>
                            <Route path={'/ticket'} element={<Ticket setLoading={this.setLoading}/>}/>
                            <Route path={'/ticket/:id'} element={<TicketMessage setLoading={this.setLoading}
                                                                                setData={d => this.setState({ticket_data: d})}/>}/>
                            <Route path={'*'} element={<Navigate to="/dashboard"/>}/>
                        </Routes>
                    </div>
                </div>
            )
    }
}

Dashboard.contextType = AuthContext

export default Dashboard
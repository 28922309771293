import React, { Component } from 'react'
import Menu from '../dashboard/menu'
import Loader from './loader'
import axios from 'axios'
import { useLocation, useParams, useNavigate } from 'react-router-dom'

function getUrlVar(key) {
    const result = new RegExp(key + "=([^&]*)", "i").exec(window.location.search)
    return result && (result[1] || "")
}

class Shop extends Component {
    constructor(props) {
        super()
        this.state = {
            isLoading: false,
            isFailed: false,
            isDisabled: false,
            showSubmit: false,
            captchaFailed: false,
            isCode: false,
            next: false,
            last: false,
            track: !!props.track,
            page: 0,
            goldPrice: 0,
            menuItems: [],
            products: [],
            product: null,
            receipt: null,
            captchaImage: null,
            gateUrl: '',
            receiptError: '',
            error: ''
        }

        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onReceiptSubmit = this.onReceiptSubmit.bind(this)
    }
    onChange(e) {
        const name = e.target.name
        const value = e.target.value

        this.setState({ [name]: value })
    }
    getCategories() {
        return new Promise((resolve, reject) => {
            this.setState({ isLoading: true, isFailed: false, receipt: null }, async () => {
                await axios.get('/api/shop/categories')
                    .then(res => {
                        this._isMounted && this.setState({
                            isLoading: !this.props.track,
                            menuItems: [
                                { name: 'رهگیری سفارش', path: '/shop/track' },
                                ...Object.values(res.data).map(c => ({ name: c.name, path: '/shop/category/' + c.name }))
                            ]
                        }, () => {
                            !this.props.track && this.state.menuItems[1] && this.props.navigate(this.state.menuItems[1].path)
                            resolve()
                        })
                    })
                    .catch(() => this._isMounted && this.setState({ isFailed: true }, () => resolve()))
            })
        })
    }
    getProducts() {
        this.setState({ showSubmit: false, isLoading: true, isFailed: false, isCode: false, product: null }, async () => {
            await axios.get(`/api/general-settings?key=shop_status`)
                .then(async _res => {
                    await axios.get(`/api/shop?category_name=${this.props.slug}&page=${this.state.page}`)
                        .then(res => {
                            const data = res.data

                            this._isMounted && this.setState({
                                isLoading: false,
                                isDisabled: !_res.data.shop_status,
                                products: Object.values(data.data),
                                next: data.next,
                                last: data.last
                            })
                        })
                        .catch(() => this._isMounted && this.setState({ isFailed: true }))
                })
                .catch(() => this._isMounted && this.setState({ isFailed: true }))
        })
    }
    getProduct(id) {
        this.setState({ isLoading: true, isFailed: false, products: [] }, async () => {
            await axios.get('/api/shop/' + id)
                .then(res => {
                    this._isMounted && this.setState({
                        isLoading: false,
                        product: res.data
                    })
                    this.getCaptcha()
                })
                .catch(() => this._isMounted && this.setState({ isFailed: true }))
        })
    }
    async startPriceListener() {
        return new Promise((resolve, reject) => {
            const evtSource = new EventSource("/api/price/geram18/sse")

            evtSource.onopen = async event => {
                const currentPrice = (await axios.get('/api/price/geram18')).data
                if (currentPrice)
                    this._isMounted && this.setState({ goldPrice: !currentPrice.value ? 0 : currentPrice.value }, () => resolve())
                else
                    resolve()
            }

            evtSource.onmessage = event => {
                this._isMounted && this.setState({ goldPrice: JSON.parse(event.data).value })
            }

            evtSource.onerror = () => {
                setTimeout(() => this.startPriceListener(), 3000)
                resolve()
            }
        })
    }
    getFee(p, price) {
        const feePerGram = (price * p.percentage_fee) + p.fixed_fee

        const seller_fee = (price + feePerGram) * p.seller_fee

        return feePerGram + seller_fee
    }
    getTax(p, price) {
        const feePerGram = (price * p.percentage_fee) + p.fixed_fee

        const seller_fee = (price + feePerGram) * p.seller_fee

        const tax = Math.floor((feePerGram + seller_fee) * p.tax)

        return tax
    }
    getFinalPrice(p, price) {
        const feePerGram = (price * p.percentage_fee) + p.fixed_fee

        const seller_fee = (price + feePerGram) * p.seller_fee

        const tax = Math.floor((feePerGram + seller_fee) * p.tax)

        const _price = ((price + feePerGram + seller_fee) * p.weight) + tax

        const final_price = Math.floor(_price / 1000) * 1000

        return final_price
    }
    getCaptcha() {
        this.setState({ captchaFailed: false }, async () => {
            const req = await fetch('/api/auth/generate_captcha', { method: 'GET' })

            if (req.status !== 200) {
                this._isMounted && this.setState({ captchaFailed: true, error: 'خطا در دریافت کپتچا' })
                return
            }

            const c = await req.json()
            this._isMounted && this.setState({ captchaImage: c.data })
        })
    }
    onSubmit(e) {
        e.preventDefault()
        const i = n => {
            const value = this.state[n]
            return !value ? undefined : value
        }

        const data = {
            name: i('name'),
            mobile: i('mobile'),
            code: i('code'),
            captcha: i('captcha'),
            postal_code: i('postal_code'),
            address: i('address'),
            user_note: i('user_note')
        }

        if (data.postal_code.length !== 10) {
            this.setState({ error: 'کد پستی نامعتبر می باشد' })
            return
        }

        if (this.state.isCode && data.code.length !== 6) {
            this.setState({ error: 'کد اعتبار سنجی نامعتبر می باشد' })
            return
        }

        const url = `/api/shop/${this.state.product.id}/buy`

        this.setState({ isLoading: true, error: '' }, async () => {
            await axios.post(url,
                {
                    name: data.name,
                    mobile: data.mobile,
                    code: this.state.isCode ? data.code : undefined,
                    postal_code: data.postal_code,
                    address: data.address,
                    user_note: data.user_note,
                    captcha: this.state.isCode ? undefined : data.captcha
                })
                .then(res => {
                    if (!this.state.isCode)
                        this.setState({ isLoading: false, isCode: true })
                    else
                        this.setState({
                            isLoading: false,
                            isCode: false,
                            track: true,
                            gateUrl: res.data.url,
                            receipt: {
                                ...data,
                                tax: this.getTax(this.state.product, this.state.goldPrice),
                                final_price: this.getFinalPrice(this.state.product, this.state.goldPrice),
                                fee: this.state.goldPrice,
                                product: this.state.product
                            }
                        })
                })
                .catch(e => {
                    const data = e.response.data
                    const message = !data.message ? '' : Array.isArray(data.message) ? data.message[0] : data.message

                    this.setState({ isLoading: false, isCode: data.code === 104 ? true : this.state.isCode, error: message })
                })
            this.getCaptcha()
        })
    }
    onReceiptSubmit(e, is_form = true) {
        is_form && e.preventDefault()

        const form = e.target
        const hash = !is_form ? e.hash : form.querySelector('input[name=hash]').value
        const mobile = !is_form ? e.mobile : form.querySelector('input[name=mobile]').value

        if (!mobile) {
            this.setState({ receiptError: 'شماره موبایل نامعتبر می باشد' })
            return
        }

        if (!hash || hash.length !== 10) {
            this.setState({ receiptError: 'کد رهگیری نامعتبر می باشد' })
            return
        }

        this.setState({ isLoading: true, isFailed: false }, async () => {
            await axios.post('/api/shop/receipt', { mobile, hash })
                .then(res => {
                    this.setState({ isLoading: false, receipt: res.data, track: true })
                })
                .catch(e => {
                    const data = e.response.data
                    const message = !data.message ? '' : Array.isArray(data.message) ? data.message[0] : data.message

                    this.setState({ isLoading: false, receiptError: message })
                })
        })
    }
    receiptStatusText(status) {
        switch (status) {
            case 'EXPIRED':
                return 'لغو شده'

            case 'CANCELLED':
                return 'لغو شده'

            case 'WAIT_PURCHASE':
                return 'در انتظار پرداخت'

            case 'PURCHASED':
                return 'پرداخت شده'

            case 'SENT':
                return 'ارسال شده'

            default:
                break
        }
    }
    async componentDidMount() {
        this._isMounted = true

        if (!this.props.result) {
            await this.startPriceListener()
            await this.getCategories()

            if (!this.props.track)
                this.getProducts()
        }
        else {
            this.onReceiptSubmit({ mobile: getUrlVar('mobile'), hash: getUrlVar('hash') }, false)
        }

        const root = document.documentElement
        root.style.setProperty('--bg-color', 'white')
        root.style.setProperty('--fg-color', 'black')
    }
    componentDidUpdate(prevProps) {
        if (this.props.slug !== prevProps.slug)
            !this.props.slug ? this.getCategories() : this.getProducts()

        if (this.props.track !== prevProps.track)
            this.setState({ track: !!this.props.track })
    }
    componentWillUnmount() {
        this._isMounted = false
    }
    render() {
        if (this.state.isLoading)
            return <Loader isFailed={this.state.isFailed} failedCallback={() => this.getCategories()} />
        else if (this.props.result && getUrlVar('status') === '0')
            return <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <h2 style={{ fontSize: '20px' }}>عملیات ناموفق</h2>
                <h2 style={{ fontSize: '16px', marginTop: '30px', marginBottom: '200px' }}>شماره تراکنش: {getUrlVar('ref_id')}</h2>
            </div>
        else
            return (
                <div className="general-container shop-container">
                    <Menu items={this.state.menuItems} isCategory={true} />

                    {!this.state.track ? (this.state.product ?
                        <div className="product-container">
                            <div className="data">
                                <img src={`/assets/images/shop/product/${this.state.product.id}.png`} alt="thumbnail" />

                                <div className="detail">
                                    <p>
                                        <span>عنوان محصول: </span>
                                        <span>{this.state.product.name}</span>
                                    </p>

                                    <p>
                                        <span>وزن: </span>
                                        <span>{this.state.product.weight} گرم</span>
                                    </p>

                                    <p>
                                        <span>ارزش: </span>
                                        <span>{(this.state.goldPrice * this.state.product.weight).toLocaleString('fa-IR')} تومان</span>
                                    </p>

                                    <p>
                                        <span>قیمت هر گرم طلای ۱۸ عیار: </span>
                                        <span>{this.state.goldPrice.toLocaleString('fa-IR')} تومان</span>
                                    </p>

                                    <p>
                                        <span>اجرت هر گرم: </span>
                                        <span>{this.getFee(this.state.product, this.state.goldPrice).toLocaleString('fa-IR')} تومان</span>
                                    </p>
                                </div>

                                <div className="price">
                                    <p>مالیات ارزش افزوده: </p>
                                    <p>{this.getTax(this.state.product, this.state.goldPrice).toLocaleString('fa-IR')} تومان</p>

                                    <p>قیمت تمام شده: </p>
                                    <p>{this.getFinalPrice(this.state.product, this.state.goldPrice).toLocaleString('fa-IR')} تومان</p>

                                    {!this.state.showSubmit && <button style={this.state.isDisabled ? { background: 'grey', cursor: 'not-allowed' } : {}} onClick={() => !this.state.isDisabled && this.setState({ showSubmit: true })} className="show-submit">{this.state.isDisabled ? 'فروشگاه غیر فعال می باشد' : 'ثبت سفارش'}</button>}
                                </div>
                            </div>

                            <p className="description">
                                <span>توضیحات: </span>
                                <span>{this.state.product.description}</span>
                            </p>

                            {this.state.showSubmit && <div className="complain-container purchase-container">
                                <form className="form" onSubmit={this.onSubmit}>
                                    {this.state.error && <p className="error">{this.state.error}</p>}

                                    {!this.state.isCode && <>
                                        <input onChange={this.onChange} value={this.state.name} type="text" name="name" placeholder="نام و نام خانوادگی" maxLength="128" required={true} />

                                        <input onChange={this.onChange} value={this.state.mobile} type="text" name="mobile" disabled={this.state.isCode} style={this.state.isCode ? { backgroundColor: '#0002' } : {}} placeholder="شماره موبایل" maxLength="11" onInput={e => e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')} required={true} />

                                        <input onChange={this.onChange} value={this.state.postal_code} type="text" name="postal_code" placeholder="کد پستی" maxLength="10" onInput={e => e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')} required={true} />

                                        <input onChange={this.onChange} value={this.state.address} type="text" name="address" placeholder="آدرس" maxLength="512" required={true} />

                                        <textarea onChange={this.onChange} value={this.state.user_note} name="user_note" placeholder="توضیحات" maxLength="2048"></textarea>
                                    </>}

                                    {!this.state.isCode ?
                                        <>
                                            <div onClick={() => this.getCaptcha()} className="captcha"
                                                dangerouslySetInnerHTML={{ __html: this.state.captchaImage }}>
                                            </div>
                                            <div onClick={() => this.getCaptcha()} className="captcha" style={{ marginTop: 10 }}
                                                dangerouslySetInnerHTML={{
                                                    __html: `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="25" height="25" enable-background="new 0 0 65 65" viewBox="0 0 65 65">
                                                <g fill="#555753">
                                                    <path d="m32.5 4.999c-5.405 0-10.444 1.577-14.699 4.282l-5.75-5.75v16.11h16.11l-6.395-6.395c3.18-1.787 6.834-2.82 10.734-2.82 12.171 0 22.073 9.902 22.073 22.074 0 2.899-0.577 5.664-1.599 8.202l4.738 2.762c1.47-3.363 2.288-7.068 2.288-10.964 0-15.164-12.337-27.501-27.5-27.501z"/>
                                                    <path d="m43.227 51.746c-3.179 1.786-6.826 2.827-10.726 2.827-12.171 0-22.073-9.902-22.073-22.073 0-2.739 0.524-5.35 1.439-7.771l-4.731-2.851c-1.375 3.271-2.136 6.858-2.136 10.622 0 15.164 12.336 27.5 27.5 27.5 5.406 0 10.434-1.584 14.691-4.289l5.758 5.759v-16.112h-16.111l6.389 6.388z"/>
                                                </g>
                                            </svg>` }}>
                                            </div>

                                            <input onChange={this.onChange} value={this.state.captcha} type="text" name="captcha" placeholder="کد کپتچا" maxLength="4" required={true} />
                                        </>
                                        :
                                        <>
                                            <input onChange={this.onChange} value={this.state.code} type="text" name="code" placeholder="کد تایید" maxLength="6" required={true} />
                                        </>
                                    }
                                    <button className="submit">{!this.state.isCode ? 'ارسال کد تایید' : 'خرید'}</button>
                                </form>
                            </div>}
                        </div>
                        :
                        <div className="products-container">
                            <div className="wrapper">
                                {this.state.products.map((p, i) =>
                                    <div key={i} className="product">
                                        <img src={`/assets/images/shop/product/${p.id}.png`} alt="thumbnail" />

                                        <p>
                                            <span>عنوان محصول: </span>
                                            <span>{p.name}</span>
                                        </p>

                                        <p>
                                            <span>وزن: </span>
                                            <span>{p.weight} گرم</span>
                                        </p>

                                        <p>
                                            <span>ارزش: </span>
                                            <span>{(this.state.goldPrice * p.weight).toLocaleString('fa-IR')} تومان</span>
                                        </p>

                                        <p>
                                            <span>قیمت هر گرم طلای ۱۸ عیار: </span>
                                            <span>{this.state.goldPrice.toLocaleString('fa-IR')} تومان</span>
                                        </p>

                                        <p>
                                            <span>اجرت هر گرم: </span>
                                            <span>{this.getFee(p, this.state.goldPrice).toLocaleString('fa-IR')} تومان</span>
                                        </p>

                                        <p>
                                            <span>مالیات ارزش افزوده: </span>
                                            <span>{this.getTax(p, this.state.goldPrice).toLocaleString('fa-IR')} تومان</span>
                                        </p>

                                        <p className="more-btn" onClick={() => this.getProduct(p.id)}>مشاهده</p>
                                    </div>
                                )}
                            </div>

                            <div className="pagination">
                                {this.state.last && <p onClick={() => this.setState({ page: this.state.page - 1 }, () => this.getProducts())}>&raquo;</p>}
                                {this.state.next && <p onClick={() => this.setState({ page: this.state.page + 1 }, () => this.getProducts())}>&laquo;</p>}
                            </div>
                        </div>
                    ) :
                        <div className="complain-container receipt-container" style={this.state.receipt ? { width: 'calc(100% - 280px)' } : {}}>
                            <form className="form" onSubmit={this.onReceiptSubmit} style={this.state.receipt ? { border: 'none' } : {}}>
                                {!this.state.receipt ?
                                    <>
                                        {this.state.receiptError && <p className="error">{this.state.receiptError}</p>}

                                        <input type="text" name="hash" placeholder="کد رهگیری" />

                                        <input type="text" name="mobile" placeholder="شماره موبایل" maxLength="11" onInput={e => e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')} />

                                        <button className="submit">بررسی</button>
                                    </>
                                    :
                                    <>
                                        <div className="receipt-data">
                                            <img src={`/assets/images/shop/product/${this.state.receipt.product.id}.png`} alt="thumbnail" />

                                            <p>
                                                <span>نام محصول: </span>
                                                <span>{this.state.receipt.product.name}</span>
                                            </p>

                                            <p>
                                                <span>نام و نام خانوادگی: </span>
                                                <span>{this.state.receipt.name}</span>
                                            </p>

                                            <p>
                                                <span>مظنه: </span>
                                                <span>{this.state.receipt.fee.toLocaleString('fa-IR')}</span>
                                            </p>

                                            <p>
                                                <span>اجرت هر گرم: </span>
                                                <span>{this.getFee(this.state.receipt.product, this.state.receipt.fee).toLocaleString('fa-IR')} تومان</span>
                                            </p>

                                            <p>
                                                <span>مالیات بر ارزش افزوده: </span>
                                                <span>{this.state.receipt.tax.toLocaleString('fa-IR')}</span>
                                            </p>

                                            <p>
                                                <span>قیمت نهایی: </span>
                                                <span>{this.state.receipt.final_price.toLocaleString('fa-IR')}</span>
                                            </p>

                                            <p>
                                                <span>کد پستی: </span>
                                                <span>{Number(this.state.receipt.postal_code).toLocaleString('fa-IR', { useGrouping: false })}</span>
                                            </p>

                                            <p>
                                                <span>آدرس: </span>
                                                <span>{this.state.receipt.address}</span>
                                            </p>

                                            <p>
                                                <span>توضیحات: </span>
                                                <span>{this.state.receipt.user_note}</span>
                                            </p>

                                            {this.state.receipt.status === 'SENT' && <p>
                                                <span>کد رهگیری: </span>
                                                <span>{this.state.receipt.tracking_number}</span>
                                            </p>}

                                            {!this.state.gateUrl && <>
                                                <p>
                                                    <span>وضعیت: </span>
                                                    <span>{this.receiptStatusText(this.state.receipt.status)}</span>
                                                </p>

                                                <p>
                                                    <span>تاریخ: </span>
                                                    <span>{new Date(this.state.receipt.create_date).toLocaleDateString('fa-IR')}</span>
                                                </p>
                                            </>}
                                        </div>

                                        {this.state.gateUrl ?
                                            <button onClick={() => window.location = this.state.gateUrl} className="gate-submit">انتقال به درگاه</button> :
                                            <button onClick={() => window.print()} className="gate-submit">دانلود PDF</button>
                                        }
                                    </>
                                }
                            </form>
                        </div>
                    }
                </div>
            )
    }
}

export default function HOC(props) {
    let { slug } = useParams()
    return <Shop {...props} slug={slug} location={useLocation()} navigate={useNavigate()} />
}
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import axios from 'axios'
import AuthContext from '../AuthContext'
import background from '../images/auth-background.jpg'
import Recaptcha from "../Recaptcha";

class Auth extends Component {
    constructor(props, context) {
        super()
        this.state = {
            type: 'login',
            submitText: 'ورود',
            captcha: '',
            error: '',
            resendDuration: 0,
            captchaFailed: false,
            isLoading: false,
            isResetPassword: false,
            isCode: false,

            // Inputs
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            password: '',
            re_password: '',
            acquaintance: '',
            referral: '',
            otpCode: ['', '', '', '', '', ''],
            // captchaCode: '',
            recaptchaValue: null
        }

        this.onSubmit = this.onSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)

        if (context.token)
            window.location = '/dashboard'
    }
    getCaptcha() {
        this.setState({ captchaFailed: false }, async () => {
            const req = await fetch('/api/auth/generate_captcha', { method: 'GET' })

            if (req.status !== 200) {
                this.setState({ captchaFailed: true, error: 'خطا در دریافت کپتچا' })
                return
            }

            const c = await req.json()
            this.setState({ captcha: c.data })
        })
    }
    secondsToMMSS(time) {
        var sec_num = parseInt(time, 10)
        var minutes = Math.floor(sec_num / 60)
        var seconds = sec_num - (minutes * 60)

        if (seconds < 10) { seconds = "0" + seconds }
        return (minutes !== 0 ? minutes + ':' : '') + seconds
    }
    resetInput() {
        this.setState({
            firstName: '',
            lastName: '',
            mobile: '',
            email: '',
            password: '',
            re_password: '',
            acquaintance: '',
            referral: '',
            otpCode: ['', '', '', '', '', ''],
            // captchaCode: '',
            recaptchaValue: null
        })
    }
    async checkInputData(e) {
        let error = ""
        const inputs = Array.from(e.target.querySelectorAll('input'))

        await Promise.all(inputs.map(input => {
            switch (input.name) {
                case 'mobile':
                    if (!input.value.match(/^(\+98|0098|98|0)?9\d{9}$/g))
                        error = "شماره موبایل نامعتبر می باشد"
                    break

                case 'firstName':
                    if (input.value.trim() === '')
                        error = "نام نامعتبر می باشد"
                    break

                case 'lastName':
                    if (input.value.trim() === '')
                        error = "نام خانوادگی نامعتبر می باشد"
                    break

                case 'password':
                    if (this.state.type === 'signup' || (this.state.type === 'resetpassword' && this.state.isCode))
                        if (input.value.length < 8 || !input.value.match(/((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/))
                            error = "رمز عبور می بایست حداقل ۸ کاراکتر و دارای عدد، حروف کوچک و بزرگ باشد"
                    break

                case 're_password':
                    if (input.value !== this.state.password)
                        error = 'رمز وارد شده با تکرار رمز تطابق ندارد'
                    break

                case 'termsofservice':
                    if (!input.checked)
                        error = "لطفا قوانین را قبول نمایید"
                    break

                case 'referral':
                    if (input.value) {
                        const refferal_code = parseInt(input.value)
                        if (isNaN(refferal_code))
                            error = 'کد معرف نامعتبر می باشد'
                        else
                            this.setState({ referral: refferal_code })
                    }
                    break

                case 'captchaCode':
                    if (input.value.length !== 4)
                        error = "کد کپتچا نامعتبر می باشد"
                    break

                default:
                    break
            }

            return input
        }))

        this.setState({ error })

        if (error)
            return true
    }
    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    async onSubmit(e) {
        console.log("is code => ", this.state.isCode)
        e.preventDefault()
        if (await this.checkInputData(e)) {
            if (this.state.type === 'resetpassword' && !this.state.isCode)
                this.setState({ type: 'login', isResetPassword: false })
            return
        }

        if((this.state.isCode || this.state.type === 'preSignup') && !this.state.recaptchaValue){
            alert("لطفا کپچا را کامل کنید")
            return
        }

        let url = ''
        if (this.state.type === 'login')
            url = '/api/auth/login'
        else if (this.state.type === 'signup')
            url = !this.state.isCode ? '/api/auth/signup/send_code' : '/api/auth/signup/verify_code'
        else if (this.state.type === 'resetpassword')
            url = !this.state.isCode ? '/api/auth/reset_password/send_code' : '/api/auth/reset_password/verify_code'
        else if (this.state.type === 'preSignup')
            url = !this.state.isCode ? '/api/auth/signup/preSignup/send_code' : '/api/auth/signup/preSignup'

        this.setState({ isLoading: true }, () => {
            axios.post(url, {
                username: !this.state.mobile ? undefined : this.state.mobile,
                mobile: !this.state.mobile ? undefined : this.state.mobile,
                password: !this.state.password ? undefined : this.state.password,
                re_password: !this.state.re_password ? undefined : this.state.re_password,
                acquaintance: !this.state.acquaintance ? undefined : this.state.acquaintance,
                referral_code: !this.state.referral ? undefined : this.state.referral,
                code: !this.state.isCode ? undefined : this.state.otpCode.join(''),
                email: !this.state.email ? undefined : this.state.email,
                firstName: !this.state.firstName ? undefined : this.state.firstName,
                lastName: !this.state.lastName ? undefined : this.state.lastName,
                // captcha: this.state.captchaCode
            })
                .then(res => {
                    const data = res.data

                    if (this.state.type === 'preSignup' && this.state.isCode){
                        alert('پیش ثبت نام شما با موفقیت انجام شد. پس از باز شدن سایت به شما اطلاع رسانی خواهد شد.')
                        window.location.reload()
                    }else if(this.state.type === 'preSignup' && !this.state.isCode){
                        this.setState({isCode: true})
                        return
                    }

                    if (data.token) {
                        localStorage.setItem('token', data.token)
                        window.location = '/dashboard'
                    }
                    else
                        this.setState({
                            resendDuration: 120,
                            isCode: true,
                            isResetPassword: this.state.type === 'resetpassword',
                            isLoading: false
                        })
                })
                .catch(e => {
                    const data = e.response.data
                    const message = !data.message ? '' : Array.isArray(data.message) ? data.message[0] : data.message

                    if (data.code === 108)
                        this.setState({
                            resendDuration: 120,
                            isCode: true,
                            isLoading: false
                        })
                    else if (data.code === 104)
                        this.setState({
                            resendDuration: 120,
                            error: message,
                            isCode: true,
                            isResetPassword: this.state.type === 'resetpassword',
                            type: this.state.type,
                            isLoading: false
                        })
                    else
                        this.setState({
                            error: !message ? 'خطا در ارسال درخواست' : message,
                            isLoading: false
                        })
                })

            // this.getCaptcha()
        })
    }
    listenOTP() {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        const inputs = document.querySelectorAll('#otp > input')
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].addEventListener('keydown', event => {
                if (event.key === "Backspace") {
                    inputs[i].value = ''

                    const otp = this.state.otpCode
                    otp[i] = ''
                    this.setState({ otpCode: otp })

                    if (i !== 0)
                        inputs[i - 1].focus()
                }
                else {
                    if (i === inputs.length - 1 && inputs[i].value !== '') {
                        return true
                    }
                    else if (chars.includes(event.key)) {
                        inputs[i].value = event.key
                        if (i !== inputs.length - 1)
                            inputs[i + 1].focus()
                        event.preventDefault()

                        const otp = this.state.otpCode
                        otp[i] = event.key
                        this.setState({ otpCode: otp })
                    }
                }
            })

            inputs[i].addEventListener('input', event => {
                if (chars.includes(event.data)) {
                    inputs[i].value = event.data
                    if (i !== inputs.length - 1)
                        inputs[i + 1].focus()
                    event.preventDefault()

                    const otp = this.state.otpCode
                    otp[i] = event.data
                    this.setState({ otpCode: otp })
                }
            })
        }
    }
    componentDidMount() {
        // this.getCaptcha()

        if (window.location.pathname.endsWith('preSignup'))
            this.setState({ submitText: 'پیش ثبت نام', type: 'preSignup' })

        else if (window.location.pathname.endsWith('signup'))
            this.setState({ submitText: 'ثبت نام', type: 'signup' })

        this.listenOTP()
    }
    componentDidUpdate(prevProps, prevState) {
        if (window.location.pathname.endsWith('preSignup') && this.state.submitText !== 'پیش ثبت نام')
            this.setState({ submitText: 'پیش ثبت نام', type: 'preSignup', error: '', isResetPassword: false, isCode: false })
        else if (window.location.pathname.endsWith('signup') && this.state.submitText !== 'ثبت نام')
            this.setState({ submitText: 'ثبت نام', type: 'signup', error: '', isResetPassword: false, isCode: false })
        else if (window.location.pathname.endsWith('login') && this.state.submitText !== 'ورود')
            this.setState({ submitText: 'ورود', type: 'login', error: '', isResetPassword: false, isCode: false })
        else if (window.location.pathname.endsWith('resetpassword') && this.state.submitText !== 'بازیابی رمز عبور')
            this.setState({ submitText: 'بازیابی رمز عبور', type: 'resetpassword', error: '', isResetPassword: false, isCode: false })

        if (prevState.isCode !== this.state.isCode || prevState.isLoading !== this.state.isLoading)
            this.listenOTP()

        if (prevState.isCode !== this.state.isCode) {
            this.resendInterval && clearInterval(this.resendInterval)
            this.setState({ resendDuration: 120 })
            this.resendInterval = setInterval(() => this.state.resendDuration > 0 && this.setState({ resendDuration: this.state.resendDuration - 1 }), 1000)
        }
    }
    render() {
        return (
            <div className="auth-container" style={{ backgroundImage: `url(${background})` }}>
                <div className="overlay"></div>

                <div className="info" style={{ width: '370px' }}>
                    <p>
                        به سیستم معاملاتی ریت گلد خوش آمدید
                        <br /><br />
                        در صورتی که از قبل ثبت نام کرده‌اید می‌توانید از بخش ورود، وارد پنل کاربری خود شوید.
                        <br /><br />
                        در صورتی هم که از قبل ثبت نام نکرده‌اید، می‌توانید با وارد کردن تنها شماره موبایل در سیستم ثبت نام کرده و با تکمیل اطلاعات شخصی از خدمات سیستم استفاده کنید.
                    </p>
                </div>

                <div className="form-container">
                    <div className="nav">
                        {/*<NavLink onClick={e => this.state.isLoading && e.preventDefault()} to="/auth/signup">ثبت نام</NavLink>*/}
                        <NavLink onClick={e => this.state.isLoading && e.preventDefault()} to="/auth/preSignup">پیش ثبت نام</NavLink>
                        <NavLink onClick={e => this.state.isLoading && e.preventDefault()} to="/auth/login">ورود</NavLink>
                    </div>

                    <form className="form" onSubmit={this.onSubmit}>
                        {this.state.error && <p className="error">{this.state.error}</p>}

                        {this.state.isLoading ? <div className="loader"></div> :
                            <>
                                {this.state.isCode ?
                                    <div className="otp-container">
                                        <div id="otp">
                                            {Array(6).fill().map((_, i) =>
                                                <input key={i} type="text" name="otp" value={this.state.otpCode[i]} onChange={e => e} maxLength="1" spellCheck={false} />
                                            )}
                                        </div>

                                        <div className="underline">
                                            {this.state.resendDuration !== 0 ?
                                                <p>ارسال مجدد تا {this.secondsToMMSS(this.state.resendDuration)}</p> :
                                                <p className="underline-btn" onClick={() => this.setState({ error: '', type: 'login', isCode: false, isResetPassword: false }, () => this.getCaptcha())}>ارسال مجدد</p>
                                            }
                                        </div>

                                        {this.state.isResetPassword && <div className="password-container">
                                            <input type="password" name="password" value={this.state.password} onChange={this.handleChange} placeholder="رمز عبور جدید" />

                                            <input type="password" name="re_password" value={this.state.re_password} onChange={this.handleChange} placeholder="تکرار رمز عبور جدید" />
                                        </div>}
                                    </div>
                                    :
                                    <>
                                        <input type="text" name="mobile" value={this.state.mobile} onChange={this.handleChange} maxLength="11" onInput={e => e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')} placeholder="شماره موبایل" />

                                        {this.state.type !== 'resetpassword' && this.state.type !== 'preSignup' &&
                                            <input type="password" name="password" value={this.state.password} onChange={this.handleChange} placeholder="رمز عبور" />
                                        }

                                        {this.state.type === 'preSignup' && <>
                                            <input type="text" name="firstName" value={this.state.firstName} onChange={this.handleChange} placeholder="نام" />
                                            <input type="text" name="lastName" value={this.state.lastName} onChange={this.handleChange} placeholder="نام خانوادگی" />
                                            <input type="email" name="email" value={this.state.email} onChange={this.handleChange} placeholder="ایمیل" />
                                        </>
                                        }

                                        {this.state.type === 'signup' && <>
                                            <select name="acquaintance" value={this.state.acquaintance} onChange={this.handleChange}>
                                                <option value="" disabled hidden>راه آشنایی با سیستم</option>
                                                <option value="RELATIVE">اقوام</option>
                                                <option value="FRIEND">دوستان</option>
                                                <option value="SEARCH_ENGINE">موتور جستجو</option>
                                                <option value="SOCIAL_MEDIA">شبکه های اجتماعی</option>
                                            </select>

                                            <input type="text" name="referral" value={this.state.referral} onChange={this.handleChange} onInput={e => e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1')} placeholder="کد معرف" />

                                            <label htmlFor="termsofservice"><input type="checkbox" id="termsofservice" name="termsofservice" /> قبول قوانین</label>
                                        </>}

                                        {/*<div onClick={() => this.getCaptcha()} className="captcha"*/}
                                        {/*    dangerouslySetInnerHTML={{ __html: this.state.captcha }}>*/}
                                        {/*</div>*/}
                                        {/*<div onClick={() => this.getCaptcha()} className="captcha" style={{ marginTop: 10 }}*/}
                                        {/*    dangerouslySetInnerHTML={{*/}
                                        {/*        __html: `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="25" height="25" enable-background="new 0 0 65 65" viewBox="0 0 65 65">*/}
                                        {/*    <g fill="#555753">*/}
                                        {/*        <path d="m32.5 4.999c-5.405 0-10.444 1.577-14.699 4.282l-5.75-5.75v16.11h16.11l-6.395-6.395c3.18-1.787 6.834-2.82 10.734-2.82 12.171 0 22.073 9.902 22.073 22.074 0 2.899-0.577 5.664-1.599 8.202l4.738 2.762c1.47-3.363 2.288-7.068 2.288-10.964 0-15.164-12.337-27.501-27.5-27.501z"/>*/}
                                        {/*        <path d="m43.227 51.746c-3.179 1.786-6.826 2.827-10.726 2.827-12.171 0-22.073-9.902-22.073-22.073 0-2.739 0.524-5.35 1.439-7.771l-4.731-2.851c-1.375 3.271-2.136 6.858-2.136 10.622 0 15.164 12.336 27.5 27.5 27.5 5.406 0 10.434-1.584 14.691-4.289l5.758 5.759v-16.112h-16.111l6.389 6.388z"/>*/}
                                        {/*    </g>*/}
                                        {/*</svg>` }}>*/}
                                        {/*</div>*/}

                                        {/*<input type="text" name="captchaCode" value={this.state.captchaCode} onChange={this.handleChange} placeholder="کد کپتچا" maxLength="4" />*/}
                                        <div style={{marginTop: '15px'}}>
                                        <Recaptcha setReCaptchaValue={(value) => this.setState({
                                            ...this.state,
                                            recaptchaValue: value
                                        })} />
                                    </div>
                                    </>}
                                <button className="submit">{this.state.isCode ? 'بررسی اطلاعات' : this.state.submitText}</button>

                                {this.state.type === 'login' && <div className="underline reset_password_btn">
                                    <NavLink className="underline-btn" to="/auth/resetpassword">بازیابی رمز عبور</NavLink>
                                </div>}
                            </>
                        }
                    </form>
                </div>
            </div >
        )
    }
}

Auth.contextType = AuthContext

export default Auth
import React, { Component } from 'react'
import DefaultUser from '../../images/default-user.png'
import AuthContext from '../../AuthContext'
import Logo from '../../images/logo2.png'

class Header extends Component {
    constructor(props, context) {
        super()
        this.state = {
            name: !context.me.name ? (0 + context.me.mobile).replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d]) : `${context.me.name} ${context.me.family}`,
            credit: context.me.credit.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d])
        }
    }
    render() {
        return (
            <div className="header-container">
                <div className="logo">
                    <img src={Logo} alt="logo" style={{ width: '100%', height: '100%', padding: '10px' }} />
                </div>

                <div className="content">
                    <img className="profile-image" src={!this.props.ProfileImage ? DefaultUser : this.props.ProfileImage} alt="profile" />

                    <p className="name">{this.state.name}</p>

                    <div className="credit-container">
                        <p>دارایی لحظه ای: {this.state.credit} تومان</p>
                    </div>

                    <a href="/" className="back">بازگشت به سایت</a>
                </div>
            </div>
        )
    }
}

Header.contextType = AuthContext

export default Header
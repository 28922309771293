import React, { Component } from 'react'
import axios from 'axios'

let cacheNotificationState = null

export default class Notification extends Component {
    constructor() {
        super()
        this.state = {
            notifications: [],
            ...cacheNotificationState
        }
    }
    async getNotifications() {
        await this.props.setLoading({ isLoading: true, isFailed: false, errorFunc: null })

        axios.get(`/api/notification`)
            .then(r => {
                const data = r.data

                cacheNotificationState = {
                    ...this.state,
                    notifications: [
                        ...Object.values(data.news).map(n => { n.type = 'اخبار'; return n }),
                        ...Object.values(data.trade).map(n => { n.type = 'معامله'; return n }),
                        ...Object.values(data.system).map(n => { n.type = 'سیستمی'; return n })
                    ]
                }

                this.props.setLoading({ isLoading: false, isFailed: false, errorFunc: null })
            })
            .catch(e => {
                this.props.setLoading({ isLoading: true, isFailed: true, errorFunc: () => this.getNotifications() })
            })
    }
    componentDidMount() {
        !cacheNotificationState && this.getNotifications()
    }
    render() {
        return (
            <div className="portfos-container notification-container">
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>عنوان</th>
                                <th>نوع</th>
                                <th>تاریخ</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.state.notifications.sort((a, b) => new Date(b.create_date).getTime() - new Date(a.create_date).getTime()).map((p, i) =>
                                <tr key={i}>
                                    <td dir='rtl'>{p.subject}</td>
                                    <td>{p.type}</td>
                                    <td>{new Date(p.create_date).toLocaleDateString('fa-IR')}</td>
                                    <td onClick={() => this.props.setLoading({ notification: p })} className="deals-btn">مشاهده</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

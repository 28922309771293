import React, { Component } from 'react'
import axios from 'axios'
import AuthContext from '../../../AuthContext'

class Settings extends Component {
    constructor(props, context) {
        super()
        this.state = {
            isLoading: false,
            token_exp: JSON.parse(decodeURIComponent(escape(window.atob(localStorage.getItem('token').split('.')[1])))).exp,
            user_id: context.me.id,
            theme: context.me.UserMeta.theme,
            show_order_dialog: context.me.UserMeta.show_order_dialog,
            direct_order: context.me.UserMeta.direct_order
        }
    }
    onSubmit() {
        if (this.state.isLoading)
            return

        this.setState({ isLoading: true }, () => {
            axios.put('/api/user/settings', {
                theme: this.state.theme,
                direct_order: this.state.direct_order,
                show_order_dialog: this.state.show_order_dialog
            })
                .then(() => this.setState({ isLoading: false }, () => {
                    localStorage.setItem('theme', this.state.theme)
                    localStorage.setItem('direct_order', this.state.direct_order)
                    localStorage.setItem('show_order_dialog', this.state.show_order_dialog)
                    window.location.reload()
                }))
                .catch(() => this.setState({ isLoading: false }, () => alert('خطا در ارسال اطلاعات')))
        })
    }
    render() {
        return (
            <div className="settings-container">
                <p className="refferal_code">کد معرفی: {(this.state.user_id + 100).toLocaleString('fa-IR')}</p>

                <p className="save-btn" onClick={() => this.onSubmit()}>
                    {!this.state.isLoading
                        ? 'ذخیره' : <span className="loader"></span>
                    }
                </p>

                <div className="order_confirm">
                    <label className="title" htmlFor='show_order_dialog'>
                        تایید قبل از انجام معامله
                        <input type='checkbox' id="show_order_dialog" checked={this.state.show_order_dialog} onChange={e => this.setState({ show_order_dialog: e.target.checked })} />
                    </label>

                    <p>
                        با فعال شدن این گزینه شما می توانید با اطمینان بیشتری معامله کنید و لفظ درخواستی شما پس از تایید گذاشته شود.
                    </p>
                </div>

                <div className="direct_order">
                    <label className="title" htmlFor='direct_order'>
                        خرید لفظ های جدول لفظ
                        <input type='checkbox' id="direct_order" checked={this.state.direct_order} onChange={e => this.setState({ direct_order: e.target.checked })} />
                    </label>

                    <p>
                        با فعال سازی این گزینه شما می توانید با کلیک بر روی لفظ های موجود در جدول لفظ های دیگر معامله گران آن را به صورت سریع معامله کنید.
                    </p>
                </div>

                <div className="theme">
                    <p className="title"> حالت نمایش</p>

                    <div>
                        <label className="title" htmlFor="theme_dark">
                            حالت شب
                            <input type="radio" name="theme" id="theme_dark" value="DARK" checked={this.state.theme === 'DARK'} onChange={e => this.setState({ theme: e.target.value })} />
                        </label>

                        <label className="title" htmlFor="theme_light">
                            حالت روز
                            <input type="radio" name="theme" id="theme_light" value="LIGHT" checked={this.state.theme === 'LIGHT'} onChange={e => this.setState({ theme: e.target.value })} />
                        </label>
                    </div>
                </div>

                {/* <p className="refferal_code" style={{ fontSize: 14 }}>تاریخ انقضاء توکن ورود: {new Date((this.state.token_exp) * 1000).toLocaleDateString('fa-IR')}</p> */}
            </div>
        )
    }
}

Settings.contextType = AuthContext

export default Settings
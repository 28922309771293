import React, { Component } from 'react'
import axios from 'axios'
import ActionLimiter from "../../ActionLimiter";

export default class OrderForm extends Component {
    constructor() {
        super()
        this.limiter = new ActionLimiter(10, 60000, 'LPM');
        this.state = {
            isLoading: false,
            type: 'lafz',
            canPerform: true
        }

        this.onSubmit = this.onSubmit.bind(this)
    }
    async checkInputData(data) {
        let error = ""
        const inputs = Array.from(document.querySelector('.order-form').querySelectorAll('input'))

        await Promise.all(inputs.map(input => {
            let value = parseInt(input.value.replace(/,/g, ''))
            data[input.name] = value

            switch (input.name) {
                case 'fee':
                    if (!value)
                        error = 'قیمت وارد شده صحیح نمی باشد'
                    data[input.name] = value * 1000
                    break

                case 'amount':
                    if (!value)
                        error = "حجم وارد شده صحیح نمی باشد"
                    break

                case 'tolerance':
                    break

                case 'tp':
                    if (!value)
                        value = undefined
                    else
                        data[input.name] = value * 1000
                    break

                case 'sl':
                    if (!value)
                        value = undefined
                    else
                        data[input.name] = value * 1000
                    break

                default:
                    break
            }

            return input
        }))

        this.props.changeState({ error })

        if (error)
            return true
    }
    updateData() {
        this.props.socket.emit('portfo')
        this.props.socket.emit('order.my')
        this.props.socket.emit('deals', { room_id: 1 })
    }
    async onSubmit(type) {
        const data = { type }
        if (this.state.isLoading || await this.checkInputData(data))
            return

        const canPerform = this.limiter.canPerformAction();
        this.setState({ canPerform });

        if(!canPerform && Object.keys(this.props.user).length > 0 && this.props.user?.orderLimitFlag !== 1){
            alert("در هر دقیقه ثبت 10 لفظ از این قسمت مجاز است")
            return;
        }

        if (localStorage.getItem('show_order_dialog').toLowerCase() === 'true')
            if (!window.confirm('آیا از ارسال درخواست اطمینان دارید؟'))
                return

        this.setState({ isLoading: true }, () => {
            axios.post(`/api/trade/room/1/portfo/order`, data)
                .then(async res => {
                    if (res.data)
                        await this.props.changeState({
                            myOrders: [res.data, ...this.props.myOrders],
                        })

                    this.updateData()
                    this.setState({ isLoading: false })
                    setTimeout(() => this.updateData(), 700)
                    setTimeout(() => this.updateData(), 1700)
                    setTimeout(() => this.updateData(), 3000)
                })
                .catch(e => {
                    const data = e.response.data
                    const message = !data.message ? '' : Array.isArray(data.message) ? data.message[0] : data.message

                    this.setState({
                        isLoading: false
                    })

                    this.props.changeState({
                        error: !message ? 'خطا در ارسال درخواست' : message
                    })
                })
        })
    }
    componentDidMount() {
        document.addEventListener('emitOrder', event => {
            event.stopImmediatePropagation()
            const order = event.detail

            this.onSubmit(order.type)
        })
        this.limiter.actions = this.limiter.actions.filter(timestamp => Date.now() - timestamp < this.limiter.timeWindow);
        this.limiter.saveActions();
    }
    render() {
        return (
            <div className="order-form-container">
                <div className="nav">
                    <p style={this.state.type === 'order' ? { backgroundColor: '#f29f1f' } : {}} onClick={() => this.setState({ type: 'order' })}>اوردر</p>
                    <p style={this.state.type === 'lafz' ? { backgroundColor: '#f29f1f' } : {}} onClick={() => this.setState({ type: 'lafz' })}>لفظ</p>
                </div>

                <form className="order-form">
                    {this.state.isLoading ? <div className="loader"></div> :
                        <>
                            <div className="input-container">
                                <label>:حجم</label>
                                <div>
                                    <span>واحد</span>
                                    <input type="text" name="amount" onInput={e => e.target.value = e.target.value.replace(/[^0-9.]/g, '')} />
                                </div>
                            </div>

                            <div className="input-container">
                                <label>:قیمت</label>
                                <div>
                                    <span>تومان</span>
                                    <input type="text" name="fee" onInput={e => e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                                </div>
                            </div>

                            {this.state.type === 'order' && <div className="input-container">
                                <label>:تلرانس</label>
                                <div className="range-container">
                                    <input type="range" name="tolerance" defaultValue={10} min="1" max="10" onInput={e => document.querySelector('.range-value').innerHTML = parseInt(e.target.value).toLocaleString('fa-IR')} />
                                    <span className="range-value">۱۰</span>
                                </div>
                            </div>}

                            <div className="line"></div>

                            <div className="input-container">
                                <label>:حد سود</label>
                                <div>
                                    <span>تومان</span>
                                    <input type="text" name="tp" onInput={e => e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                                </div>
                            </div>

                            <div className="input-container">
                                <label>:حد ضرر</label>
                                <div>
                                    <span>تومان</span>
                                    <input type="text" name="sl" onInput={e => e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                                </div>
                            </div>
                        </>
                    }
                </form>

                <div className="nav buy-sell">
                    <p onClick={() => this.onSubmit('SELL')} style={this.props.portfo ? { backgroundColor: '#ff004e' } : {}}>فروش</p>
                    <p onClick={() => this.onSubmit('BUY')} style={this.props.portfo ? { backgroundColor: '#4aaf23' } : {}}>خرید</p>
                </div>
            </div>
        )
    }
}

import React, { Component } from 'react'

export default class ChartHeader extends Component {
    render() {
        return (
            <div className="chart-header-container">
                {!this.props.isMobile && <a href='/dashboard' className="dashboard">←</a>}

                {this.props.isConnected && <>
                    <p>
                        <span>انس جهانی: </span>
                        <span>{this.props.metaPrice.GlobalOunce.toLocaleString('fa-IR')} تومان</span>
                    </p>

                    <span className="line"></span>

                    <p>
                        <span>دلار هرات: </span>
                        <span>{this.props.metaPrice.HaratDollar.toLocaleString('fa-IR')} تومان</span>
                    </p>

                    <span className="line"></span>

                    <p>
                        <span>آخرین معامله: </span>
                        <span>{this.props.price.lastDealValue.toLocaleString('fa-IR')} تومان</span>
                    </p>

                    <span className="line"></span>
                    <p>
                        <span>تعداد پرتفو باز: </span>
                        <span>{!this.props.portfoCount ? '۰' : Math.ceil(this.props.portfoCount * 1.3).toLocaleString('fa-IR', { useGrouping: false })} عدد</span>
                    </p>
                </>
                }
            </div>
        )
    }
}

import React, {Component} from 'react'
import axios from 'axios'
import * as shamsi from 'shamsi-date-converter'
import AuthContext from '../../../AuthContext'
import AuthPlaceholder from '../../../images/auth-placeholder.png'

let cachePersonalInfoState = {}

class PersonalInfo extends Component {
    constructor(props, context) {
        super()
        this.state = {
            error: '',
            gender: '',
            phone: '',
            resendDuration: 0,
            level: !context.me.UserMeta.confirm_wait && context.me.level,
            confirmFailed: context.me.UserMeta.confirm_failed,
            confirmMessage: context.me.UserMeta.confirm_message,
            isWaiting: context.me.UserMeta.confirm_wait,
            isCode: false,
            isEditEmail: false,
            ...cachePersonalInfoState
        }
        this.setLevel(context.me.level)

        this.onSubmit = this.onSubmit.bind(this)
    }

    setLevel(level) {
        switch (level) {
            case 'MOBILE_CONFIRMED':
                this.levelText = 'سطح یک'
                this.levelReq = '* برای شروع کار با سیستم اطلاعات خود را تکمیل نمایید. (سطح کاربری دو مجاز به باز کردن پرتفو به اندازه ۱ واحد می باشد)'
                break

            case 'NATIONAL_CARD_CONFIRMED':
                this.levelText = 'سطح دو'
                this.levelReq = '* برای ارتقا سطح کاربری خود، مرحله ی احراز هویت خود را تکمیل کنید. (سطح کاربری سه مجاز به باز کردن پرتفو به اندازه ۵ واحد می باشد)'
                break

            case 'AUTH_IMAGE_CONFIRMED':
                this.levelText = 'سطح سه'
                this.levelReq = '* برای استفاده از تمامی خدمات سیستم بدون محدودیت، شماره ثابت خود را تایید کنید.'
                break

            case 'PHONE_CONFIRMED':
                this.levelText = 'سطح چهار'
                this.levelReq = '> شما تمامی مراحل احراز هویت را انجام داده اید.'
                break

            default:
                break
        }
    }

    secondsToMMSS(time) {
        var sec_num = parseInt(time, 10)
        var minutes = Math.floor(sec_num / 60)
        var seconds = sec_num - (minutes * 60)

        if (seconds < 10) {
            seconds = "0" + seconds
        }
        return (minutes !== 0 ? minutes + ':' : '') + seconds
    }

    async checkInputData(e, data) {
        let error = ""
        let image = null
        const inputs = Array.from(e.querySelectorAll('input'))

        await Promise.all(inputs.map(input => {
            switch (input.name) {
                case 'phone':
                    if (!input.value.match(/^0[0-8]{2,}[0-9]{7,}$/))
                        error = 'شماره تلفن نامعتبر می باشد'
                    break

                case 'phone_code':
                    if (!input.value || input.value.length !== 6)
                        error = 'کد تایید نامعتبر می باشد'
                    data.append('phone_code', input.value)
                    break

                case 'name':
                    if (!input.value.match(/^[\u0600-\u06FF\s]+$/g))
                        error = 'نام وارد شده فارسی نمی باشد'
                    if (!input.value)
                        error = 'نام خود را وارد نمایید'
                    data.append('name', input.value)
                    break

                case 'family':
                    if (!input.value.match(/^[\u0600-\u06FF\s]+$/g))
                        error = 'نام خانوادگی وارد شده فارسی نمی باشد'
                    if (!input.value)
                        error = 'نام خانوادگی خود را وارد نمایید'
                    data.append('family', input.value)
                    break

                case 'national_id':
                    if (!input.value)
                        error = 'کد ملی خود را وارد نمایید'
                    data.append('national_id', input.value)
                    break

                case 'birth':
                    if (!input.value)
                        error = 'تاریخ تولد خود را وارد نمایید'
                    else {
                        const rawDate = input.value.split('/').map(d => parseInt(d))
                        const birthdate = shamsi.jalaliToGregorian(rawDate[0], rawDate[1], rawDate[2]).map(d => d < 10 ? '0' + d.toString() : d).join('-')
                        data.append('birth', birthdate + 'T19:30:00.000Z')
                    }
                    break

                case 'national_card_image':
                    image = input.files[0]
                    if (!image)
                        error = 'تصویر کارت ملی نامعتبر می باشد'
                    data.append('national_image', image)
                    break

                case 'profile_image':
                    image = input.files[0]
                    if (!image)
                        error = 'تصویر پروفایل نامعتبر می باشد'
                    data.append('profile_image', image)
                    break

                case 'auth_image':
                    image = input.files[0]
                    if (!image)
                        error = 'تصویر نامعتبر می باشد'
                    data.append('confirm_image', image)
                    break

                default:
                    break
            }

            if (input.type === 'file' && image) {
                if (image.type !== 'image/png' && image.type !== 'image/jpg' && image.type !== 'image/jpeg')
                    error = 'تنها فرمت png و jpg برای تصویر مجاز می باشد'

                if (image.size > 10000000)
                    error = 'حداکثر حجم مجاز برای تصویر 10MB می باشد'
            }

            return input
        }))

        this.setState({error})

        if (error)
            return true
    }

    async onSubmit(e) {
        const data = new FormData()
        if (await this.checkInputData(e, data))
            return
        if (this.state.level === 'MOBILE_CONFIRMED' && !this.state.gender) {
            this.setState({error: 'جنسیت خود را انتخاب نمایید'})
            return
        }
        this.state.gender && data.append('gender', this.state.gender)
        this.state.phone && data.append('phone', this.state.phone)

        await this.props.setLoading({isLoading: true, error: ''})

        await axios.put('/api/user/personal_info', data, {headers: {"Content-Type": "multipart/form-data"}})
            .then(res => {
                if (this.state.level === 'AUTH_IMAGE_CONFIRMED') {
                    if (this.state.isCode) {
                        cachePersonalInfoState = {
                            ...this.state,
                            error: '',
                            level: 'PHONE_CONFIRMED',
                            isWaiting: false
                        }
                        this.context.changeMe({
                            ...this.context.me,
                            UserMeta: {
                                ...this.context.me.UserMeta,
                                phone: this.state.phone
                            },
                            level: 'PHONE_CONFIRMED'
                        })
                    } else
                        cachePersonalInfoState = {
                            ...this.state,
                            error: '',
                            isCode: true
                        }
                } else
                    cachePersonalInfoState = {
                        ...this.state,
                        error: '',
                        level: '',
                        isWaiting: true
                    }
            })
            .catch(e => {
                const data = e.response.data
                const message = !data.message ? '' : Array.isArray(data.message) ? data.message[0] : data.message

                if (data.code === 119)
                    cachePersonalInfoState = {
                        ...this.state,
                        error: message,
                        isCode: true
                    }
                else
                    cachePersonalInfoState = {
                        ...this.state,
                        error: !message ? 'خطا در ارسال درخواست' : message,
                    }
            })

        await this.props.setLoading({isLoading: false})

        this.setState(cachePersonalInfoState)
    }

    async updateUserData(me, setLoading) {
        const emailValue = document.querySelector('#email').value.trim()
        if (!emailValue || emailValue === '') return

        setLoading({isLoading: true, error: ''})

        await axios.patch(`/api/user/${me.id}`, {
            email: emailValue
        }).then(() => {
            alert('بروزرسانی با موفقیت انجام شد. ایمیل تایید برای شما ارسال خواهد شد')
            window.location.reload()
        }).catch(() => {
            alert('خطا در بروزرسانی')
        }).finally(() => {
            setLoading({isLoading: false})
        })
    }

    componentDidMount() {
        window.jalaliDatepicker.startWatch()
    }

    render() {
        const me = this.context.me
        return (
            <div className="personal-info-container">
                <p className="level-text">سطح کاربری فعلی شما: {this.levelText}</p>
                <p className="level-req" style={!this.state.isWaiting ? {} : {color: '#787219'}}>
                    {this.state.confirmFailed ? this.state.confirmMessage : !this.state.isWaiting ?
                        this.levelReq : 'اطلاعات در حال بررسی می باشند'
                    }
                </p>

                {this.state.level !== 'PHONE_CONFIRMED' && <div style={{marginBottom: "10px"}}>
                    <strong style={{color: '#862424', lineHeight: 1.7, textAlign: 'justify'}}>
                        * حجم مجاز تصاویر برای ادامه صحیح روند ارتقای سطح کاربری حداکثر 500 کیلوبایت برای هر تصویر می
                        باشد، درصورت بارگذاری تصویر با حجم بالاتر امکان بروز خطا در ادامه روند خواهد بود.
                    </strong>
                </div>}

                {this.state.error && <p className="error level-req">{this.state.error}</p>}

                {(this.state.level === 'MOBILE_CONFIRMED' || this.state.level === 'AUTH_IMAGE_CONFIRMED') &&
                    <div className="form-container">
                        <form className="form" onSubmit={e => {
                            e.preventDefault();
                            this.onSubmit(e.target)
                        }}>
                            {this.state.level === 'AUTH_IMAGE_CONFIRMED' && (!this.state.isCode ?
                                    <input type="text" name="phone" placeholder="شماره تلفن" maxLength={11}
                                           onChange={e => this.setState({phone: e.target.value})}
                                           onInput={e => e.target.value = e.target.value.replace(/[^0-9.]/g, '')}/> :
                                    <>
                                        <input type="text" name="phone_code" placeholder="کد" maxLength={6}
                                               onInput={e => e.target.value = e.target.value.replace(/[^0-9.]/g, '')}/>
                                        <div className="underline">
                                            {this.state.resendDuration !== 0 ?
                                                <p>ارسال مجدد تا {this.secondsToMMSS(this.state.resendDuration)}</p> :
                                                <p className="underline-btn"
                                                   onClick={() => this.setState({error: '', isCode: false})}>ارسال
                                                    مجدد</p>
                                            }
                                        </div>
                                    </>
                            )}

                            {this.state.level === 'MOBILE_CONFIRMED' && <>
                                <input type="text" name="name" placeholder="نام"/>

                                <input type="text" name="family" placeholder="نام خانوادگی"/>


                                <input type="text" defaultValue={me?.national_id?.replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d])}
                                       disabled={me?.national_id} name="national_id" placeholder="کد ملی" maxLength={10}
                                       onInput={e => e.target.value = e.target.value.replace(/[^0-9.]/g, '')}/>

                                <input data-jdp name="birth" placeholder="تاریخ تولد" autoComplete="off"/>

                                <select name="gender" onChange={e => this.setState({gender: e.target.value})}
                                        defaultValue="">
                                    <option value="" disabled hidden>جنسیت</option>
                                    <option value="MALE">مرد</option>
                                    <option value="FEMALE">زن</option>
                                </select>

                                <label htmlFor="national_card">:تصویر کارت ملی</label>
                                <input type="file" id="national_card" name="national_card_image"/>

                                <label htmlFor="profile">:تصویر پروفایل</label>
                                <input type="file" id="profile" name="profile_image"/>
                            </>}

                            <button className="submit"
                                    disabled={this.state.level === 'MOBILE_CONFIRMED' && me?.national_id}>{this.state.isCode ? 'بررسی کد' : this.state.level === 'AUTH_IMAGE_CONFIRMED' ? 'ارسال کد' : 'ارسال اطلاعات'}</button>
                        </form>
                    </div>}

                {this.state.level === 'NATIONAL_CARD_CONFIRMED' && <>
                    <p className="auth-tutorial-title">برای استفاده بدون محدودیت از تمامی خدمات سیستم و ارتقا به سطح
                        کاربری سه، متن زیر را در برگه ای نوشته و عکسی همانند الگو به صورتی که چهره شما پوشانده نشده باشد
                        به همراه کارت ملی گرفته و ارسال نمایید.</p>

                    <div className="auth-tutorial">
                        <div className="text">
                            <p>اینجانب ............... با کد ملی ............. ضمن مطالعه کامل قوانین و مقررات، کلیه
                                قوانین و شرایط استفاده از خدمات وب سایت ریت گلد را قبول دارم و تعهد می نمایم حساب کاربری
                                خود را در اختیار فرد دیگری قرار ندهم همچنین مسئولیت تمام خرید و فروش های خود در وب سایت
                                ریت گلد را به عهده میگیرم.</p>
                            <p>نام و نام خانوادگی</p>
                            <p>تاریخ</p>
                            <p>امضا</p>
                        </div>

                        <div className="placeholder-image">
                            <img src={AuthPlaceholder} alt="placeholder"/>

                            <form>
                                <input type="file" name="auth_image" onChange={e => this.onSubmit(e.target.parentNode)}
                                       style={{height: 0, width: 0, overflow: 'hidden'}}/>
                            </form>
                            <div id="auth-image"
                                 onClick={() => document.querySelector('input[name=auth_image]').click()}>ارسال تصویر
                            </div>
                        </div>
                    </div>
                </>}

                {this.state.level === 'PHONE_CONFIRMED' &&
                    <div className="auth-info">
                        <p className="title">اطلاعات شما</p>

                        <div className="auth-data">
                            <table>
                                <tr>
                                    <td>نام و نام خانوادگی:</td>
                                    <td>{me?.name} {me?.family}</td>
                                </tr>
                                <tr>
                                    <td>کد ملی:</td>
                                    <td>{me?.national_id?.replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d])}</td>
                                </tr>
                                <tr>
                                    <td>شماره موبایل:</td>
                                    <td>{('0' + me?.mobile)?.replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d])}</td>
                                </tr>
                                <tr>
                                    <td>شماره ثابت:</td>
                                    <td>{me?.UserMeta?.phone?.replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d])}</td>
                                </tr>
                                <tr>
                                    <td>جنسیت:</td>
                                    <td>{me?.gender === 'MALE' ? 'آقا' : 'خانم'}</td>
                                </tr>
                                <tr>
                                    <td>تاریخ تولد:</td>
                                    <td>{new Date(me?.UserMeta?.birth).toLocaleString('fa-IR', {
                                        year: "numeric",
                                        month: "numeric",
                                        day: 'numeric'
                                    })}</td>
                                </tr>
                                <tr>
                                    <td>ایمیل:</td>
                                    <td>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            {!me?.email || this.state.isEditEmail ? <>

                                                <input type="text" id="email" defaultValue={me?.email}
                                                       style={{
                                                           width: '100%',
                                                           padding: '5px',
                                                           backgroundColor: 'transparent'
                                                       }}/>
                                                <button onClick={() => this.updateUserData(me, this.props.setLoading)}
                                                        style={{
                                                            backgroundColor: '#4baf01',
                                                            padding: '3px',
                                                            marginTop: '8px',
                                                            cursor: 'pointer'
                                                        }}>ثبت ایمیل
                                                </button>
                                            </> : <>

                                                <p>{me?.email}</p>
                                                <button
                                                    onClick={() => this.setState({...this.state, isEditEmail: true})}
                                                    style={{
                                                        backgroundColor: '#4baf01',
                                                        padding: '3px',
                                                        marginTop: '8px',
                                                        cursor: 'pointer'
                                                    }}>ویرایش ایمیل
                                                </button>
                                            </>}

                                        </div>
                                    </td>
                                </tr>
                            </table>

                            {/*<div>*/}
                            {/*    <p>نام و نام خانوادگی:</p>*/}
                            {/*    <p>کد ملی:</p>*/}
                            {/*    <p>شماره موبایل:</p>*/}
                            {/*    <p>شماره ثابت:</p>*/}
                            {/*    <p>جنسیت:</p>*/}
                            {/*    <p>تاریخ تولد:</p>*/}
                            {/*    <p>ایمیل:</p>*/}
                            {/*</div>*/}

                            {/*<div>*/}
                            {/*    <p>{me?.name} {me?.family}</p>*/}
                            {/*    <p>{me?.national_id?.replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d])}</p>*/}
                            {/*    <p>{('0' + me?.mobile)?.replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d])}</p>*/}
                            {/*    <p>{me?.UserMeta?.phone?.replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d])}</p>*/}
                            {/*    <p>{me?.gender === 'MALE' ? 'آقا' : 'خانم'}</p>*/}
                            {/*    <p>{new Date(me?.UserMeta?.birth).toLocaleString('fa-IR', {*/}
                            {/*        year: "numeric",*/}
                            {/*        month: "numeric",*/}
                            {/*        day: 'numeric'*/}
                            {/*    })}</p>*/}
                            {/*    <p>*/}
                            {/*        <div style={{display: 'flex', flexDirection: 'column'}}>*/}
                            {/*            <input type="text" id="email" defaultValue={me?.email} style={{width: '100%'}}/>*/}
                            {/*            <button onClick={() => this.updateUserData(me, this.props.setLoading)} style={{*/}
                            {/*                backgroundColor: '#4baf01',*/}
                            {/*                padding: '3px',*/}
                            {/*                marginTop: '8px',*/}
                            {/*                cursor: 'pointer'*/}
                            {/*            }}>ویرایش ایمیل*/}
                            {/*            </button>*/}
                            {/*        </div>*/}
                            {/*    </p>*/}
                            {/*</div>*/}
                        </div>

                        {!me?.email &&
                            <p style={{color: 'tomato', lineHeight: 1.5}}>
                                لطفا برای ادامه فعالیت در وبسایت، ایمیل خود را
                                وارد کنید.
                            </p>
                        }
                        {me?.email && !me?.emailConfirmed &&
                            <p style={{color: 'tomato', lineHeight: 1.5}}>
                                در انتظار تایید ایمیل
                            </p>
                        }
                    </div>
                }
            </div>
        )
    }
}

PersonalInfo.contextType = AuthContext

export default PersonalInfo
import React, { Component } from 'react'

export default class ConnectionModal extends Component {
    render() {
        if (!this.props.isLoading && !this.props.ConnectionError)
            return <></>
        else
            return (
                <div className="modal">
                    {!this.props.ConnectionError ?
                        <div className="loader"></div> :
                        <div className="container" style={{ justifyContent: 'space-between', padding: '20px' }}>
                            {this.props.ConnectionError === '-1' ?
                                <p className="message">تعداد کانکشن های متصل بیشتر از تعداد مجاز می باشد، لطفا متصل بودن به اتاق را با دستگاه های دیگر بررسی کنید، در غیر اینصورت رمز خود را تغییر دهید تا تمامی دستگاه های متصل قطع شوند</p> :
                                this.props.ConnectionError === '0' ?
                                    <p className="message">از ارتباط خود با اینترنت اطمینان پیدا کنید</p> :
                                    this.props.ConnectionError === '-3' ?
                                        <p className="message">بازار موقتا تعطیل می باشد</p> :
                                        <p className="message">ساعت کار بازار به پایان رسیده</p>
                            }

                            <div className="buttons-container">
                                <p className="reconnect-btn dashboard-btn" style={{ backgroundColor: 'grey', marginRight: 10 }} onClick={() => window.location = '/dashboard'}>بازگشت به داشبورد</p>
                                <p className="reconnect-btn" onClick={() => this.props.reconnect()}>تلاش مجدد</p>
                            </div>
                        </div>}
                </div>
            )
    }
}

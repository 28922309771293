import ReCAPTCHA from 'react-google-recaptcha';

const Recaptcha = ({setReCaptchaValue}) => {
    return (
        <div>
            <ReCAPTCHA
                sitekey="6Lc_ZzoqAAAAACjyzGOwqcdMUJPFdV5x6xy5a9T7"
                onChange={value => setReCaptchaValue(value)}
            />
        </div>
    );
};

export default Recaptcha;
import React, { Component } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import AuthContext from './AuthContext'
import Logo from './images/logo2.png'
import UserIcon from './images/user-icon.png'

function Items({ token, close, showUserItem }) {
    return (
        <>
            <NavLink onClick={close} to='/'>خانه</NavLink>
            <NavLink onClick={close} to='/blog'>بلاگ</NavLink>
            <NavLink onClick={close} to='/guide'>راهنما</NavLink>
            <NavLink onClick={close} to='/shop'>فروشگاه</NavLink>
            <NavLink onClick={close} to='/complaint'>ثبت شکایات</NavLink>
            <NavLink onClick={close} to='/privacy_policy'>قوانین و مقررات</NavLink>
            <NavLink onClick={close} to='/faq'>سوالات متداول</NavLink>
            {
                showUserItem && <NavLink onClick={close} to={!token ? '/auth' : '/dashboard'}>
                    {token ? 'داشبورد' : 'ورود'}
                </NavLink>
            }
        </>
    )
}

class Navbar extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
            showUserItem: document.documentElement.clientWidth <= 800
        }
    }
    componentDidMount() {
        window.onresize = e => {
            const w = e.target.innerWidth
            this.setState({ showUserItem: w <= 800 })
        }
    }
    render() {
        if (this.props.location.pathname.startsWith('/dashboard') || this.props.location.pathname.startsWith('/room'))
            return
        else
            return (
                <div className="navbar-container">
                    <div className="navbar">
                        <div className="logo-container">
                            <div className="logo-wrapper">
                                <img src={Logo} alt="logo" />
                            </div>
                        </div>

                        <div className="user-icon">
                            <NavLink to={!this.context.token ? '/auth/login' : '/dashboard'}><img src={UserIcon} alt="user icon" /></NavLink>
                        </div>

                        <div className="items">
                            <Items token={this.context.token} showUserItem={this.state.showUserItem} />
                        </div>

                        {/* Responsive */}
                        <span
                            className="hamberger-btn"
                            style={this.state.isOpen ? { fontSize: 40, position: 'fixed', top: 37 } : {}}
                            onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                        >
                            {!this.state.isOpen ? '☰' : '×'}
                        </span>
                        <ul className="items-rwd" style={this.state.isOpen ? { width: document.documentElement.clientWidth >= 350 ? '300px' : '100%' } : {}}>
                            <Items
                                token={this.context.token}
                                close={() => this.setState({ isOpen: false })}
                                showUserItem={this.state.showUserItem}
                            />
                        </ul>
                    </div>
                    {/*<div style={{direction: 'rtl',textAlign: 'center', fontSize: '23px', paddingTop: '10px',paddingBottom: '15px'}}>*/}
                    {/*    جهت خرید طلای آب‌شده به سامانه*/}
                    {/*    <a href="https://keivangold.ir" target="_blank">keivangold.ir</a>*/}
                    {/*    مراجعه فرمایید*/}
                    {/*</div>*/}
                </div>
            )
    }
}

Navbar.contextType = AuthContext

export default function HOC() {
    return <Navbar location={useLocation()} />
}
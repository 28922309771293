import React, { Component } from 'react'
import ActionLimiter from "../../ActionLimiter";

export default class OrderList extends Component {
    constructor(props) {
        super(props);
        this.limiter = new ActionLimiter(10, 60000, 'LOPM');
        this.state = {
            canPerform: true,
        };
    }

    componentDidMount() {
        this.limiter.actions = this.limiter.actions.filter(timestamp => Date.now() - timestamp < this.limiter.timeWindow);
        this.limiter.saveActions();
    }


    setOrder(o, type) {
        const canPerform = this.limiter.canPerformAction();
        this.setState({ canPerform });

        const form = document.querySelector('.order-form')

        if (!type || localStorage.getItem('direct_order').toLowerCase() !== 'true') {
            form.querySelector('input[name=fee]').value = o.fee.toString().slice(0, -3).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            form.querySelector('input[name=amount]').value = o.count
        }
        else {
            if (!this.props.portfo) return

            if(!canPerform && Object.keys(this.props.user).length > 0 && this.props.user?.orderLimitFlag !== 1){
                alert("در هر دقیقه ثبت 10 لفظ از این قسمت مجاز است")
                return;
            }

            form.querySelector('input[name=fee]').value = o.fee.toString().slice(0, -3).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            form.querySelector('input[name=amount]').value = 1

            const emitOrderEvent = new CustomEvent('emitOrder', {
                bubbles: true,
                detail: { ...o, type }
            })

            document.dispatchEvent(emitOrderEvent)
        }
    }
    render() {
        return (
            <div className="order-list-container">
                <div className="order-list">
                    <div className="list sell">
                        {this.props.orders.BUY.sort((a, b) => b.fee - a.fee).map((o, i) => o.count !== 0 &&
                            <p key={i} onClick={() => this.setOrder(o, 'SELL')}>{this.props.formatPrice(o.fee)} - {o.count.toLocaleString('fa-IR', { useGrouping: false })}</p>
                        )}
                    </div>

                    <div className="last-price" onClick={() => this.setOrder({ fee: this.props.price.value, count: 1 })}>
                        <p>{this.props.formatPrice(this.props.price.value)}</p>
                        <p>:مظنه</p>
                    </div>

                    <div className="list buy">
                        {this.props.orders.SELL.sort((a, b) => a.fee - b.fee).map((o, i) => o.count !== 0 &&
                            <p key={i} onClick={() => this.setOrder(o, 'BUY')}>{this.props.formatPrice(o.fee)} - {o.count.toLocaleString('fa-IR', { useGrouping: false })}</p>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

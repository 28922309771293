import React, { Component } from 'react'
import axios from 'axios'
import AuthContext from '../../../AuthContext'

let evtSource = null
let cacheGoldWalletState = null

class GoldWallet extends Component {
    constructor(props, context) {
        super()
        this.state = {
            goldWallet: context.me.UserMeta.gold_wallet,
            sendError: '',
            sellError: '',
            buyError: '',
            mode: '',
            tolerance: {},
            goldPrice: 0,
            buyAmount: 0,
            sellAmount: 0,
            page: 0,
            next: false,
            last: false,
            isDisabled: false,
            history: [],
            ...cacheGoldWalletState
        }

        this.onSubmit = this.onSubmit.bind(this)

        props.setLoading({ isLoading: !cacheGoldWalletState, isFailed: false, errorFunc: null })

        this._isMounted = false
    }
    async checkInputData(e, data) {
        let error = ""
        const inputs = [
            ...Array.from(e.target.querySelectorAll('input')),
            ...Array.from(e.target.querySelectorAll('textarea'))
        ]

        await Promise.all(inputs.map(input => {
            switch (input.name) {
                case 'amount':
                    if (!Number(input.value))
                        error = 'مقدار گرم نامعتبر می باشد'
                    data['amount'] = Number(input.value)
                    break

                case 'postal_code':
                    if (!input.value || input.value.length !== 10)
                        error = 'کد پستی نامعتبر می باشد'
                    data['postal_code'] = input.value
                    break

                case 'address':
                    if (!input.value)
                        error = 'آدرس نامعتبر می باشد'
                    data['address'] = input.value
                    break

                default:
                    break
            }

            return input
        }))

        if (e.target.id === 'send-form')
            this.setState({ sendError: error })
        else if (e.target.id === 'sell-form')
            this.setState({ sellError: error })
        else if (e.target.id === 'buy-form')
            this.setState({ buyError: error })

        if (error)
            return true
    }
    async onSubmit(e) {
        e.preventDefault()
        let url = ''
        const data = {}
        if (await this.checkInputData(e, data))
            return

        await this.props.setLoading({ isLoading: true, buyError: '', sendError: '', sellError: '' })

        if (e.target.id === 'send-form')
            url = '/api/gold-wallet/send'
        else if (e.target.id === 'sell-form')
            url = '/api/gold-wallet/sell'
        else
            url = '/api/gold-wallet/buy'

        await axios.post(url, data)
            .then(res => {
                if (e.target.id === 'buy-form')
                    window.location = res.data.url
                else
                    window.location.reload()
            })
            .catch(err => {
                const data = err.response.data
                const message = !data.message ? '' : Array.isArray(data.message) ? data.message[0] : data.message

                if (e.target.id === 'send-form')
                    cacheGoldWalletState = {
                        ...this.state,
                        sendError: !message ? 'خطا در ارسال درخواست' : message
                    }
                else if (e.target.id === 'sell-form')
                    cacheGoldWalletState = {
                        ...this.state,
                        sellError: !message ? 'خطا در ارسال درخواست' : message
                    }
                else
                    cacheGoldWalletState = {
                        ...this.state,
                        buyError: !message ? 'خطا در ارسال درخواست' : message,
                    }
            })

        await this.props.setLoading({ isLoading: false })

        this.setState(cacheGoldWalletState)
    }
    getStatusText(s) {
        switch (s) {
            case 'EXPIRED':
                return 'منقضی شده'
            case 'CANCELLED':
                return 'لغو شده'

            case 'WAIT_PURCHASE':
                return 'در انتظار پرداخت'
            case 'PURCHASED':
                return 'پرداخت شده'
            case 'BOUGHT':
                return 'خرید موفق'

            case 'WAIT_SELL':
                return 'در انتظار فروش'
            case 'SOLD':
                return 'فروش موفق'

            case 'WAIT_SEND':
                return 'در انتظار ارسال'
            case 'SENT':
                return 'ارسال شده'

            default:
                break
        }
    }
    async getHistory() {
        await this.props.setLoading({ isLoading: true, isFailed: false, errorFunc: null })

        axios.get('/api/gold-wallet?page=' + this.state.page)
            .then(async r => {
                const data = r.data

                cacheGoldWalletState = {
                    ...cacheGoldWalletState,
                    ...this.state,
                    next: data.next,
                    last: data.last,
                    history: data.data
                }

                await this.getTolerance()
            })
            .catch(e => {
                this.props.setLoading({ isLoading: true, isFailed: true, errorFunc: () => this.getHistory() })
            })
    }
    async getTolerance() {
        await axios.get('/api/general-settings?key=gw_tolerance')
            .then(r => {
                const data = r.data

                cacheGoldWalletState = {
                    ...cacheGoldWalletState,
                    tolerance: data.gw_tolerance,
                    isDisabled: !data.gw_tolerance.gold_wallet_status
                }

                this.props.setLoading({ isLoading: false, isFailed: false, errorFunc: null })
            })
            .catch(e => {
                this.props.setLoading({ isLoading: true, isFailed: true, errorFunc: () => this.getHistory() })
            })
    }
    async startPriceListener() {
        return new Promise((resolve, reject) => {
            if (evtSource)
                evtSource.close()

            evtSource = new EventSource("/api/price/geram18/sse")

            evtSource.onopen = async event => {
                const currentPrice = (await axios.get('/api/price/geram18')).data
                if (currentPrice)
                    this._isMounted && this.setState({ goldPrice: !currentPrice.value ? 0 : currentPrice.value }, () => resolve())
                else
                    resolve()
            }

            evtSource.onmessage = event => {
                this._isMounted && this.setState({ goldPrice: JSON.parse(event.data).value })
            }

            evtSource.onerror = () => {
                setTimeout(() => this.startPriceListener(), 3000)
                resolve()
            }
        })
    }
    calcTolerance(amount, price, type) {
        amount = parseFloat(amount)
        if (!amount) return 0

        const o = type !== 'sell' ? 1 : -1
        const settings = this.state.tolerance

        if (amount <= 10)
            price = price + (o * settings.gw_between_zero_ten_geram)

        else if (amount > 10 && amount <= 50)
            price = price + (o * settings.gw_between_ten_fifty_geram)

        else if (amount > 50)
            price = price + (o * settings.gw_more_fifty_geram)

        price = amount * price

        return Math.floor(price)
    }
    async componentDidMount() {
        this._isMounted = true

        if (!cacheGoldWalletState)
            await this.getHistory()
        else
            await this.startPriceListener()
    }
    componentWillUnmount() {
        this._isMounted = false
    }
    render() {
        return (
            <div className="wallet-container gold_wallet-container">
                {this.state.isDisabled && <p style={{ color: 'red', alignSelf: 'flex-end', marginBottom: '30px' }} className="error">کیف طلایی موقتا غیر فعال می باشد</p>}

                <div className="gold-table">
                    <p>
                        <span>موجودی (طلا ۱۸ عیار)</span>
                        <span>{this.state.goldWallet.toLocaleString('fa-IR', { useGrouping: false })}</span>
                    </p>

                    <p>
                        <span>قیمت هر گرم طلا ۱۸ عیار</span>
                        <span>{this.state.goldPrice.toLocaleString('fa-IR')} تومان</span>
                    </p>

                    <p>
                        <span>ارزش کیف پول طلایی من</span>
                        <span>{(this.state.goldWallet * this.state.goldPrice).toLocaleString('fa-IR')} تومان</span>
                    </p>
                </div>

                <div className="mode-container">
                    <button style={this.state.isDisabled ? { backgroundColor: 'grey', cursor: 'not-allowed' } : { backgroundColor: '#4baf01' }} onClick={() => !this.state.isDisabled && this.setState({ mode: 'buy' })}>خرید</button>
                    <button style={this.state.isDisabled ? { backgroundColor: 'grey', cursor: 'not-allowed' } : { backgroundColor: '#ff004e' }} onClick={() => !this.state.isDisabled && this.setState({ mode: 'sell' })}>فروش</button>
                    <button style={this.state.isDisabled ? { backgroundColor: 'grey', cursor: 'not-allowed' } : { backgroundColor: '#f4b915' }} onClick={() => !this.state.isDisabled && this.setState({ mode: 'send' })}>ارسال</button>
                </div>

                <div className="form-container">
                    {/* Buy */}
                    {this.state.mode === 'buy' && <form className="form" id="buy-form" onSubmit={this.onSubmit}>
                        <p className="header">خرید طلای آب شده گرمی</p>
                        {this.state.buyError && <p className="error">{this.state.buyError}</p>}

                        <input type="number" name="amount" placeholder="مقدار (گرم)" value={this.state.buyAmount} onChange={e => this.setState({ buyAmount: e.target.value })} step="0.1" min="0" max="200" />

                        <p className="price">مبلغ قابل پرداخت: {this.calcTolerance(this.state.buyAmount, this.state.goldPrice, 'buy').toLocaleString('fa-IR')} تومان</p>

                        <button className="submit" style={{ backgroundColor: '#4baf01' }}>انتقال به درگاه</button>
                    </form>}

                    {/* Sell */}
                    {this.state.mode === 'sell' && <form className="form" id="sell-form" onSubmit={this.onSubmit}>
                        <p className="header">فروش طلای آب شده گرمی</p>
                        {this.state.sellError && <p className="error">{this.state.sellError}</p>}

                        <input type="number" name="amount" placeholder="مقدار (گرم)" value={this.state.sellAmount} onChange={e => this.setState({ sellAmount: e.target.value })} step="0.1" min="0" max="200" />

                        <p className="price">مبلغ قابل دریافت: {this.calcTolerance(this.state.sellAmount, this.state.goldPrice, 'sell').toLocaleString('fa-IR')} تومان</p>

                        <p className="error">
                            <span>مقدار گرم مجاز فروش: </span>
                            <span>{this.context.me.UserMeta.gold_wallet.toLocaleString('fa-IR', { useGrouping: false })} گرم </span>
                            <span
                                style={{ color: '#374ca3', textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => this.setState({ sellAmount: this.context.me.UserMeta.gold_wallet }, () => document.querySelector('#sell-form input[name=amount').value = this.context.me.UserMeta.gold_wallet)}
                            >
                                انتخاب
                            </span>
                        </p>
                        <p className="error">حداکثر گرم مجاز فروش در روز: ۲۰۰ گرم</p>
                        <p className="error">مقدار گرم مورد فروش شما پس از تایید فروش به اعتبار کیف پول شما واریز خواهد شد</p>

                        <button className="submit" style={{ backgroundColor: '#ff004e' }}>ثبت فروش</button>
                    </form>}

                    {/* Send */}
                    {this.state.mode === 'send' && <form className="form" id="send-form" onSubmit={this.onSubmit}>
                        <p className="header">ارسال موجودی کیف پول طلایی من</p>
                        {this.state.sendError && <p className="error">{this.state.sendError}</p>}

                        <input type="number" name="amount" placeholder="مقدار (گرم)" step="0.1" min="0" max="200" />

                        <p className="error">
                            <span>مقدار گرم مجاز ارسال: </span>
                            <span>{this.context.me.UserMeta.gold_wallet.toLocaleString('fa-IR', { useGrouping: false })} گرم </span>
                            <span
                                style={{ color: '#374ca3', textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => this.setState({ sellAmount: this.context.me.UserMeta.gold_wallet }, () => document.querySelector('#send-form input[name=amount').value = this.context.me.UserMeta.gold_wallet)}
                            >
                                انتخاب
                            </span>
                        </p>

                        <input type="text" name="postal_code" placeholder="کد پستی" />

                        <textarea name="address" rows="4" placeholder="آدرس"></textarea>

                        <button className="submit" style={{ backgroundColor: '#f4b915' }}>ثبت درخواست ارسال</button>
                    </form>}
                </div>

                <div className="table-container">
                    <p className="title">تاریخچه</p>

                    <table>
                        <thead>
                            <tr>
                                <th>مقدار (گرم)</th>
                                <th>مظنه خرید</th>
                                <th>مبلغ پرداختی</th>
                                <th>تاریخ</th>
                                <th>وضعیت</th>
                                <th>توضیحات</th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.state.history.map((r, i) =>
                                <tr key={i}>
                                    <td>{r.amount.toLocaleString('fa-IR', { useGrouping: false })}</td>
                                    <td>{r.fee.toLocaleString('fa-IR')}</td>
                                    <td>{r.price.toLocaleString('fa-IR')}</td>
                                    <td>{new Date(r.create_date).toLocaleString('fa-IR', { year: "numeric", month: "numeric", day: 'numeric' })}</td>
                                    <td>{this.getStatusText(r.status)}</td>
                                    <td>{r.status === 'SENT' ? `کد رهگیری ${r.tracking_number}` : r.fail_reason}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className="pagination">
                    {this.state.next && <p onClick={() => this.setState({ page: this.state.page + 1 }, () => this.getHistory())}>&laquo;</p>}
                    {this.state.last && <p onClick={() => this.setState({ page: this.state.page - 1 }, () => this.getHistory())}>&raquo;</p>}
                </div>
            </div>
        )
    }
}

GoldWallet.contextType = AuthContext

export default GoldWallet
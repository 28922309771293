import React from 'react'

export default function Loader({ isFailed, failedCallback }) {
    return (
        <div className="loader-container" style={{ height: '100vh' }}>
            <div className="dashboard-loading-container">
                {!isFailed ? <div className="loader" style={{ marginBottom: '250px' }}></div> :
                    <div className="error-container" style={{ marginBottom: '250px' }}>
                        <p>خطا در دریافت اطلاعات</p>
                        <p className="retry-btn" onClick={() => failedCallback()}>تلاش مجدد</p>
                    </div>
                }
            </div>
        </div>
    )
}

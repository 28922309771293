import React, { Component } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'

class TicketMessage extends Component {
    constructor(props) {
        super()
        this.state = {
            isLoading: true,
            id: props.id,
            messages: [],
            status: '',
            text: ''
        }
    }
    loadMessages() {
        this.setState({ isLoading: true }, async () => {
            await axios.get(`/api/ticket/${this.state.id}/one`)
                .then(r => {
                    const data = r.data

                    this.setState({ status: data.status })
                })
                .catch(e => {
                    this.props.setLoading({ isLoading: true, isFailed: true, errorFunc: () => this.loadMessages() })
                })

            axios.get(`/api/ticket/${this.state.id}/message`)
                .then(r => {
                    const data = r.data

                    this.setState({ isLoading: false, messages: data })
                })
                .catch(e => {
                    this.props.setLoading({ isLoading: true, isFailed: true, errorFunc: () => this.loadMessages() })
                })
        })
    }
    download(m, type) {
        this.setState({ isLoading: true }, async () => {
            try {
                const res = await axios.get(
                    `/api/ticket/${this.state.id}/resource?type=${type}&filename=${m.data}`, {
                    responseType: "arraybuffer"
                })

                const urlCreator = window.URL || window.webkitURL
                const file = new Blob([res.data], { type: m.data_type === 'Video' ? 'video/mp4' : 'image/png' })
                const fileURL = urlCreator.createObjectURL(file)
                this.setState({ isLoading: false }, () => this.props.setData({ t: type, url: fileURL }))
            }
            catch (e) {
                this.setState({ isLoading: false }, () => alert('خطا در دریافت فایل'))
            }
        })
    }
    sendFile(e) {
        const payload = new FormData()
        const file = e.target.files[0]

        if (file.type.startsWith('image/')) {
            if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
                alert('تنها فرمت های png ,jpg و jpeg مجاز می باشد')
                return
            }

            if (file.size > 5000000) {
                alert('حجم فایل بیشتر از 5MB می باشد')
                return
            }

            payload.append('image', file)
            payload.append('type', 'Image')
        }
        else if (file.type.startsWith('video/')) {
            if (file.type !== 'video/mp4') {
                alert('تنها فرمت mp4 مجاز می باشد')
                return
            }

            if (file.size > 50000000) {
                alert('حجم فایل بیشتر از 50MB می باشد')
                return
            }

            payload.append('video', file)
            payload.append('type', 'Video')
        }
        else {
            alert('نوع فایل نامعتبر می باشد')
            return
        }

        this.setState({ isLoading: true }, () => {
            axios.post(`/api/ticket/${this.state.id}/message`, payload, { headers: { "Content-Type": "multipart/form-data" } })
                .then(res => {
                    this.setState({ isLoading: false, messages: [res.data, ...this.state.messages] })
                })
                .catch(e => {
                    const data = e.response.data
                    const message = !data.message ? '' : Array.isArray(data.message) ? data.message[0] : data.message

                    alert(message)

                    this.setState({ isLoading: false })
                })
        })
    }
    sendText() {
        if (!this.state.text)
            return

        this.setState({ isLoading: true }, () => {
            axios.post(`/api/ticket/${this.state.id}/message`, { text: this.state.text, type: 'Text' })
                .then(res => {
                    this.setState({ isLoading: false, text: '', messages: [res.data, ...this.state.messages] })
                })
                .catch(e => {
                    const data = e.response.data
                    const message = !data.message ? '' : Array.isArray(data.message) ? data.message[0] : data.message

                    alert(message)

                    this.setState({ isLoading: false })
                })
        })
    }
    componentDidMount() {
        this.loadMessages()
    }
    render() {
        return (
            <div className="ticket-message-container">
                {this.state.isLoading ?
                    <div className="loader"></div> :
                    <>
                        <div className="messages">
                            {this.state.messages.map((m, i) =>
                                <div key={i} className="message" style={{ alignSelf: m.type === 'ADMIN' ? 'flex-start' : 'flex-end' }}>
                                    {
                                        m.data_type === 'Text' && <p style={{ whiteSpace: 'pre-line' }} className="text">{m.data}</p>
                                    }

                                    {(m.data_type === 'Video' || m.data_type === 'Image') &&
                                        <p
                                            className="text"
                                            onClick={() => this.download(m, m.data_type === 'Video' ? 'videos' : 'images')}
                                            style={{ direction: 'ltr', textDecoration: 'underline', cursor: 'pointer' }}
                                        >
                                            {m.data_type === 'Video' ? 'مشاهده ویدئو' : 'مشاهده عکس'}
                                        </p>
                                    }

                                    <p style={{ opacity: '0.4' }}>{new Date(m.create_date).toLocaleTimeString('fa-IR')}</p>
                                </div>
                            )}
                        </div>

                        {this.state.status === 'ACTIVE' && <div className="form">
                            <input placeholder="...متن پیام" value={this.state.text} onChange={e => this.setState({ text: e.target.value })} />

                            <svg viewBox="0 0 280.067 280.067" onClick={() => document.querySelector('input[name=file]').click()}>
                                <g>
                                    <path d="M149.823,257.142c-31.398,30.698-81.882,30.576-113.105-0.429 c-31.214-30.987-31.337-81.129-0.42-112.308l-0.026-0.018L149.841,31.615l14.203-14.098c23.522-23.356,61.65-23.356,85.172,0 s23.522,61.221,0,84.586l-125.19,123.02l-0.044-0.035c-15.428,14.771-40.018,14.666-55.262-0.394 c-15.244-15.069-15.34-39.361-0.394-54.588l-0.044-0.053l13.94-13.756l69.701-68.843l13.931,13.774l-83.632,82.599 c-7.701,7.596-7.701,19.926,0,27.53s20.188,7.604,27.88,0L235.02,87.987l-0.035-0.026l0.473-0.403 c15.682-15.568,15.682-40.823,0-56.39s-41.094-15.568-56.776,0l-0.42,0.473l-0.026-0.018l-14.194,14.089L50.466,158.485 c-23.522,23.356-23.522,61.221,0,84.577s61.659,23.356,85.163,0l99.375-98.675l14.194-14.089l14.194,14.089l-14.194,14.098 l-99.357,98.675C149.841,257.159,149.823,257.142,149.823,257.142z" />
                                </g>
                            </svg>

                            <input name="file" type="file" accept="image/jpg, image/jpeg, image/jpeg, video/mp4" style={{ display: 'none' }} onChange={e => this.sendFile(e)} />

                            <svg viewBox="0 0 122.56 122.88" onClick={() => this.sendText()}>
                                <path d="M2.33,44.58,117.33.37a3.63,3.63,0,0,1,5,4.56l-44,115.61h0a3.63,3.63,0,0,1-6.67.28L53.93,84.14,89.12,33.77,38.85,68.86,2.06,51.24a3.63,3.63,0,0,1,.27-6.66Z" />
                            </svg>
                        </div>}
                    </>
                }
            </div>
        )
    }
}

export default function HOC(props) {
    let { id } = useParams()
    return <TicketMessage {...props} id={id} />
}
import React, { Component } from 'react'

export default class NotFound extends Component {
    render() {
        return (
            <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {!this.props.m ?
                    <h2 style={{ fontSize: '25px', marginBottom: '200px' }}>Not Found | 404</h2> :
                    <h2 style={{ fontSize: '22px', marginBottom: '200px' }}>سایت در دست تعمیر می باشد</h2>
                }
            </div>
        )
    }
}

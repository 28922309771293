import React, { Component } from 'react'
import axios from 'axios'

let cachePortfosState = null

export default class Portfos extends Component {
    constructor() {
        super()
        this.state = {
            page: 0,
            next: false,
            last: false,
            portfos: [],
            deals: null,
            lastPath: window.location.pathname,
            ...cachePortfosState
        }
    }
    async getPortfos() {
        await this.props.setLoading({ isLoading: true, isFailed: false, errorFunc: null })

        axios.get(`/api/trade/room/1/portfo?page=${this.state.page}`)
            .then(r => {
                const data = r.data

                cachePortfosState = {
                    ...this.state,
                    next: data.next,
                    last: data.last,
                    portfos: data.data
                }

                this.props.setLoading({ isLoading: false, isFailed: false, errorFunc: null })
            })
            .catch(e => {
                this.props.setLoading({ isLoading: true, isFailed: true, errorFunc: () => this.getPortfos() })
            })
    }
    async getDeals(id) {
        await this.props.setLoading({ isLoading: true, isFailed: false, errorFunc: null })

        axios.get(`/api/trade/room/1/portfo/${id}/deal`)
            .then(r => {
                cachePortfosState = {
                    ...this.state,
                    deals: r.data
                }

                this.props.setLoading({ isLoading: false, isFailed: false, errorFunc: null })
            })
            .catch(e => {
                this.props.setLoading({ isLoading: true, isFailed: true, errorFunc: () => this.getDeals(id) })
            })
    }
    componentDidMount() {
        !cachePortfosState && this.getPortfos()
    }
    componentDidUpdate() {
        if (document.referrer.endsWith('/room') && this.state.lastPath !== document.referrer) {
            cachePortfosState = null
            this.getPortfos()
        }
    }
    render() {
        return (
            <div className="portfos-container">
                <div className="table-container">
                    <table>
                        <thead>
                            {!this.state.deals ?
                                <tr>
                                    <th>شماره</th>
                                    <th>مبلغ باز شدن</th>
                                    <th>مبلغ اضافه شده</th>
                                    <th>مبلغ بسته شدن</th>
                                    <th>ساعت و تاریخ</th>
                                    <th>سود/ضرر - کمیسیون</th>
                                    <th></th>
                                </tr>
                                :
                                <tr>
                                    <th>شماره</th>
                                    <th>مبلغ ورودی</th>
                                    <th>واحد</th>
                                    <th>نوع</th>
                                    <th>ساعت</th>
                                    <th>مبلغ خروجی</th>
                                    <th>قیمت حراج</th>
                                    <th>حد سود / حد ضرر</th>
                                    <th>سود / زیان</th>
                                </tr>
                            }
                        </thead>

                        <tbody>
                            {!this.state.deals ? this.state.portfos.map((p, i) =>
                                <tr key={i}>
                                    <td>{p.id.replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d])}</td>
                                    <td>{p.entry_amount.toLocaleString('fa-IR')} تومان</td>
                                    <td>{p.added_amount.toLocaleString('fa-IR')} تومان</td>
                                    <td>{p.closed_amount.toLocaleString('fa-IR')} تومان</td>
                                    <td>
                                        {new Date(p.create_date).toLocaleString('fa-IR', { hour: "numeric", minute: "numeric", second: 'numeric' })} - {new Date(p.create_date).toLocaleString('fa-IR', { year: "numeric", month: "numeric", day: 'numeric' })}
                                    </td>
                                    <td>{(p.profit_loss - p.commission).toLocaleString('fa-IR')} تومان</td>
                                    <td onClick={() => this.getDeals(p.id)} className="deals-btn">معاملات</td>
                                </tr>
                            ) :
                                this.state.deals.reverse().map((d, i) =>
                                    <tr key={i}>
                                        <td>{d.number.toLocaleString('fa-IR')}</td>
                                        <td>{d.entry_fee.toLocaleString('fa-IR')} تومان</td>
                                        <td>{d.amount.toLocaleString('fa-IR', { useGrouping: false })}</td>
                                        <td style={{ color: d.type === 'BUY' ? 'green' : 'red' }}>{d.type === 'BUY' ? 'خرید' : 'فروش'}</td>
                                        <td>{new Date(d.create_date).toLocaleString('fa-IR', { hour: "numeric", minute: "numeric", second: 'numeric' })}</td>
                                        <td>{d.close_fee.toLocaleString('fa-IR')} تومان</td>
                                        <td>{d.margin.toLocaleString('fa-IR')}</td>

                                        <td>
                                            {!d.tp ? '-' : d.tp.toLocaleString('fa-IR')} / {!d.sl ? '-' : d.sl.toLocaleString('fa-IR')}
                                        </td>

                                        <td style={{ color: d.profit_loss > 0 ? 'green' : d.profit_loss < 0 ? 'red' : 'var(--fg-color)' }}>
                                            {d.profit_loss.toLocaleString('fa-IR')} تومان
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>

                <div className="pagination">
                    {(this.state.next && !this.state.deals) && <p onClick={() => this.setState({ page: this.state.page + 1 }, () => this.getPortfos())}>&laquo;</p>}
                    {(this.state.last && !this.state.deals) && <p onClick={() => this.setState({ page: this.state.page - 1 }, () => this.getPortfos())}>&raquo;</p>}
                    {this.state.deals && <p onClick={() => this.setState({ deals: null })}>➤</p>}
                </div>
            </div>
        )
    }
}

import React, { Component } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

class Menu extends Component {
    constructor(props) {
        super()
        this.state = {
            showMenu: false,
            isMobile: document.documentElement.clientWidth <= 800,
            items: !props.items ? [] : props.items
        }
    }
    closeMenu() {
        this.setState({ showMenu: false })
    }
    componentDidMount() {
        window.onresize = e => {
            const w = e.target.innerWidth
            this.setState({ isMobile: w <= 800 })
        }
    }
    render() {
        const l = this.props.location.pathname
        return (
            <div className="menu-container">
                {this.state.isMobile && <span className="hamberger-btn" onClick={() => this.setState({ showMenu: !this.state.showMenu })}>{!this.props.isCategory ? '☰' : 'دسته بندی <'}</span>}
                {(!this.state.isMobile || (this.state.isMobile && this.state.showMenu)) && <>
                    {this.state.items.map((item, i) =>
                        item.name === 'داشبورد' ? <NavLink key={i} to={'/dashboard'} className={(l === '/dashboard' || l === '/dashboard/') ? "active" : ''} onClick={() => this.closeMenu()} end>داشبورد</NavLink> :
                            item.name === 'خروج' ? <a key={i} href="#logout" onClick={() => this.props.setLogout()}>خروج</a> :
                                <NavLink
                                    key={i}
                                    to={item.path}
                                    onClick={() => this.closeMenu()}
                                    className={item.name === decodeURI(this.props.location.pathname.replace(/\/$/g, '').split('/').pop()) ? 'active' : ''}
                                >
                                    {item.name}
                                </NavLink>
                    )}
                </>}
            </div>
        )
    }
}

export default function HOC(props) {
    return <Menu {...props} location={useLocation()} />
}
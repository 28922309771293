import React, { Component } from 'react'

export default class Navbar extends Component {
    render() {
        return (
            <div className="room-navbar-container">
                <p onClick={() => window.location = '/dashboard'}>داشبورد</p>
                <p
                    className={this.props.current === 'chart' ? 'active' : ''}
                    onClick={() => this.props.changeState({ nav: 'chart' })}
                >
                    نمودار
                </p>
                <p
                    className={this.props.current === 'trade' ? 'active' : ''}
                    onClick={() => this.props.changeState({ nav: 'trade' })}
                >
                    معاملات
                </p>
            </div>
        )
    }
}

class ActionLimiter {
    constructor(maxActions, timeWindow, storageKey) {
        this.maxActions = maxActions;
        this.timeWindow = timeWindow;
        this.storageKey = storageKey;
        this.actions = this.loadActions();
    }

    loadActions() {
        const storedActions = localStorage.getItem(this.storageKey);
        return storedActions ? JSON.parse(storedActions) : [];
    }

    saveActions() {
        localStorage.setItem(this.storageKey, JSON.stringify(this.actions));
    }

    canPerformAction() {
        const now = Date.now();

        this.actions = this.actions.filter(timestamp => now - timestamp < this.timeWindow);

        if (this.actions.length < this.maxActions) {
            this.actions.push(now); // Record the current action timestamp
            this.saveActions(); // Save updated actions to localStorage
            return true;
        } else {
            return false;
        }
    }
}

export default ActionLimiter;

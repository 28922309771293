import { useState } from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from "react-router-dom"
import axios from "axios";
import AuthContext from './AuthContext';
import Footer from "./Footer";
import Navbar from "./Navbar";
import Auth from './pages/Auth';
import Dashboard from './pages/Dashboard';
import Room from './pages/Room';
import NotFound from './pages/404';
import Terms from './pages/general/terms';
import Guide from './pages/general/guide';
import Complain from './pages/general/complain';
import FAQ from './pages/general/faq';
import Blog from './pages/general/blog';
import PaymentForm from './pages/paymentForm';
import Shop from './pages/general/shop';
import Home from './pages/general/home';

function App() {
  const [token, changeToken] = useState(localStorage.getItem('token'))
  const [me, changeMe] = useState({})

  // axios.defaults.baseURL = 'http://192.168.100.5:3000'
  if (token) {
    axios.defaults.headers.common['Authorization'] = token
    axios.defaults.headers.common['uid'] = localStorage.getItem('uid')?.at(-1)
    axios.defaults.headers.head['uid'] = localStorage.getItem('uid')?.at(-1)
  }else {
    const rid = Math.floor(10000 + Math.random() * 90000)
    axios.defaults.headers.common['uid'] = rid.toString().at(-1)
    axios.defaults.headers.head['uid'] = rid.toString().at(-1)
  }

  return (
    <div className="App">
      <AuthContext.Provider value={{ token, changeToken, me, changeMe }}>
        <Router>
          {!window.location.pathname.endsWith('/maintenance') && <Navbar />}

          <Routes>
            <Route path={'/'} element={<Home />} exact={true} />
            <Route path={'/auth/login'} element={<Auth />} />
            <Route path={'/auth/preSignup'} element={<Auth />} />
            {/*<Route path={'/auth/signup'} element={<Auth />} />*/}
            <Route path={'/auth/resetpassword'} element={<Auth />} />
            <Route path={'/auth'} element={<Navigate to="/auth/login" />} />
            <Route path={'/dashboard/*'} element={<Dashboard />} />
            <Route path={'/room'} element={<Room />} />
            <Route path={'/complaint'} element={<Complain />} />
            <Route path={'/guide'} element={<Guide />} />
            <Route path={'/privacy_policy'} element={<Terms />} />
            <Route path={'/faq'} element={<FAQ />} />

            <Route path={'/blog'} element={<Blog />} />
            {/* <Route path={'/blog/post/:slug'} element={<Blog />} /> */}
            <Route path={'/blog/category/:slug'} element={<Blog />} />

            <Route path={'/shop'} element={<Shop />} />
            <Route path={'/shop/track'} element={<Shop track={true} />} />
            <Route path={'/shop/result'} element={<Shop result={true} />} />
            {/* <Route path={'/blog/post/:slug'} element={<Blog />} /> */}
            <Route path={'/shop/category/:slug'} element={<Shop />} />

            <Route path={'/maintenance'} element={<NotFound m={true} />} />
            <Route path={'/payment/:token'} element={<PaymentForm />} />
            <Route path={'*'} element={<NotFound />} />
          </Routes>

          {!window.location.pathname.endsWith('/maintenance') && <Footer />}
        </Router>
      </AuthContext.Provider>
    </div>
  );
}

export default App;

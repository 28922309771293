import React, { Component } from 'react'
import Loader from './loader'
import axios from 'axios'
import Mage from '../../images/Complaint.png'

export default class Complain extends Component {
    constructor() {
        super()
        this.state = {
            isLoading: false,
            isFailed: false,
            isSuccess: false,
            captchaFailed: false,
            captchaImage: null,
            error: '',
            name: '',
            mobile: '',
            email: '',
            subject: '',
            body: '',
            captcha: ''
        }

        this.onSubmit = this.onSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
    }
    onChange(e) {
        const name = e.target.name
        const value = e.target.value

        this.setState({ [name]: value })
    }
    getCaptcha() {
        this.setState({ captchaFailed: false }, async () => {
            const req = await fetch('/api/auth/generate_captcha', { method: 'GET' })

            if (req.status !== 200) {
                this.setState({ captchaFailed: true, error: 'خطا در دریافت کپتچا' })
                return
            }

            const c = await req.json()
            this.setState({ captchaImage: c.data })
        })
    }
    onSubmit(e) {
        e.preventDefault()

        this.setState({ isLoading: true, isFailed: false, isSuccess: false, error: '' }, async () => {
            await axios.post('/api/complaint', this.state)
                .then(res => {
                    this.setState({
                        isLoading: false,
                        isSuccess: true,
                        name: '',
                        mobile: '',
                        email: '',
                        subject: '',
                        body: ''
                    })
                })
                .catch(e => {
                    const data = e.response.data
                    const message = !data.message ? '' : Array.isArray(data.message) ? data.message[0] : data.message

                    this.setState({ isLoading: false, error: message })
                })

            this.getCaptcha()
        })
    }
    componentDidMount() {
        this.getCaptcha()

        const root = document.documentElement
        root.style.setProperty('--bg-color', 'white')
        root.style.setProperty('--fg-color', 'black')
    }
    render() {
        if (this.state.isLoading)
            return <Loader isFailed={this.state.isFailed} failedCallback={() => this.onSubmit()} />
        else
            return (
                <div className="general-container complain-container general-data">
                    <div className="data">
                        <p className="title">برای ثبت شکایات خود از کادر زیر می توانید اقدام فرمایید</p>

                        <form className="form" onSubmit={this.onSubmit}>
                            {this.state.error && <p className="error">{this.state.error}</p>}
                            {this.state.isSuccess && <p className="error" style={{ color: 'green' }}>شکایت شما با موفقیت ارسال شد</p>}

                            <input type="text" name="name" value={this.state.name} onChange={this.onChange} placeholder="نام و نام خانوادگی" maxLength="128" />

                            <input type="text" name="mobile" value={this.state.mobile} onChange={this.onChange} placeholder="شماره موبایل" maxLength="11" onInput={e => e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')} />

                            <input type="text" name="email" value={this.state.email} onChange={this.onChange} placeholder="آدرس ایمیل" maxLength="128" />

                            <input type="text" name="subject" value={this.state.subject} onChange={this.onChange} placeholder="موضوع شکایت" maxLength="128" />

                            <textarea name="body" value={this.state.body} onChange={this.onChange} placeholder="متن شکایت" maxLength="1024"></textarea>

                            <div onClick={() => this.getCaptcha()} className="captcha"
                                dangerouslySetInnerHTML={{ __html: this.state.captchaImage }}>
                            </div>
                            <div onClick={() => this.getCaptcha()} className="captcha" style={{ marginTop: 10 }}
                                dangerouslySetInnerHTML={{
                                    __html: `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="25" height="25" enable-background="new 0 0 65 65" viewBox="0 0 65 65">
                                <g fill="#555753">
                                    <path d="m32.5 4.999c-5.405 0-10.444 1.577-14.699 4.282l-5.75-5.75v16.11h16.11l-6.395-6.395c3.18-1.787 6.834-2.82 10.734-2.82 12.171 0 22.073 9.902 22.073 22.074 0 2.899-0.577 5.664-1.599 8.202l4.738 2.762c1.47-3.363 2.288-7.068 2.288-10.964 0-15.164-12.337-27.501-27.5-27.501z"/>
                                    <path d="m43.227 51.746c-3.179 1.786-6.826 2.827-10.726 2.827-12.171 0-22.073-9.902-22.073-22.073 0-2.739 0.524-5.35 1.439-7.771l-4.731-2.851c-1.375 3.271-2.136 6.858-2.136 10.622 0 15.164 12.336 27.5 27.5 27.5 5.406 0 10.434-1.584 14.691-4.289l5.758 5.759v-16.112h-16.111l6.389 6.388z"/>
                                </g>
                            </svg>` }}>
                            </div>

                            <input type="text" name="captcha" value={this.state.captcha} onChange={this.onChange} placeholder="کد کپتچا" maxLength="4" />

                            <button className="submit">ثبت شکایت</button>
                        </form>
                    </div>

                    <img className="mage" src={Mage} alt="mage" />
                </div>
            )
    }
}

import React, { Component } from 'react'

export default class Portfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: props.portfo === false,
            havePortfo: props.portfo !== null,
            portfo: props.portfo
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.portfo !== this.props.portfo)
            this.setState({
                isLoading: this.props.portfo === false,
                havePortfo: this.props.portfo !== null,
                portfo: this.props.portfo
            })
    }
    render() {
        return (
            <div className="portfo-container">
                {this.state.isLoading ? <div className="loader"></div> : !this.state.havePortfo ?
                    <div className="new">
                        <p>قبل از انجام معامله باید پرتفو بسازید. <span onClick={() => this.props.changeState({ modal: 'portfo', portfo: null })}>ساخت پرتفو</span></p>
                    </div> :
                    <div className="data-container">
                        <div className="type"><p>- {this.state.portfo.type} -</p></div>

                        <div className="data">
                            <div className="wrapper">
                                <p>
                                    <span>وجه تضمین (تومان)</span>
                                    <span className="price" onClick={() => this.props.changeState({ modal: 'portfo', portfo: this.state.portfo })}>
                                        <svg viewBox="0 0 64 64" className="settings-gear">
                                            <g><path d="M62.124,27.008l-7.916-.989a22.814,22.814,0,0,0-2.275-5.493l4.9-6.3a1,1,0,0,0-.083-1.321L51.091,7.252A1,1,0,0,0,49.77,7.17l-6.3,4.9A22.825,22.825,0,0,0,37.982,9.8l-.99-7.921A1,1,0,0,0,36,1H28a1,1,0,0,0-.992.876L26.018,9.8a22.713,22.713,0,0,0-5.491,2.275l-6.3-4.9a1,1,0,0,0-1.321.082L7.252,12.909a1,1,0,0,0-.083,1.321l4.9,6.3A22.849,22.849,0,0,0,9.8,26.019l-7.921.989A1,1,0,0,0,1,28v8a1,1,0,0,0,.876.992l7.921.989a22.811,22.811,0,0,0,2.274,5.493l-4.9,6.3a1,1,0,0,0,.082,1.321l5.657,5.657a1,1,0,0,0,1.321.082l6.3-4.9a22.837,22.837,0,0,0,5.491,2.274l.99,7.917A1,1,0,0,0,28,63h8a1,1,0,0,0,.992-.876l.989-7.916a22.757,22.757,0,0,0,5.493-2.275l6.3,4.9a1,1,0,0,0,1.321-.082l5.657-5.657a1,1,0,0,0,.083-1.321l-4.9-6.3a22.844,22.844,0,0,0,2.274-5.493l7.917-.989A1,1,0,0,0,63,36V28A1,1,0,0,0,62.124,27.008ZM32,43A11,11,0,1,1,43,32,11,11,0,0,1,32,43Z"></path></g>
                                        </svg>
                                        {(this.state.portfo.entry_amount + this.state.portfo.added_amount + this.state.portfo.profit_loss - this.state.portfo.commission).toLocaleString('fa-IR')}
                                    </span>
                                </p>

                                <div className="line"></div>

                                <p>
                                    <span>سود / ضرر (تومان)</span>
                                    <span>{this.state.portfo.profit_loss.toLocaleString('fa-IR')}</span>
                                </p>

                                <div className="line"></div>

                                <p>
                                    <span>واحد مجاز خرید: {this.state.portfo.buy_unit < 0 ? Number(0).toLocaleString('fa-IR') : this.state.portfo.buy_unit.toLocaleString('fa-IR', { useGrouping: false })}</span>
                                    <span>واحد مجاز فروش: {this.state.portfo.sell_unit < 0 ? Number(0).toLocaleString('fa-IR') : this.state.portfo.sell_unit.toLocaleString('fa-IR', { useGrouping: false })}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

import React, { Component } from 'react'
import axios from 'axios'

let cacheTransactionsState = null

export default class Transactions extends Component {
  constructor(props) {
    super()
    this.state = {
      type: '',
      page: 0,
      next: false,
      last: false,
      transactions: [],
      ...cacheTransactionsState
    }
    props.setLoading({ isLoading: !cacheTransactionsState, isFailed: false, errorFunc: null })
  }
  async getTransactions() {
    await this.props.setLoading({ isLoading: true, isFailed: false, errorFunc: null })

    axios.get(`/api/transaction?page=${this.state.page}&type=${this.state.type}`)
      .then(r => {
        const data = r.data

        cacheTransactionsState = {
          ...this.state,
          next: data.next,
          last: data.last,
          transactions: data.data
        }

        this.props.setLoading({ isLoading: false, isFailed: false, errorFunc: null })
      })
      .catch(e => {
        this.props.setLoading({ isLoading: true, isFailed: true, errorFunc: () => this.getTransactions() })
      })
  }
  subTypeText(type) {
    let text = ''

    switch (type) {
      case 'AdminIn':
      case 'AdminOut':
        text = 'ادمین'
        break

      case 'Gateway':
        text = 'درگاه'
        break

      case 'CardToCard':
        text = 'کارت به کارت'
        break

      case 'Referral':
        text = 'معرفی'
        break

      case 'GoldWallet':
        text = 'کیف طلایی'
        break

      case 'PortfoSettle':
      case 'PortfoDeposite':
        text = 'پرتفو'
        break

      case 'Commission':
        text = 'کمیسیون'
        break

      case 'SettleRequest':
        text = 'تسویه'
        break

      case 'SystemIn':
      case 'SystemOut':
        text = 'سیستم'
        break

      default:
        text = ''
    }

    return text
  }
  statusText(t) {
    let text = ''

    if (t.sub_type === 'Gateway') {
      if (t.payment_status === 'SUCCESS')
        text = 'انجام شده'
      else if (t.payment_status === 'WAIT')
        text = 'در انتظار پرداخت'
      else if (t.payment_status === 'CANCELLED')
        text = 'لغو شده'
      else if (t.payment_status === 'EXPIRED')
        text = 'منقضی شده'
    }
    else {
      if (t.status === 'SUCCESS')
        text = 'انجام شده'
      else if (t.status === 'WAIT')
        text = 'در حال بررسی'
      else if (t.status === 'FAILED')
        text = 'ناموفق'
    }

    return text
  }
  statusTextColor(status) {
    let color = ''

    switch (status) {
      case 'SUCCESS':
        color = 'green'
        break

      case 'WAIT':
        color = '#FFD700'
        break

      case 'CANCELLED':
        color = 'red'
        break

      case 'EXPIRED':
        color = 'red'
        break

      case 'FAILED':
        color = 'red'
        break

      default:
        break
    }

    return color
  }
  componentDidMount() {
    !cacheTransactionsState && this.getTransactions()
  }
  render() {
    return (
      <div className="transaction-container">
        <select onChange={e => this.setState({ page: 0, type: e.target.value }, () => this.getTransactions())} defaultValue={this.state.type}>
          <option value="">همه</option>
          <option value="Gateway">درگاه</option>
          <option value="CardToCard">کارت به کارت</option>
          <option value="Referral">معرفی</option>
          <option value="PortfoSettle">پرتفو - واریز</option>
          <option value="PortfoDeposite">پرتفو - برداشت</option>
          <option value="Commission">کمیسیون</option>
          <option value="SettleRequest">درخواست تسویه</option>
          <option value="AdminIn">ادمین - واریز</option>
          <option value="AdminOut">ادمین - برداشت</option>
          <option value="SystemIn">سیستم - واریز</option>
          <option value="SystemOut">سیستم - برداشت</option>
        </select>

        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>مبلغ</th>
                <th>موجودی</th>
                <th>نوع</th>
                <th>زیر نوع</th>
                <th>تاریخ</th>
                <th>وضعیت</th>
                <th>توضیحات</th>
              </tr>
            </thead>

            <tbody>
              {this.state.transactions.map((t, i) =>
                <tr key={i}>
                  <td>{parseInt(t.price).toLocaleString('fa-IR')}</td>
                  <td>{parseInt(t.wallet).toLocaleString('fa-IR')}</td>
                  <td style={t.type === 'Income' ? { color: 'green' } : { color: 'red' }}>{t.type === 'Income' ? 'واریز' : 'برداشت'}</td>
                  <td>{this.subTypeText(t.sub_type)}</td>
                  <td>{new Date(t.create_date).toLocaleString('fa-IR', { year: "numeric", month: "numeric", day: 'numeric' })}</td>
                  <td style={{ color: this.statusTextColor(t.sub_type === 'Gateway' ? t.payment_status : t.status) }}>{this.statusText(t)}</td>
                  <td>{!t.fail_reason ? t.description : t.fail_reason}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="pagination">
          {this.state.next && <p onClick={() => this.setState({ page: this.state.page + 1 }, () => this.getTransactions())}>&laquo;</p>}
          {this.state.last && <p onClick={() => this.setState({ page: this.state.page - 1 }, () => this.getTransactions())}>&raquo;</p>}
        </div>
      </div>
    )
  }
}

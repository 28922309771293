import React, { Component } from 'react'
import TradingViewWidget from 'react-tradingview-widget'
import { widget } from '../../assets/static/charting_library'
import { ErrorBoundary } from 'react-error-boundary'

export default class Chart extends Component {
    tvWidget = null
    lastIndex = 0
    resolution = 0
    supported_resolutions = ['1', '5', '15', '30', '1H', '4H', 'D', 'W', 'M']

    constructor() {
        super()
        this.state = {
            candles: [],
            main: true
        }
    }
    getResolutionIndex(resolution) {
        switch (resolution) {
            case '1':
                return 1

            case '5':
                return 2

            case '15':
                return 3

            case '30':
                return 4

            case '60':
                return 5

            case '240':
                return 6

            case '1D':
                return 7

            default:
                break
        }
    }
    datafeed() {
        return {
            onReady: (callback) => {
                setTimeout(() => callback({
                    supported_resolutions: this.supported_resolutions,
                    symbols_types: [{
                        name: 'Geram18',
                        value: 'geram18'
                    }]
                }), 0)
            },
            searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
                setTimeout(() => onResultReadyCallback({}), 0)
            },
            resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
                setTimeout(() => onSymbolResolvedCallback({
                    ticker: 'Geram18',
                    name: 'آبشده جهانی',
                    type: 'geram18',
                    session: '24x7',
                    timezone: 'Asia/Tehran',
                    minmov: 1,
                    pricescale: 100,
                    has_intraday: true,
                    has_no_volume: true,
                    has_weekly_and_monthly: false,
                    supported_resolutions: this.supported_resolutions
                }), 0)
            },
            getBars: (symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) => {
                if (firstDataRequest) {
                    this.lastIndex = 0
                    this.resolution = this.getResolutionIndex(resolution)
                }

                this.props.socket.on('chart/' + this.resolution, async data => {
                    this.props.socket.off('chart/' + this.resolution)

                    if (data.index === null)
                        onHistoryCallback([], { noData: true })
                    else {
                        this.lastIndex = data.index

                        let candles = []
                        await Promise.all(data.candles.map((candle, i) => {
                            candles.push({
                                time: candle.timestamp * 1000,
                                open: candle.entry_price,
                                high: candle.max_price,
                                low: candle.min_price,
                                close: candle.close_price
                            })
                            if (i === data.candles.length - 1)
                                onHistoryCallback(candles.reverse())

                            return candle
                        }))
                    }
                })

                this.props.socket.emit('chart', {
                    index: this.lastIndex,
                    resolution: this.resolution
                })
            },
            subscribeBars: (symbolInfo, resolution, onRealtimeCallback) => {
                this.resolution = this.getResolutionIndex(resolution)

                this.props.changeState({
                    barHandler: {
                        resolution: this.resolution,
                        callback: bar => onRealtimeCallback(bar)
                    }
                })
            },
            unsubscribeBars: (subscriberUID) => {
                // console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID)
            }
        }
    }
    mountChart() {
        const widgetOptions = {
            symbol: 'Geram18',
            datafeed: this.datafeed(),
            container_id: 'chart-container',
            interval: 'D',
            library_path: '/static/charting_library/',
            charts_storage_url: 'https://chart.keivangold.com',
            charts_storage_api_version: '1.1',
		    client_id: 'keivangold.com',
            user_id: localStorage.getItem('tv_username'),
            load_last_chart: true,
            auto_save: true,
            auto_save_delay: 10,
            //saved_data: this.load(this.state),
            disabled_features: ["use_localstorage_for_settings"],
            timezone: 'Asia/Tehran',
            locale: 'en',
            //hide_symbol_search: true,
            fullscreen: false,
            autosize: true,
            overrides: localStorage.getItem('theme') === 'DARK' ? {
                "paneProperties.background": "#2b2a34",
                "paneProperties.vertGridProperties.color": "#454545",
                "paneProperties.horzGridProperties.color": "#454545",
                "scalesProperties.textColor": "#AAA"
            } : {},
            custom_css_url: localStorage.getItem('theme') === 'DARK' ? '/static/charting_library/themed.css' : ''
        }

        this.tvWidget = new widget(widgetOptions)
    }
    componentDidMount() {
        this.mountChart()
    }
    componentWillUnmount() {
        try {
            if (this.tvWidget !== null) {
                this.tvWidget.remove()
                this.tvWidget = null
            }
        }
        catch (e) { }
    }
    render() {
        return (
            <div className="chart-container">
                <div className="nav">
                    <p onClick={() => this.setState({ main: true })} style={this.state.main ? { backgroundColor: 'rgba(0,0,0,0.4)' } : {}}>آبشده جهانی</p>
                    <p onClick={() => this.setState({ main: false })} style={!this.state.main ? { backgroundColor: 'rgba(0,0,0,0.4)' } : {}}>انس جهانی</p>
                </div>

                <div id="chart-container" style={this.state.main ? { width: '100%', height: '100%', zIndex: 2 } : { visibility: 'hidden' }}></div>

                <ErrorBoundary FallbackComponent={() => <p className="chart-failed">خطا در بارگذاری نمودار</p>}>
                    <TradingViewWidget
                        autosize={true}
                        fullscreen={false}
                        symbol="FOREXCOM:XAUUSD"
                        interval="D"
                        timezone="Asia/Tehran"
                        locale="en"
                        toolbar_bg="#f1f3f6"
                        enable_publishing={false}
                        hide_legend={true}
                        theme={localStorage.getItem('theme')}
                        withdateranges={true}
                        hide_side_toolbar={false}
                    />
                </ErrorBoundary>
            </div>
        )
    }
}

import React, { Component } from 'react'
import { Chart as ChartJS, PieController, ArcElement, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import axios from 'axios'
import NotiClose from '../../../images/noti_cancel.png'

ChartJS.register(PieController, ArcElement, Legend)

ChartJS.defaults.font.size = 16
ChartJS.defaults.font.family = "IranSans"

let homeInterval = null

export default class Home extends Component {
    constructor() {
        super()
        this.state = {
            goldPrice: {
                "Geram18": "-",
                "NewCoin": "-",
                "OldCoin": "-",
                "HalfCoin": "-",
                "QuarterCoin": "-",
                "GeramCoin": "-"
            },
            chart: {
                profit: 0,
                loss: 0,
                settle: 0
            },
            deal: '',
            profit_loss: 0,
            open_deals_count: 0,
            isLoading: true,
            showNotification: true,
            chartUpdate: false,
            disableStatistic: false,
            todayPortfos: [],
            latestDeals: [],
            system_notification: ''
        }
    }
    async syncGoldPrice() {
        await axios.get('/api/price')
            .then(async r => {
                const data = r.data

                await Promise.all(Object.keys(data).map(key => {
                    data[key] = parseInt(data[key]).toLocaleString('fa-IR')
                    return key
                }))

                this.setState({ goldPrice: { ...this.state.goldPrice, ...data } })
            })
            .catch(console.log)
    }
    async syncOpenDeals() {
        await axios.get('/api/trade/room/1/deal/open')
            .then(r => {
                const { profit_loss, type, count } = r.data

                this.setState({
                    type,
                    profit_loss,
                    open_deals_count: count
                })
            })
            .catch(console.log)
    }
    getStatistic(type = 'day') {
        return new Promise(async (resolve, reject) => {
            const sendStatisticRequest = () => axios.get('/api/user/statistic?type=' + type)
                .then(r => {
                    const data = r.data

                    this.setState({
                        chart: {
                            profit: !data.profit ? 0 : data.profit,
                            loss: !data.loss ? 0 : data.loss,
                            settle: !data.settle ? 0 : data.settle
                        },
                        chartUpdate: true
                    }, () => this.setState({ chartUpdate: false }))
                })
                .catch(console.log)

            this.setState({ disableStatistic: true }, async () => {
                await sendStatisticRequest()

                this.setState({ disableStatistic: false })
            })

            resolve()
        })
    }
    async getTodayPortfos() {
        await axios.get('/api/trade/room/1/portfo/today')
            .then(r => {
                const data = r.data

                this.setState({
                    todayPortfos: data.portfos,
                    latestDeals: data.deals
                })
            })
            .catch(console.log)
    }
    async getLastNotification() {
        await axios.get('/api/notification?type=system')
            .then(r => {
                const data = Object.values(r.data)

                if (data.length)
                    this.setState({
                        system_notification: data.reverse()[0].body
                    }, () => this.scrollNotification())
            })
            .catch(console.log)
    }
    scrollNotification() {
        setInterval(() => {
            try {
                const elem = document.querySelector('.system_notification .text')
                elem.scrollBy(elem.scrollLeft - 100, 0)
            }
            catch (e) { }
        }, 50)
    }
    async componentDidMount() {
        await this.getStatistic()
        await this.syncOpenDeals()
        await this.syncGoldPrice()
        await this.getTodayPortfos()
        await this.getLastNotification()

        this.setState({ isLoading: false })

        homeInterval && clearInterval(homeInterval)
        homeInterval = setInterval(() => { this.syncGoldPrice(); this.syncOpenDeals() }, 60000)
    }
    componentWillUnmount() {
        homeInterval && clearInterval(homeInterval)
    }
    render() {
        return (
            <div className={(this.state.isLoading ? 'load ' : '') + "home-container"} style={(this.state.system_notification && this.state.showNotification) ? { paddingTop: '60px' } : {}}>
                {(this.state.system_notification && this.state.showNotification) &&
                    <div className="system_notification">
                        <div className="close" onClick={() => this.setState({ showNotification: false })}>
                            <img src={NotiClose} alt="close" />
                        </div>

                        <div className="text">
                            <p>{this.state.system_notification}</p>
                        </div>
                    </div>
                }

                <div className="history-container">
                    <div className="hc">
                        <div className="loss-profit">
                            <p className="title">سود/ضرر لحظه ای:</p>
                            <p className="value">{!this.state.profit_loss ? '-' : (this.state.profit_loss.toLocaleString('fa-IR') + ' تومان')}</p>
                        </div>

                        <div className="open-deals">
                            <p className="title">تعداد معامله باز:</p>
                            <p className="value">{!this.state.open_deals_count ? '-' : (this.state.open_deals_count.toLocaleString('fa-IR', { useGrouping: false }) + ` واحد ${this.state.type === 'BUY' ? 'خرید' : 'فروش'} باز`)}</p>
                        </div>
                    </div>

                    <div className="rt">
                        <div className="recent-deals">
                            <p className="title">معاملات اخیر</p>

                            <div className="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>واحد</th>
                                            <th>مبلغ ورودی</th>
                                            <th>نوع</th>
                                            <th>مبلغ خروجی</th>
                                            <th>سود/زیان</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.latestDeals.map((d, i) =>
                                            <tr key={i}>
                                                <td>{d.amount.toLocaleString('fa-IR', { useGrouping: false })}</td>
                                                <td>{d.entry_fee.toLocaleString('fa-IR')}</td>
                                                <td style={{ color: d.type === 'BUY' ? 'green' : 'red' }}>{d.type === 'BUY' ? 'خرید' : 'فروش'}</td>
                                                <td>{d.close_fee.toLocaleString('fa-IR')}</td>
                                                <td style={{ color: d.profit_loss > 0 ? 'green' : d.profit_loss < 0 ? 'red' : 'var(--fg-color)' }}>{d.profit_loss.toLocaleString('fa-IR')} تومان</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="today-portfo">
                            <p className="title">پرتفوهای امروز</p>

                            <div className="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>مبلغ ورودی</th>
                                            <th>تعداد معاملات</th>
                                            <th>سود/زیان</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.todayPortfos.map(p =>
                                            <tr key={p.id}>
                                                <td>{p.entry_amount.toLocaleString('fa-IR')} تومان</td>
                                                <td>{p.deal_count.toLocaleString('fa-IR', { useGrouping: false })}</td>
                                                <td style={{ color: p.profit_loss > 0 ? 'green' : p.profit_loss < 0 ? 'red' : 'var(--fg-color)' }}>{p.profit_loss.toLocaleString('fa-IR')} تومان</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="chart-container">
                    <select onChange={e => this.getStatistic(e.target.value)} defaultValue="day" disabled={this.state.disableStatistic}>
                        <option value="day">نگاهی به عملکرد امروز</option>
                        <option value="week">نگاهی به یک هفته گذشته </option>
                        <option value="month">نگاهی به یک ماه گذشته</option>
                    </select>

                    <Pie
                        id="chart"
                        redraw={this.state.chartUpdate}
                        data={{
                            labels: ['سود', 'ضرر', 'برداشت'],
                            datasets: [
                                {
                                    backgroundColor: ["#47bfbd", "#f64649", "#feb45d", "grey"],
                                    borderWidth: (!this.state.chart.profit && !this.state.chart.loss && !this.state.chart.settle) ? 0 : 5,
                                    data: [
                                        this.state.chart.profit,
                                        this.state.chart.loss,
                                        this.state.chart.settle,
                                        !this.state.chart.profit && !this.state.chart.loss && !this.state.chart.settle
                                    ]
                                }
                            ]
                        }}
                    />

                    <div className="chart-indicator">
                        <div>
                            <span style={{ backgroundColor: '#47bfbd' }}></span>
                            <p>{this.state.chart.profit.toLocaleString('fa-IR')} تومان</p>
                        </div>

                        <div>
                            <span style={{ backgroundColor: '#f64649' }}></span>
                            <p>{this.state.chart.loss.toLocaleString('fa-IR')} تومان</p>
                        </div>

                        <div>
                            <span style={{ backgroundColor: '#feb45d' }}></span>
                            <p>{this.state.chart.settle.toLocaleString('fa-IR')} تومان</p>
                        </div>
                    </div>
                </div>

                <div className="price-container">
                    <div>
                        <p className="name">مظنه گرم ۱۸ عیار</p>
                        <p className="price">{this.state.goldPrice['Geram18']}</p>
                    </div>

                    <div>
                        <p className="name">سکه طرح جدید</p>
                        <p className="price">{this.state.goldPrice['NewCoin']}</p>
                    </div>

                    <div>
                        <p className="name">سکه طرح قدیم</p>
                        <p className="price">{this.state.goldPrice['OldCoin']}</p>
                    </div>

                    <div>
                        <p className="name">نیم سکه</p>
                        <p className="price">{this.state.goldPrice['HalfCoin']}</p>
                    </div>

                    <div>
                        <p className="name">ربع سکه</p>
                        <p className="price">{this.state.goldPrice['QuarterCoin']}</p>
                    </div>

                    <div>
                        <p className="name">سکه گرمی</p>
                        <p className="price">{this.state.goldPrice['GeramCoin']}</p>
                    </div>
                </div>
            </div >
        )
    }
}
